import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents time data, ISO-8601 encoded UTC date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  activePlans?: Maybe<Array<Plan>>;
  activeTrips?: Maybe<Array<Trip>>;
  additionalTypes?: Maybe<Array<TypeOfAdditional>>;
  addresseesGroupToChat?: Maybe<Array<ChatGroup>>;
  addresseesUsersToChat?: Maybe<AddresseesUsersToChatsPaginatedList>;
  aggregateTypes?: Maybe<Array<Aggregate>>;
  akebonoPackages?: Maybe<PackagesPaginatedList>;
  ambassadors?: Maybe<AmbassadorsPaginatedList>;
  attachmentsTemplate?: Maybe<Array<AttachmentsTemplate>>;
  /** Получить все отзывы аукциона */
  auctionFeedbacks?: Maybe<AuctionFeedbacksPaginatedList>;
  auctions?: Maybe<Array<Stock>>;
  autoResolveJobsQuery?: Maybe<Scalars['Boolean']>;
  branchOffices?: Maybe<Array<Scalars['String']>>;
  brokerExcelTemplates?: Maybe<Array<BrokerExcelTemplate>>;
  brokers?: Maybe<BrokersPaginatedList>;
  carriers?: Maybe<Array<Carrier>>;
  carTypes?: Maybe<Array<TypeOfExport>>;
  chassisTypes?: Maybe<Array<TypeOfChassis>>;
  chatForOthers?: Maybe<ChatForOthers>;
  chatMessages?: Maybe<ChatMessagessPaginatedList>;
  chatsForOthers?: Maybe<ChatsForOtherssPaginatedList>;
  chatTags?: Maybe<Array<Tag>>;
  checkAgentDebts?: Maybe<Scalars['Boolean']>;
  clientContacts?: Maybe<Array<ClientContact>>;
  clientObject?: Maybe<ObjectOfServices>;
  /** Вложения, посылки */
  clientObjectAttachments?: Maybe<ClientObjectAttachmentsPaginatedList>;
  clientObjectByVin?: Maybe<ObjectOfServices>;
  /** Список рейсов с активными объектами или долгами */
  clientObjectDashboard?: Maybe<Array<ClientObjectsTrip>>;
  clientObjectLogs?: Maybe<ClientObjectLogsPaginatedList>;
  clientObjectQueue?: Maybe<ClientObjectQueuesPaginatedList>;
  clientObjects?: Maybe<ClientObjectsPaginatedList>;
  clientObjectsReportPaymentAkebonoAllow?: Maybe<ClientObjectsReportPaymentAkebonoAllowsPaginatedList>;
  clientObjectYears?: Maybe<Array<ClientObjectsYear>>;
  clientRecipients?: Maybe<ClientRecipientsPaginatedList>;
  colors?: Maybe<Array<Color>>;
  /** Данные контейнера */
  container?: Maybe<Container>;
  /** Список контейнеров */
  containers?: Maybe<ContainersPaginatedList>;
  /** Типов контейнеров */
  containerTypes?: Maybe<Array<Maybe<TypeOfContainer>>>;
  countries?: Maybe<CountrysPaginatedList>;
  countUnreadChatMessages?: Maybe<Scalars['Int']>;
  currencies?: Maybe<Array<Currency>>;
  currentPlan?: Maybe<SbktsPlan>;
  currentUser?: Maybe<User>;
  currentUserNotifyMessages?: Maybe<Array<CurrentUserNotifyMessage>>;
  customPaymentTypes?: Maybe<Array<CustomPayment>>;
  customTypes?: Maybe<Array<TypeOfCustom>>;
  deliveryWheelPrice?: Maybe<Scalars['Int']>;
  depositOpenAmount?: Maybe<Array<Scalars['Int']>>;
  diffEntities?: Maybe<Array<LogEntity>>;
  diffEntityTypes?: Maybe<Array<Scalars['String']>>;
  drivers?: Maybe<Array<User>>;
  ecarJpBotSubscribeUrl: Scalars['String'];
  engines?: Maybe<Array<TypeOfEngine>>;
  engineTypes?: Maybe<Array<TypeOfEngine>>;
  exchangeJobActions?: Maybe<Array<Scalars['String']>>;
  exchangeJobs?: Maybe<ExchangeJobsPaginatedList>;
  exchangeJobTransports?: Maybe<Array<Scalars['String']>>;
  /** Список сторонних рейсов */
  exportTrips?: Maybe<ExportTripsPaginatedList>;
  fastObjectsByTrip?: Maybe<Array<FastObjectsByTrip>>;
  /** Получить все отзывы */
  feedbacks?: Maybe<FeedbackssPaginatedList>;
  financeReport?: Maybe<Array<FinanceReport>>;
  flatTypes?: Maybe<Array<TypeOfFlat>>;
  /** Формальник по id */
  formalOwner?: Maybe<FormalOwner>;
  formalOwners?: Maybe<FormalOwnersPaginatedList>;
  /** Список типов фрахта контейнера */
  freightTypes?: Maybe<Array<Maybe<TypeOfFreight>>>;
  getEPTSLauncherUrls?: Maybe<Array<Scalars['String']>>;
  /** Статистика + объекты ЗУ с грузополучателем */
  greenCornerObjects?: Maybe<GreenCornerObjects>;
  houseTypes?: Maybe<Array<TypeOfHouse>>;
  housingTypes?: Maybe<Array<TypeOfHousing>>;
  invoiceCustoms?: Maybe<InvoiceCustomerInfosPaginatedList>;
  invoiceLogs?: Maybe<InvoiceLogsPaginatedList>;
  /** Список и поиск инвойсов */
  invoicesSearch?: Maybe<InvoicesSearchsPaginatedList>;
  invoiceTypes?: Maybe<Array<InvoiceType>>;
  /** Запросы на изменение инвойса */
  invoiceUpdateRequests?: Maybe<Array<InvoiceUpdateRequest>>;
  /** Состояние автовыставления лотов */
  kaitaiAutoResubmitState?: Maybe<KaitaiAutoResubmitStateEnum>;
  /** Состояние очереди Kaitai емэйлов */
  kaitaiQueueStatus?: Maybe<QueueStatus>;
  laboratories?: Maybe<Array<Laboratory>>;
  limitsPlan?: Maybe<LimitsPlan>;
  localityTypes?: Maybe<Array<TypeOfLocality>>;
  logAgentEvents?: Maybe<LogAgentEventsPaginatedList>;
  logObjectEvents?: Maybe<Array<ObjectLogEvent>>;
  logs?: Maybe<LogsPaginatedList>;
  logsUserRequests?: Maybe<LogsUserRequestsPaginatedList>;
  logsUserRequestTypes?: Maybe<Array<Scalars['String']>>;
  logTypes?: Maybe<Array<Scalars['String']>>;
  /** Получить список лотерейных билетов. */
  lotteryTickets?: Maybe<Array<Maybe<LotteryTicket>>>;
  makerColors?: Maybe<Array<MakerColor>>;
  makers?: Maybe<Array<Maker>>;
  marks?: Maybe<Array<Mark>>;
  masterAccountsClients?: Maybe<MasterAccountsClientsPaginatedList>;
  mediaStatistics?: Maybe<MediaStatistics>;
  mediaTypes?: Maybe<Array<MediaTypeEnum>>;
  models?: Maybe<Array<Model>>;
  /** Курс по банку MUFG JPT/CNY */
  mufgJpyToCnyExhangeRate?: Maybe<Scalars['Float']>;
  /** Курс по банку MUFG JPT/EUR */
  mufgJpyToEurExhangeRate?: Maybe<Scalars['Float']>;
  /** Курс по банку MUFG JPT/USD */
  mufgJpyToUsdExhangeRate?: Maybe<Scalars['Float']>;
  navisSchedule?: Maybe<TripSchedule>;
  news?: Maybe<NewsPaginatedList>;
  newsView?: Maybe<News>;
  nomenclatures?: Maybe<Array<Nomenclature>>;
  notificationLogs?: Maybe<NotificationLogsPaginatedList>;
  objectKeyTypes?: Maybe<Array<TypeOfObjectKey>>;
  operationalObjects?: Maybe<OperationalObjectsPaginatedList>;
  /** Список организаций */
  organizations?: Maybe<Array<Organization>>;
  owner?: Maybe<Owner>;
  owners?: Maybe<OwnersPaginatedList>;
  /** Список палет */
  pallet?: Maybe<PalletsPaginatedList>;
  parkingPrice?: Maybe<Scalars['Int']>;
  partTemplates?: Maybe<Array<PartTemplate>>;
  paymentsHistory?: Maybe<Array<PaymentHistory>>;
  plan?: Maybe<Plan>;
  planGenerate?: Maybe<SbktsPlan>;
  plans?: Maybe<SbktsPlansPaginatedList>;
  ports?: Maybe<Array<PortInfo>>;
  /** Стоимость услуги Подготовка пакета документов для жителей не ДВФО */
  prepareDocumentsPrice?: Maybe<Scalars['Int']>;
  reasons?: Maybe<Array<Reason>>;
  sawTypes?: Maybe<Array<TypeOfSaw>>;
  sbktsBrokerStatistic?: Maybe<SbktsStatistic>;
  sbktsPlanStatistic?: Maybe<SbktsPlanStatistic>;
  sbktsRequest?: Maybe<SbktsRequest>;
  sbktsRequests?: Maybe<SbktsRequestsPaginatedList>;
  /** Список автомобилей на утилизацию */
  scrapObjects?: Maybe<DisposalsPaginatedList>;
  /** Список описаний шаблонов запчастей */
  scrapTypeDescriptions?: Maybe<ScrapTypeDescriptionsPaginatedList>;
  scrapTypes?: Maybe<Array<Scrap>>;
  /** Список морских доставщиков */
  seaCarriers?: Maybe<SeaCarriersPaginatedList>;
  searchObjects?: Maybe<SearchObjectsPaginatedList>;
  sectors?: Maybe<Array<Sector>>;
  senderAgentsSearch?: Maybe<OwnerAgentsPaginatedList>;
  senders?: Maybe<SendersPaginatedList>;
  /** Наличие заявки на фото с лота */
  serviceRequestAuctionLotByUser?: Maybe<ServiceRequest>;
  /** Стоимость услуги (инспекций и т.д) */
  serviceRequestPrice?: Maybe<Scalars['Int']>;
  /** Список заявок на инспекцию */
  serviceRequests?: Maybe<ServiceRequestsPaginatedList>;
  /** Получить статистику на постановку в план. */
  statisticCarInPlan: CarInPlantStatistic;
  statuses?: Maybe<Array<Status>>;
  /** Страны в которых есть склады */
  stockCountries?: Maybe<StockCountiesPaginatedList>;
  /** Список складов */
  stocks?: Maybe<StocksPaginatedList>;
  ticket?: Maybe<Ticket>;
  ticketDecisionsQuery?: Maybe<TicketDecisionsPaginatedList>;
  tickets?: Maybe<TicketsPaginatedList>;
  ticketsReasons?: Maybe<TicketsReasonsPaginatedList>;
  ticketsReportDownload?: Maybe<Scalars['String']>;
  ticketsStatistic?: Maybe<TicketStatistic>;
  ticketStatuses?: Maybe<Array<TicketStatus>>;
  /** Получить дополнительне отметки при работе с претензиями. */
  ticketSubTypes?: Maybe<Array<TypeOfTicketSub>>;
  ticketTypes?: Maybe<Array<TypeOfTicket>>;
  timeZones?: Maybe<Array<TimeZone>>;
  totalCustomsClearanceTime?: Maybe<TotalCustomsClearanceTime>;
  trip?: Maybe<Trip>;
  trips?: Maybe<TripsPaginatedList>;
  tripsCalendar?: Maybe<TripsCalendar>;
  undefinedPayments?: Maybe<UndefinedPaymentsPaginatedList>;
  /** Получить логи по объекту */
  unionLog?: Maybe<ClientUnionLogsPaginatedList>;
  userMenu?: Maybe<Array<PermissionMenuItem>>;
  users?: Maybe<UsersPaginatedList>;
  /** Список кораблей */
  vessels?: Maybe<Array<Vessel>>;
  vesselTypes?: Maybe<Array<VesselTypeInfo>>;
  watchCommentTemplate?: Maybe<Array<WatchCommentTemplate>>;
  watchType?: Maybe<Array<TypeOfWatchCar>>;
  /** Список колес */
  wheel?: Maybe<WheelsPaginatedList>;
  /** Список колес для менеджера */
  wheelAll?: Maybe<WheelAllsPaginatedList>;
  /** Значения фильтра для списка колес */
  wheelFilter?: Maybe<WheelFilterValue>;
  /** Статистика колес для менеджера */
  wheelStatistic?: Maybe<WheelStatistic>;
  withoutMediaObjects?: Maybe<WithoutMediaObjectsPaginatedList>;
  yahooOwners?: Maybe<YahooOwnersPaginatedList>;
};


export type QueryAddresseesGroupToChatArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryAddresseesUsersToChatArgs = {
  first?: Maybe<Scalars['Int']>;
  objectId?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryAkebonoPackagesArgs = {
  filter?: Maybe<PackagesFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAmbassadorsArgs = {
  byAgent?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAuctionFeedbacksArgs = {
  auctionId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAuctionsArgs = {
  partName?: Maybe<Scalars['String']>;
};


export type QueryBrokerExcelTemplatesArgs = {
  broker?: Maybe<Scalars['Int']>;
};


export type QueryBrokersArgs = {
  first?: Maybe<Scalars['Int']>;
  forSbkts?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  partName?: Maybe<Scalars['String']>;
  withoutPb?: Maybe<Scalars['Boolean']>;
};


export type QueryCarTypesArgs = {
  forWorldWide?: Maybe<Scalars['Boolean']>;
};


export type QueryChatForOthersArgs = {
  chatId: Scalars['Int'];
};


export type QueryChatMessagesArgs = {
  chatId: Scalars['Int'];
  filter?: Maybe<ChatMessagessFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryChatsForOthersArgs = {
  filter?: Maybe<ChatsForOtherssFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryClientObjectArgs = {
  objectId: Scalars['Int'];
};


export type QueryClientObjectAttachmentsArgs = {
  filter?: Maybe<ClientObjectAttachmentsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryClientObjectByVinArgs = {
  vin: Scalars['String'];
};


export type QueryClientObjectDashboardArgs = {
  filter?: Maybe<ClientObjectDashboardFilter>;
};


export type QueryClientObjectLogsArgs = {
  filter?: Maybe<ClientObjectLogsFilter>;
  first?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type QueryClientObjectQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type QueryClientObjectsArgs = {
  filter?: Maybe<ClientObjectsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<ClientObjectsSort>;
};


export type QueryClientObjectsReportPaymentAkebonoAllowArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  partVin: Scalars['String'];
};


export type QueryClientObjectYearsArgs = {
  agentId?: Maybe<Scalars['Int']>;
};


export type QueryClientRecipientsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryContainerArgs = {
  id: Scalars['Int'];
};


export type QueryContainersArgs = {
  filter?: Maybe<ContainersFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCountriesArgs = {
  filter?: Maybe<CountrysFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCountUnreadChatMessagesArgs = {
  onlyClients?: Maybe<Scalars['Boolean']>;
  onlyTickets?: Maybe<Scalars['Boolean']>;
};


export type QueryCurrenciesArgs = {
  filter?: Maybe<CurrencyFilter>;
};


export type QueryCurrentPlanArgs = {
  date?: Maybe<Scalars['String']>;
};


export type QueryCurrentUserNotifyMessagesArgs = {
  onlyEcarjpBot?: Maybe<Scalars['Boolean']>;
};


export type QueryDiffEntitiesArgs = {
  filter?: Maybe<DiffEntityFilter>;
};


export type QueryExchangeJobsArgs = {
  filter?: Maybe<ExchangeJobsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryExportTripsArgs = {
  filter?: Maybe<ExportTripsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<ExportTripsSort>;
};


export type QueryFeedbacksArgs = {
  filter?: Maybe<FeedbackssFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryFinanceReportArgs = {
  agentId?: Maybe<Scalars['Int']>;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
};


export type QueryFormalOwnerArgs = {
  id: Scalars['Int'];
};


export type QueryFormalOwnersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  partName: Scalars['String'];
};


export type QueryInvoiceCustomsArgs = {
  ownerId: Scalars['Int'];
  partName?: Maybe<Scalars['String']>;
};


export type QueryInvoiceLogsArgs = {
  objectId: Scalars['Int'];
};


export type QueryInvoicesSearchArgs = {
  filter?: Maybe<InvoicesSearchsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceUpdateRequestsArgs = {
  invoiceId: Scalars['Int'];
};


export type QueryLogAgentEventsArgs = {
  filter?: Maybe<LogAgentEventsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryLogObjectEventsArgs = {
  objectId: Scalars['Int'];
};


export type QueryLogsArgs = {
  filter?: Maybe<LogsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryLogsUserRequestsArgs = {
  filter?: Maybe<LogsUserRequestsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryLogsUserRequestTypesArgs = {
  user?: Maybe<Scalars['Int']>;
};


export type QueryMakerColorsArgs = {
  makerId?: Maybe<Scalars['Int']>;
};


export type QueryMarksArgs = {
  makerId: Scalars['Int'];
};


export type QueryMasterAccountsClientsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  partName?: Maybe<Scalars['String']>;
};


export type QueryMediaStatisticsArgs = {
  mediaType: MediaTypeEnum;
  stockId?: Maybe<Scalars['Int']>;
  tripId?: Maybe<Scalars['Int']>;
};


export type QueryModelsArgs = {
  markId: Scalars['Int'];
  vin?: Maybe<Scalars['String']>;
};


export type QueryNavisScheduleArgs = {
  comingSoon?: Maybe<Scalars['Boolean']>;
};


export type QueryNewsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryNewsViewArgs = {
  id: Scalars['Int'];
};


export type QueryNotificationLogsArgs = {
  filter?: Maybe<NotificationLogsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryOperationalObjectsArgs = {
  filter?: Maybe<OperationalObjectsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationsArgs = {
  forTickets?: Maybe<Scalars['Boolean']>;
};


export type QueryOwnerArgs = {
  id: Scalars['Int'];
};


export type QueryOwnersArgs = {
  first?: Maybe<Scalars['Int']>;
  fullSearch?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  partName: Scalars['String'];
};


export type QueryPalletArgs = {
  filter?: Maybe<PalletsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryPaymentsHistoryArgs = {
  agentId?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type QueryPlanArgs = {
  id: Scalars['Int'];
};


export type QueryPlansArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySawTypesArgs = {
  chassisTypeId?: Maybe<Scalars['Int']>;
  objectTypeId?: Maybe<Scalars['Int']>;
};


export type QuerySbktsBrokerStatisticArgs = {
  date: Scalars['String'];
};


export type QuerySbktsPlanStatisticArgs = {
  brokers?: Maybe<Array<Scalars['Int']>>;
  date: Scalars['String'];
  dateTo: Scalars['String'];
};


export type QuerySbktsRequestArgs = {
  id: Scalars['Int'];
};


export type QuerySbktsRequestsArgs = {
  filter?: Maybe<SbktsRequestsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SbktsRequestsSort>;
};


export type QueryScrapObjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  makerId?: Maybe<Scalars['Int']>;
  markId?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryScrapTypeDescriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryScrapTypesArgs = {
  isDeleted?: Maybe<Scalars['Boolean']>;
  objectId?: Maybe<Scalars['Int']>;
};


export type QuerySeaCarriersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySearchObjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QuerySectorsArgs = {
  stockId: Scalars['Int'];
};


export type QuerySenderAgentsSearchArgs = {
  filter?: Maybe<OwnerAgentsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySendersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  partName?: Maybe<Scalars['String']>;
};


export type QueryServiceRequestAuctionLotByUserArgs = {
  lotLink: Scalars['String'];
};


export type QueryServiceRequestPriceArgs = {
  type: VideoWatchTypeEnum;
};


export type QueryServiceRequestsArgs = {
  filter?: Maybe<ServiceRequestsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryStatisticCarInPlanArgs = {
  byAuctionId?: Maybe<Scalars['Int']>;
  byDate?: Maybe<Scalars['DateTime']>;
  dateFrom: Scalars['DateTime'];
  dateTo?: Maybe<Scalars['DateTime']>;
  statisticBy: StatisticTypeEnum;
};


export type QueryStatusesArgs = {
  type: TypeOfStatusEnum;
};


export type QueryStockCountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryStocksArgs = {
  filter?: Maybe<StocksFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  partName?: Maybe<Scalars['String']>;
};


export type QueryTicketArgs = {
  id: Scalars['Int'];
};


export type QueryTicketDecisionsQueryArgs = {
  filter?: Maybe<TicketDecisionsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryTicketsArgs = {
  filter?: Maybe<TicketsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<TicketsSort>;
};


export type QueryTicketsReasonsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryTicketsReportDownloadArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type QueryTripArgs = {
  id: Scalars['Int'];
};


export type QueryTripsArgs = {
  filter?: Maybe<TripsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<TripsSort>;
};


export type QueryUndefinedPaymentsArgs = {
  filter?: Maybe<UndefinedPaymentsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<UndefinedPaymentsSort>;
};


export type QueryUnionLogArgs = {
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};


export type QueryUserMenuArgs = {
  permissions?: Maybe<Array<Scalars['String']>>;
};


export type QueryUsersArgs = {
  filter?: Maybe<UsersFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryVesselsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  forTickets?: Maybe<Scalars['Boolean']>;
  isExport?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryVesselTypesArgs = {
  forWorldWide?: Maybe<Scalars['Boolean']>;
};


export type QueryWheelArgs = {
  filter?: Maybe<WheelsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryWheelAllArgs = {
  filter?: Maybe<WheelAllsFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryWithoutMediaObjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  mediaType: MediaTypeEnum;
  offset?: Maybe<Scalars['Int']>;
  stockId?: Maybe<Scalars['Int']>;
  tripId?: Maybe<Scalars['Int']>;
};


export type QueryYahooOwnersArgs = {
  first?: Maybe<Scalars['Int']>;
  idList?: Maybe<Array<Scalars['Int']>>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  /** К погрузке */
  activeObjectsCount?: Maybe<Scalars['Int']>;
  addToTripTitle?: Maybe<Scalars['String']>;
  /** Контейнеры на судне */
  containers?: Maybe<Array<Container>>;
  countCars?: Maybe<Scalars['Int']>;
  countCarsPrimbroker?: Maybe<Scalars['Int']>;
  countObjects?: Maybe<Scalars['Int']>;
  countOcrObjects?: Maybe<Scalars['Int']>;
  countSaws?: Maybe<Scalars['Int']>;
  /** Всего на судне */
  countTotal?: Maybe<Scalars['Int']>;
  customsAt?: Maybe<Scalars['DateTime']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  driveFrom?: Maybe<Scalars['DateTime']>;
  drivePaused?: Maybe<Scalars['Boolean']>;
  driveTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  loadingFinishedAt?: Maybe<Scalars['DateTime']>;
  loadingFinishedBy?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  nextContractNumber?: Maybe<Scalars['Int']>;
  /** Сгон со складов */
  notDeliveredObjectsCount?: Maybe<Scalars['Int']>;
  /** кол-во объектов вне судна */
  notLoadingObjectsCount?: Maybe<Scalars['Int']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  portFrom?: Maybe<Port>;
  simpleTitle?: Maybe<Scalars['String']>;
  stockFrom?: Maybe<Stock>;
  trip?: Maybe<Trip>;
  /** Ждем в порт */
  waitObjectsInPortCount?: Maybe<Scalars['Int']>;
};


export type PlanActiveObjectsCountArgs = {
  isAkebono?: Maybe<Scalars['Boolean']>;
};


export type PlanCountTotalArgs = {
  completed?: Maybe<Scalars['Boolean']>;
};


export type PlanNotDeliveredObjectsCountArgs = {
  isAkebono?: Maybe<Scalars['Boolean']>;
};


export type PlanWaitObjectsInPortCountArgs = {
  isAkebono?: Maybe<Scalars['Boolean']>;
};

export type Container = {
  __typename?: 'Container';
  /** Можно ли поставить объекты на данный рейс */
  canAddToPlan?: Maybe<AddToPlanAttention>;
  containerType?: Maybe<TypeOfContainer>;
  countObjects?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfLoadingAt?: Maybe<Scalars['DateTime']>;
  events?: Maybe<Array<Maybe<ContainerEvent>>>;
  files?: Maybe<Array<S3File>>;
  freightType?: Maybe<TypeOfFreight>;
  id?: Maybe<Scalars['Int']>;
  insurancePolicy?: Maybe<Scalars['String']>;
  /** Можно ли выгрузить в страну */
  isCanUnloading?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  seaCarrier?: Maybe<SeaCarrier>;
  sendedAt?: Maybe<Scalars['DateTime']>;
};


export type ContainerCanAddToPlanArgs = {
  planId: Scalars['Int'];
};

export type AddToPlanAttention = {
  __typename?: 'AddToPlanAttention';
  reason?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Boolean']>;
};

export type TypeOfContainer = {
  __typename?: 'TypeOfContainer';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ContainerEvent = {
  __typename?: 'ContainerEvent';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  agent?: Maybe<Agent>;
  allowedMediaTypes?: Maybe<Array<MediaTypeEnum>>;
  auctionGroups?: Maybe<Array<AuctionGroup>>;
  balance?: Maybe<Array<Money>>;
  broker?: Maybe<Broker>;
  carrierDriver?: Maybe<Driver>;
  chatGroups?: Maybe<Array<ChatGroup>>;
  clientsUsers?: Maybe<Array<UserShortInfo>>;
  commentCssClass?: Maybe<Scalars['String']>;
  debtUpdatedDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digests?: Maybe<Array<Digest>>;
  driverMode?: Maybe<DriverMode>;
  emails?: Maybe<Array<UserEmail>>;
  expireAccount?: Maybe<Scalars['String']>;
  expireToken?: Maybe<Scalars['Int']>;
  freightPaymentCountry?: Maybe<Country>;
  guid1C?: Maybe<Scalars['String']>;
  hasPermission?: Maybe<Scalars['Boolean']>;
  hasRole?: Maybe<Scalars['Boolean']>;
  homePageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAcceptCondition?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** Наличие подключенного телеграмм бота akebonobot */
  isBotAkebono?: Maybe<Scalars['Boolean']>;
  /** Наличие подключенного телеграмм бота cartrackbot */
  isBotCarsTrack?: Maybe<Scalars['Boolean']>;
  /** Наличие подключенного телеграмм бота ecarjpbot */
  isBotEcarJpAuc?: Maybe<Scalars['Boolean']>;
  isBroker?: Maybe<Scalars['Boolean']>;
  isChangePassword?: Maybe<Scalars['Boolean']>;
  isClient?: Maybe<Scalars['Boolean']>;
  /** Политика со скрытыми финансами */
  isHiddenFinance?: Maybe<Scalars['Boolean']>;
  isMasquerade?: Maybe<Scalars['Boolean']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  isSender?: Maybe<Scalars['Boolean']>;
  /** Флаг для отображения\скрытия отчёта 1С */
  isShowReport?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isUpdateContact?: Maybe<Scalars['Boolean']>;
  laboratoryExpert?: Maybe<LaboratoryExpert>;
  language?: Maybe<LanguageEnum>;
  masqueradeUser?: Maybe<Scalars['Int']>;
  mobilePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  owner?: Maybe<Owner>;
  permissionGroups?: Maybe<Array<PermissionGroup>>;
  permissions?: Maybe<Array<Permission>>;
  ports?: Maybe<Array<Port>>;
  /** @deprecated Useless */
  reminders?: Maybe<Array<Reminder>>;
  requiredChangePassword?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UserRole>;
  roles?: Maybe<Array<UserRole>>;
  sender?: Maybe<Sender>;
  stocks?: Maybe<Array<Stock>>;
  tickets?: Maybe<Array<Ticket>>;
  timeZone?: Maybe<TimeZone>;
  type?: Maybe<TypeOfUser>;
  username?: Maybe<Scalars['String']>;
  userParameter?: Maybe<UserParameter>;
  vessels?: Maybe<Array<Vessel>>;
};


export type UserBalanceArgs = {
  isAkebono?: Maybe<Scalars['Boolean']>;
};


export type UserHasPermissionArgs = {
  code: Scalars['String'];
  componentName?: Maybe<Scalars['String']>;
  ignoreSuperPermission?: Maybe<Scalars['Boolean']>;
};


export type UserHasRoleArgs = {
  isBase: Scalars['Boolean'];
  role: RoleEnum;
};

export type Agent = {
  __typename?: 'Agent';
  emails?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['Int']>;
  isAllowChangeCustomsPaymentType?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['String']>>;
};

export enum MediaTypeEnum {
  AdditionalInvoiceCustom = 'ADDITIONAL_INVOICE_CUSTOM',
  AgentPassportAll = 'AGENT_PASSPORT_ALL',
  AkebonoUpForAuction = 'AKEBONO_UP_FOR_AUCTION',
  AucCopyDocs = 'AUC_COPY_DOCS',
  AucDocs = 'AUC_DOCS',
  AucMedia = 'AUC_MEDIA',
  AucMediaHidden = 'AUC_MEDIA_HIDDEN',
  AuctionAutoFee = 'AUCTION_AUTO_FEE',
  AuctionLoader = 'AUCTION_LOADER',
  AuctionPaySheet = 'AUCTION_PAY_SHEET',
  AutoImportAcceptObject = 'AUTO_IMPORT_ACCEPT_OBJECT',
  AutoImportIssueObject = 'AUTO_IMPORT_ISSUE_OBJECT',
  AutoimportAfterDisassembly = 'AUTOIMPORT_AFTER_DISASSEMBLY',
  AutoimportBeforeDisassembly = 'AUTOIMPORT_BEFORE_DISASSEMBLY',
  AutoimportFastening = 'AUTOIMPORT_FASTENING',
  AutoimportParkingPass = 'AUTOIMPORT_PARKING_PASS',
  BarhatAcceptObject = 'BARHAT_ACCEPT_OBJECT',
  BarhatIssueObject = 'BARHAT_ISSUE_OBJECT',
  BeforeOutsideParking = 'BEFORE_OUTSIDE_PARKING',
  BrAgentBusinessCard = 'BR_AGENT_BUSINESS_CARD',
  BrAgentImages = 'BR_AGENT_IMAGES',
  BrAgentLogo = 'BR_AGENT_LOGO',
  BrokerImportExcels = 'BROKER_IMPORT_EXCELS',
  CarsAdMedia = 'CARS_AD_MEDIA',
  CertificateGlonassDocs = 'CERTIFICATE_GLONASS_DOCS',
  Chat = 'CHAT',
  Client = 'CLIENT',
  ClientBoardingPasses = 'CLIENT_BOARDING_PASSES',
  ClientCertificateFromHotel = 'CLIENT_CERTIFICATE_FROM_HOTEL',
  ClientExplanatory = 'CLIENT_EXPLANATORY',
  ClientExplanatoryNoDvfo = 'CLIENT_EXPLANATORY_NO_DVFO',
  ClientInvoiceDoc = 'CLIENT_INVOICE_DOC',
  ClientPassport = 'CLIENT_PASSPORT',
  ClientTempRegistration = 'CLIENT_TEMP_REGISTRATION',
  ClientVerificationTempRegistration = 'CLIENT_VERIFICATION_TEMP_REGISTRATION',
  ContainerBookedConfirm = 'CONTAINER_BOOKED_CONFIRM',
  ContainerInsurePolicy = 'CONTAINER_INSURE_POLICY',
  ContainerPhotos = 'CONTAINER_PHOTOS',
  ContainerUnloading = 'CONTAINER_UNLOADING',
  CustomInvoiceDoc = 'CUSTOM_INVOICE_DOC',
  CustomPaymentDoc = 'CUSTOM_PAYMENT_DOC',
  DeliveryFromAuctionTroubles = 'DELIVERY_FROM_AUCTION_TROUBLES',
  DeliverySheet = 'DELIVERY_SHEET',
  EnginePhoto = 'ENGINE_PHOTO',
  ExportBl = 'EXPORT_BL',
  ExportCertificate = 'EXPORT_CERTIFICATE',
  ExportDeclaration = 'EXPORT_DECLARATION',
  ExportInvoice = 'EXPORT_INVOICE',
  GasFilling = 'GAS_FILLING',
  ImageToAct = 'IMAGE_TO_ACT',
  Inn = 'INN',
  InnLegalOwner = 'INN_LEGAL_OWNER',
  InnPdf = 'INN_PDF',
  Invoice = 'INVOICE',
  InvoiceTransportCompany = 'INVOICE_TRANSPORT_COMPANY',
  IssueFromStockMedia = 'ISSUE_FROM_STOCK_MEDIA',
  Loading = 'LOADING',
  LoadingOnTruck = 'LOADING_ON_TRUCK',
  MainInvoiceCustom = 'MAIN_INVOICE_CUSTOM',
  Maintenance = 'MAINTENANCE',
  ObjectAfterDeliveryFromAuction = 'OBJECT_AFTER_DELIVERY_FROM_AUCTION',
  ObjectAttachment = 'OBJECT_ATTACHMENT',
  ObjectBrokerPhotos = 'OBJECT_BROKER_PHOTOS',
  ObjectCatalyst = 'OBJECT_CATALYST',
  ObjectClientPassport = 'OBJECT_CLIENT_PASSPORT',
  ObjectCopyConsignment = 'OBJECT_COPY_CONSIGNMENT',
  ObjectElptsPhotos = 'OBJECT_ELPTS_PHOTOS',
  ObjectExplode = 'OBJECT_EXPLODE',
  ObjectGlonassContract = 'OBJECT_GLONASS_CONTRACT',
  ObjectImage360Internal = 'OBJECT_IMAGE360_INTERNAL',
  ObjectInvoiceChanged = 'OBJECT_INVOICE_CHANGED',
  ObjectInvoiceForShippingKaitai = 'OBJECT_INVOICE_FOR_SHIPPING_KAITAI',
  ObjectInvoiceOriginal = 'OBJECT_INVOICE_ORIGINAL',
  ObjectKaitaiPhoto = 'OBJECT_KAITAI_PHOTO',
  ObjectLotScan = 'OBJECT_LOT_SCAN',
  ObjectOldElptsPhotos = 'OBJECT_OLD_ELPTS_PHOTOS',
  ObjectSbktsBackViewPhotos = 'OBJECT_SBKTS_BACK_VIEW_PHOTOS',
  ObjectSbktsForwardViewPhotos = 'OBJECT_SBKTS_FORWARD_VIEW_PHOTOS',
  ObjectSbktsNameplatePhotos = 'OBJECT_SBKTS_NAMEPLATE_PHOTOS',
  ObjectSbktsPanelPhotos = 'OBJECT_SBKTS_PANEL_PHOTOS',
  ObjectSbktsPdf = 'OBJECT_SBKTS_PDF',
  ObjectSbktsPhotos = 'OBJECT_SBKTS_PHOTOS',
  ObjectTpoPhotos = 'OBJECT_TPO_PHOTOS',
  ObjectWheelStockman = 'OBJECT_WHEEL_STOCKMAN',
  Odometer = 'ODOMETER',
  OgrnLegalOwner = 'OGRN_LEGAL_OWNER',
  OutsideParking = 'OUTSIDE_PARKING',
  PassportCommonSheet = 'PASSPORT_COMMON_SHEET',
  PassportMainSheet = 'PASSPORT_MAIN_SHEET',
  PassportRegistrationSheet = 'PASSPORT_REGISTRATION_SHEET',
  PaymentAdditionalInvoiceCustom = 'PAYMENT_ADDITIONAL_INVOICE_CUSTOM',
  PaymentCustomBroker = 'PAYMENT_CUSTOM_BROKER',
  PaymentCustomStock = 'PAYMENT_CUSTOM_STOCK',
  PaymentInvoiceTransportCompany = 'PAYMENT_INVOICE_TRANSPORT_COMPANY',
  PaymentMainInvoiceCustom = 'PAYMENT_MAIN_INVOICE_CUSTOM',
  PaymentNotary = 'PAYMENT_NOTARY',
  PaymentOverstaying = 'PAYMENT_OVERSTAYING',
  PaymentTransferFromCustom = 'PAYMENT_TRANSFER_FROM_CUSTOM',
  PbAgreementBlank = 'PB_AGREEMENT_BLANK',
  PbAgreementClientPhoto = 'PB_AGREEMENT_CLIENT_PHOTO',
  PbAgreementClientSigned = 'PB_AGREEMENT_CLIENT_SIGNED',
  PdfAct = 'PDF_ACT',
  PreDeliveryOrder = 'PRE_DELIVERY_ORDER',
  PurchaseReceipt = 'PURCHASE_RECEIPT',
  PurchaseReceiptPdf = 'PURCHASE_RECEIPT_PDF',
  QrSheet = 'QR_SHEET',
  RecipientsDocs = 'RECIPIENTS_DOCS',
  RoadManifest = 'ROAD_MANIFEST',
  RussianStock = 'RUSSIAN_STOCK',
  RussianWatchMedia = 'RUSSIAN_WATCH_MEDIA',
  SbktsDocuments = 'SBKTS_DOCUMENTS',
  SbktsDocxFizik = 'SBKTS_DOCX_FIZIK',
  SbktsDocxUrik = 'SBKTS_DOCX_URIK',
  SenderDocument = 'SENDER_DOCUMENT',
  StockMedia = 'STOCK_MEDIA',
  SupplierInvoice = 'SUPPLIER_INVOICE',
  TripBoxPhotos = 'TRIP_BOX_PHOTOS',
  TripExportPayment = 'TRIP_EXPORT_PAYMENT',
  TsmReplace = 'TSM_REPLACE',
  UnavailableAct = 'UNAVAILABLE_ACT',
  Unloading = 'UNLOADING',
  Vehicle = 'VEHICLE',
  WatchMedia = 'WATCH_MEDIA',
  WeighingAutoimport = 'WEIGHING_AUTOIMPORT',
  WorkerMedia = 'WORKER_MEDIA',
  WorldWideMedia = 'WORLD_WIDE_MEDIA'
}

export type AuctionGroup = {
  __typename?: 'AuctionGroup';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMenu?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  queryAlias?: Maybe<Scalars['String']>;
  stocks?: Maybe<Array<Stock>>;
};

export type Stock = {
  __typename?: 'Stock';
  /** Список активных секторов */
  activeSectors?: Maybe<Array<Sector>>;
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Склад в Отару */
  inOtaru?: Maybe<Scalars['Boolean']>;
  /** Склад в Томакомай */
  inTomakomai?: Maybe<Scalars['Boolean']>;
  /** Склад в Тояме */
  inToyama?: Maybe<Scalars['Boolean']>;
  /** Склад аукциона */
  isAuction?: Maybe<Scalars['Boolean']>;
  /** Склад в ЯП */
  isJapan?: Maybe<Scalars['Boolean']>;
  /** Склад порта */
  isPort?: Maybe<Scalars['Boolean']>;
  /** Хранение на складе платное */
  isStoragePaid?: Maybe<Scalars['Boolean']>;
  /** Склад с которых можно делать выдачу */
  issueAllow?: Maybe<Scalars['Boolean']>;
  /** Склад судна */
  isVessel?: Maybe<Scalars['Boolean']>;
  /** Виртуальный склад */
  isVirtual?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  /** Флаг отправки уведомления о прибытии на склад клиенту */
  notificationClient?: Maybe<Scalars['Boolean']>;
  /** Флаг отправки уведомления о прибытии на склад менеджеру */
  notificationManager?: Maybe<Scalars['Boolean']>;
  /** Список всех секторов */
  sectors?: Maybe<Array<Sector>>;
  /** Стоимость хранения */
  storageCost?: Maybe<Scalars['Int']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: Maybe<Scalars['Int']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']>;
};

export type Sector = {
  __typename?: 'Sector';
  /** Вместимость */
  capacity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  /** Порядок вывода секторов в списках (сортировка) */
  order?: Maybe<Scalars['Int']>;
  /** Кол-во объектов в секторе */
  totalCars?: Maybe<Scalars['Int']>;
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  internationalSymbol?: Maybe<Scalars['String']>;
  isoCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CurrencyEnum>;
};

export enum CurrencyEnum {
  Aed = 'AED',
  Cny = 'CNY',
  Eur = 'EUR',
  Jpy = 'JPY',
  Php = 'PHP',
  Rub = 'RUB',
  Sgd = 'SGD',
  Usd = 'USD'
}

export type Broker = {
  __typename?: 'Broker';
  engName?: Maybe<Scalars['String']>;
  fullNameAgent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  powerOfAttorneyInfo?: Maybe<Scalars['String']>;
};

export type Driver = {
  __typename?: 'Driver';
  carrier?: Maybe<Carrier>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicle>;
};

export type Carrier = {
  __typename?: 'Carrier';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentKm?: Maybe<VehicleKm>;
  driver?: Maybe<Driver>;
  employees?: Maybe<Array<Maybe<User>>>;
  etcCard?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  fuelCard?: Maybe<Scalars['String']>;
  fuelTankVolume?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  maker?: Maybe<Maker>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  technicalInspectionExpirationDate?: Maybe<Scalars['String']>;
  useType?: Maybe<TypeOfUseVehicle>;
  vin?: Maybe<Scalars['String']>;
};

export type VehicleKm = {
  __typename?: 'VehicleKm';
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']>;
  km?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

export type S3File = {
  __typename?: 'S3File';
  /** Base64 файл */
  binaryBase64?: Maybe<Scalars['String']>;
  /** Ссылка через клаудфронт */
  cloudFrontPath?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Состояние файлов для файлов таможни */
  customDocumentStatus?: Maybe<FileCustomDocumentStatusEnum>;
  /** CDN c учётом разрешения файла и бакета (для старых файлов) */
  fullPathByExtension?: Maybe<Scalars['String']>;
  /** Ссылка на файл с учётом бакета */
  fullPathForFile?: Maybe<Scalars['String']>;
  /** Ссылка через бакет на прямую */
  fullPathForFileWithoutCdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Путь в бакете */
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<UserShortInfo>;
};

/** Статусы загрузки файлов для таможни */
export enum FileCustomDocumentStatusEnum {
  /** Подтвержден */
  Accepted = 'ACCEPTED',
  /** Новый файл */
  New = 'NEW',
  /** Не изменялся */
  NotChanged = 'NOT_CHANGED'
}

export type UserShortInfo = {
  __typename?: 'UserShortInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Maker = {
  __typename?: 'Maker';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Mark = {
  __typename?: 'Mark';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfUseVehicle = {
  __typename?: 'TypeOfUseVehicle';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatGroup = {
  __typename?: 'ChatGroup';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserShortInfo>>;
};

export type Digest = {
  __typename?: 'Digest';
  name?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
};

export type DriverMode = {
  __typename?: 'DriverMode';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserEmail = {
  __typename?: 'UserEmail';
  dateConfirm?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isConfirm?: Maybe<Scalars['Boolean']>;
  useForChatNotifications?: Maybe<Scalars['Boolean']>;
};

export type Country = {
  __typename?: 'Country';
  alfa2?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  engName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum RoleEnum {
  AkebonoBukhgalter = 'AkebonoBukhgalter',
  AkebonoDokumentoved = 'AkebonoDokumentoved',
  AkebonoMenedzher = 'AkebonoMenedzher',
  AkebonoOtpravkaPoMiru = 'AkebonoOtpravkaPoMiru',
  AkebonoRazbor = 'AkebonoRazbor',
  AkebonoRossiya = 'AkebonoRossiya',
  AkebonoShipping = 'AkebonoShipping',
  AkebonoSklad = 'AkebonoSklad',
  AkebonoSkladMedia = 'AkebonoSkladMedia',
  AkebonoStarshijRazborshhik = 'AkebonoStarshijRazborshhik',
  AkebonoYaponskijOfis = 'AkebonoYaponskijOfis',
  Auktsionist = 'Auktsionist',
  BlNaEhksportnjkhRejsakh = 'BLNaEhksportnjkhRejsakh',
  Broker = 'Broker',
  Bukhgaltera = 'Bukhgaltera',
  BukhgalterPrimbroker = 'BukhgalterPrimbroker',
  ChatGruppaPrimbroker = 'ChatGruppaPrimbroker',
  DannjeAuktsionaVKartochkeObiekta = 'DannjeAuktsionaVKartochkeObiekta',
  Deklarant = 'Deklarant',
  Direktor = 'Direktor',
  DobavlenieKommentariyaKontragentu = 'DobavlenieKommentariyaKontragentu',
  DosmotrPrimbroker = 'DosmotrPrimbroker',
  DostupKDokumentamObiekta = 'DostupKDokumentamObiekta',
  DostupKProsmotryuPretenzij = 'DostupKProsmotryuPretenzij',
  DostupOrganizatsiiVlk = 'DostupOrganizatsiiVLK',
  EhkipazhSudna = 'EhkipazhSudna',
  EhksportnjeSertifikatjWorldWide = 'EhksportnjeSertifikatjWorldWide',
  GeneratsiyaOtchetaExcelPoZayavkamNaDostavku = 'GeneratsiyaOtchetaExcelPoZayavkamNaDostavku',
  IzmenenieOtmetkiKontragenta = 'IzmenenieOtmetkiKontragenta',
  IzmenenieSpiskaOzhidaniyaPogruzki = 'IzmenenieSpiskaOzhidaniyaPogruzki',
  IzmenenieYazjkaKontragenta = 'IzmenenieYazjkaKontragenta',
  Klient = 'Klient',
  Logist = 'Logist',
  LogistRf = 'LogistRF',
  LogistVoditelej = 'LogistVoditelej',
  MenedzherSvKh = 'MenedzherSVKh',
  MenedzherZelenjjUgol20 = 'MenedzherZelenjjUgol20',
  MladshijSotrudnikOtdelaPogruzki = 'MladshijSotrudnikOtdelaPogruzki',
  Nablyudatelj = 'Nablyudatelj',
  NapominaniyaBl = 'NapominaniyaBL',
  NavisAnalitik = 'NavisAnalitik',
  NavisDosmotr = 'NavisDosmotr',
  NavisDosmotrRossiya = 'NavisDosmotrRossiya',
  NavisFukui = 'NavisFukui',
  NavisMini = 'NavisMini',
  NavisOtaru = 'NavisOtaru',
  NavisPoluchateli = 'NavisPoluchateli',
  NavisShipping = 'NavisShipping',
  NavisShippingJsc = 'NavisShippingJSC',
  NavisSklad = 'NavisSklad',
  NovjjDogovorPb = 'NovjjDogovorPB',
  OtdelPogruzkiNavisShipping = 'OtdelPogruzkiNavisShipping',
  OtmetkaInvojsaOplachennjm = 'OtmetkaInvojsaOplachennjm',
  Otpravitelj = 'Otpravitelj',
  OtpravkaTelegramUvedomlenij = 'OtpravkaTelegramUvedomlenij',
  OuterAgentOtaru = 'OuterAgentOtaru',
  OuterCarrier = 'OuterCarrier',
  OuterOtaru = 'OuterOtaru',
  PartnerPerevozchik = 'PartnerPerevozchik',
  PartnerSbkts = 'PartnerSBKTS',
  PeremeshhenieObiektaMezhduSkladami = 'PeremeshhenieObiektaMezhduSkladami',
  PeremeshhenieZayavokDostavkiMezhduGruppami = 'PeremeshhenieZayavokDostavkiMezhduGruppami',
  PokazKlientovAkebono = 'PokazKlientovAkebono',
  PolnjjDostupKMenyuDejstvijQr = 'PolnjjDostupKMenyuDejstvijQR',
  PolnjjPoiskKontragenta = 'PolnjjPoiskKontragenta',
  PolnjjPoiskObiektov = 'PolnjjPoiskObiektov',
  PolnjjProsmotrKartochkiObiekta = 'PolnjjProsmotrKartochkiObiekta',
  Priemnaya = 'Priemnaya',
  ProsmotrDejstvijPoljzovatelya = 'ProsmotrDejstvijPoljzovatelya',
  ProsmotrIIzmenenieBl = 'ProsmotrIIzmenenieBL',
  ProsmotrInvojsov = 'ProsmotrInvojsov',
  ProsmotrKartochkiDostavki = 'ProsmotrKartochkiDostavki',
  ProsmotrKartochkiKontragenta = 'ProsmotrKartochkiKontragenta',
  ProsmotrKtoOnlajn = 'ProsmotrKtoOnlajn',
  ProsmotrLichnjkhKabinetov = 'ProsmotrLichnjkhKabinetov',
  ProsmotrLkVjbrannjkhKlientov = 'ProsmotrLKVjbrannjkhKlientov',
  ProsmotrOtchetaAkebono = 'ProsmotrOtchetaAkebono',
  ProsmotrPolnogoProfilyaPoljzovatelya = 'ProsmotrPolnogoProfilyaPoljzovatelya',
  ProsmotrSpiskaZelenjjUgol20 = 'ProsmotrSpiskaZelenjjUgol20',
  ProsmotrVsekhLkVjbrannjkhKlientov = 'ProsmotrVsekhLKVjbrannjkhKlientov',
  ProverkaDolgovPoObiektu = 'ProverkaDolgovPoObiektu',
  RazborshhikOtDostupnjkhSudov = 'RazborshhikOtDostupnjkhSudov',
  RedaktirovanieEhksportnjkhSertifikatov = 'RedaktirovanieEhksportnjkhSertifikatov',
  RedaktirovaniePoljzovatelya = 'RedaktirovaniePoljzovatelya',
  SbktsEhkspert = 'SBKTSEhkspert',
  SbktsRazreshenieUdalyatjZayavki = 'SBKTSRazreshenieUdalyatjZayavki',
  SbktsStrashnijEhkspert = 'SBKTSStrashnijEhkspert',
  SbrosPogruzkiRejsa = 'SbrosPogruzkiRejsa',
  SbrosRazgruzkiRejsa = 'SbrosRazgruzkiRejsa',
  SchyotchikPredvariteljnjkhZayavokNaDostavku = 'SchyotchikPredvariteljnjkhZayavokNaDostavku',
  SkladovshhikSvKh = 'SkladovshhikSVKh',
  SkladRossiya = 'SkladRossiya',
  SmenaKlientaObiekta = 'SmenaKlientaObiekta',
  SmenaNomeraKuzova = 'SmenaNomeraKuzova',
  SmenaOtpravitelyaObiekta = 'SmenaOtpravitelyaObiekta',
  SmenaProizvoditelyaIMarki = 'SmenaProizvoditelyaIMarki',
  SmenaStranjNaznacheniya = 'SmenaStranjNaznacheniya',
  SozdanieInvojsaRikun = 'SozdanieInvojsaRikun',
  SozdanieInvojsaZaPokupkuNaYaponskomAuktsione = 'SozdanieInvojsaZaPokupkuNaYaponskomAuktsione',
  SozdanieKontragenta = 'SozdanieKontragenta',
  SozdanieLotaVAkebonoIn = 'SozdanieLotaVAkebonoIn',
  SozdanieZayavokSbkts = 'SozdanieZayavokSBKTS',
  SozdatjZaprosNaVjdachu = 'SozdatjZaprosNaVjdachu',
  SozdavatjZayavkuNaDostavku = 'SozdavatjZayavkuNaDostavku',
  SpisatjSoSklada = 'SpisatjSoSklada',
  StatistikaZagruzkiFotoPoRejsam = 'StatistikaZagruzkiFotoPoRejsam',
  TorgovetsPogranichkami = 'TorgovetsPogranichkami',
  UdalenieFajlovBl = 'UdalenieFajlovBL',
  UpravlenieChatami = 'UpravlenieChatami',
  UpravleniePoljzovatelyami = 'UpravleniePoljzovatelyami',
  VerifitsirovatjPoluchatelya = 'VerifitsirovatjPoluchatelya',
  VjdachaPravaMenyatjTipOplatjTamozhni = 'VjdachaPravaMenyatjTipOplatjTamozhni',
  Voditelj = 'Voditelj',
  YahooMenedzher = 'YahooMenedzher',
  YahooSkladMoto = 'YahooSkladMoto',
  YaponskayaTamozhnya = 'YaponskayaTamozhnya',
  YaponskieRaschetkiSAuktsiona = 'YaponskieRaschetkiSAuktsiona',
  YaponskijSudovojAgent = 'YaponskijSudovojAgent',
  YaponskoeMenyuLogista = 'YaponskoeMenyuLogista',
  ZakrjvatjPretenziiBezUvedomlenij = 'ZakrjvatjPretenziiBezUvedomlenij'
}

export type LaboratoryExpert = {
  __typename?: 'LaboratoryExpert';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Инструменты эксперта */
  expertData?: Maybe<Array<ExpertData>>;
  /** Инструменты эксперта (удаленные) */
  expertDataDeleted?: Maybe<Array<ExpertData>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ignoreSendToFgis?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isJunior?: Maybe<Scalars['Boolean']>;
  isSignDeputy?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  juniors?: Maybe<Array<LaboratoryExpert>>;
  laboratory?: Maybe<Laboratory>;
  name?: Maybe<Scalars['String']>;
  parentExpert?: Maybe<LaboratoryExpert>;
  patronymic?: Maybe<Scalars['String']>;
  secondName?: Maybe<Scalars['String']>;
  thumbprint?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type ExpertData = {
  __typename?: 'ExpertData';
  categoriesCar?: Maybe<Array<CategoryCar>>;
  certificateNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  docName?: Maybe<Scalars['String']>;
  factoryN?: Maybe<Scalars['String']>;
  /** Конец эксплуатации инструмента */
  finishServiceAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  inventoryN?: Maybe<Scalars['String']>;
  inventoryNumber?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** Начало эксплуатации инструмента */
  startServiceAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  validityPeriod?: Maybe<Scalars['String']>;
};

export type CategoryCar = {
  __typename?: 'CategoryCar';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Laboratory = {
  __typename?: 'Laboratory';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deputyDirectorFirstName?: Maybe<Scalars['String']>;
  deputyDirectorLastName?: Maybe<Scalars['String']>;
  deputyDirectorPatronymic?: Maybe<Scalars['String']>;
  deputyThumbprint?: Maybe<Scalars['String']>;
  directorFirstName?: Maybe<Scalars['String']>;
  directorLastName?: Maybe<Scalars['String']>;
  directorPatronymic?: Maybe<Scalars['String']>;
  experts?: Maybe<Array<LaboratoryExpert>>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameAlias?: Maybe<Scalars['String']>;
  numberAccredited?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  thumbprint?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum LanguageEnum {
  En = 'en',
  Ru = 'ru'
}

export type Owner = {
  __typename?: 'Owner';
  address?: Maybe<Scalars['String']>;
  advanceAkebonoBalance?: Maybe<Scalars['Int']>;
  akebonoData?: Maybe<OwnerAkebono>;
  akebonoPenalties?: Maybe<OwnerAkebonoPenalty>;
  allUser?: Maybe<Array<User>>;
  availableDepositAmounts?: Maybe<Array<Scalars['Int']>>;
  balance?: Maybe<OwnerBalance>;
  comments?: Maybe<Array<OwnerComment>>;
  countryIso?: Maybe<Scalars['String']>;
  /** @deprecated Moved to formal owner */
  dataForDocuments?: Maybe<OwnerData>;
  daysOfFreeStorageOnDelivery?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Scalars['String']>>;
  engLegalAddress?: Maybe<Scalars['String']>;
  engName?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  guid1c?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inn?: Maybe<Scalars['String']>;
  invoiceBank?: Maybe<BankInvoice>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesCustom?: Maybe<Array<InvoiceCustom>>;
  isAcceptedStorageAgreement?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBroker?: Maybe<Scalars['Boolean']>;
  isExportToOtherCountry?: Maybe<Scalars['Boolean']>;
  isHasBlockedObjects?: Maybe<Scalars['Boolean']>;
  isHasOldDebts?: Maybe<Scalars['Boolean']>;
  isHasPenalty?: Maybe<Scalars['Boolean']>;
  isMasterAccount?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  isSender?: Maybe<Scalars['Boolean']>;
  isUnfairPartner?: Maybe<Scalars['Boolean']>;
  isWarning?: Maybe<Scalars['Boolean']>;
  lastPlannedObject?: Maybe<ObjectOfServices>;
  lastPurchasedObject?: Maybe<ObjectOfServices>;
  logo?: Maybe<S3File>;
  lotteryTickets?: Maybe<Array<Maybe<LotteryTicker>>>;
  name?: Maybe<Scalars['String']>;
  notPaidBidsDisplayStatus?: Maybe<PaidBidsDisplayStatus>;
  objectsStatistic?: Maybe<Scalars['Int']>;
  paidBidsDisplayStatuses?: Maybe<Array<Maybe<PaidBidsDisplayStatus>>>;
  phones?: Maybe<Array<Scalars['String']>>;
  senderAgents?: Maybe<Array<SenderAgent>>;
  senderCode?: Maybe<Scalars['String']>;
  senderComment?: Maybe<Scalars['String']>;
  senderEmails?: Maybe<Array<Scalars['String']>>;
  senderFax?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  tariffModel?: Maybe<Tariff>;
  userBroker?: Maybe<User>;
  userRecipient?: Maybe<User>;
  userSender?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
  yahooUser?: Maybe<Scalars['Int']>;
};


export type OwnerBalanceArgs = {
  isAkebono?: Maybe<Scalars['Boolean']>;
};


export type OwnerObjectsStatisticArgs = {
  active?: Maybe<Scalars['Boolean']>;
  isAkebono?: Maybe<Scalars['Boolean']>;
  type: TypeOfOwnerEnum;
};

export type OwnerAkebono = {
  __typename?: 'OwnerAkebono';
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type OwnerAkebonoPenalty = {
  __typename?: 'OwnerAkebonoPenalty';
  totalBases?: Maybe<Array<Money>>;
  totalPenaltiesRub?: Maybe<Money>;
  totalProposedPenaltiesJpy?: Maybe<Money>;
  totalProposedPenaltiesRub?: Maybe<Money>;
  totalTaxes?: Maybe<Array<Money>>;
};

export type OwnerBalance = {
  __typename?: 'OwnerBalance';
  advances?: Maybe<Array<Money>>;
  advancesByOrganization?: Maybe<Array<BalanceByOrganization>>;
  debts?: Maybe<Array<Money>>;
  deposits?: Maybe<Array<Money>>;
};

export type BalanceByOrganization = {
  __typename?: 'BalanceByOrganization';
  balance?: Maybe<Array<Money>>;
  organization?: Maybe<Organization>;
};

export type OwnerComment = {
  __typename?: 'OwnerComment';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type OwnerData = {
  __typename?: 'OwnerData';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type BankInvoice = {
  __typename?: 'BankInvoice';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Последний запрос на изменение */
  activeUpdateRequest?: Maybe<InvoiceUpdateRequest>;
  amount?: Maybe<Scalars['Float']>;
  amountSupplier?: Maybe<Scalars['Float']>;
  client?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isSendSupplier?: Maybe<Scalars['Boolean']>;
  isSent?: Maybe<Scalars['Boolean']>;
  isSentTo1C?: Maybe<Scalars['Boolean']>;
  isShow1c?: Maybe<Scalars['Boolean']>;
  nomenclaturesList?: Maybe<Array<Maybe<InvoiceNomenclatureItem>>>;
  number?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  organization?: Maybe<Organization>;
  sender?: Maybe<Sender>;
  type?: Maybe<InvoiceType>;
  /** Запросы на изменение */
  updateRequests?: Maybe<Array<InvoiceUpdateRequest>>;
};

export type InvoiceUpdateRequest = {
  __typename?: 'InvoiceUpdateRequest';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  data?: Maybe<Array<InvoiceUpdateRequestData>>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** Номенклатуры, затронутые в данном запросе */
  nomenclatures?: Maybe<Array<Nomenclature>>;
};

export type InvoiceUpdateRequestData = {
  __typename?: 'InvoiceUpdateRequestData';
  newValue?: Maybe<Scalars['Int']>;
  nomenclatureId?: Maybe<Scalars['Int']>;
  oldValue?: Maybe<Scalars['Int']>;
};

export type Nomenclature = {
  __typename?: 'Nomenclature';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isForExportPrice?: Maybe<Scalars['Boolean']>;
  isForWorldWide?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  ndsType?: Maybe<NdsType>;
};

export type NdsType = {
  __typename?: 'NdsType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Ставка включена в сумму начисления */
  isIncluded?: Maybe<Scalars['Boolean']>;
  /** Ставка НДС в процентах */
  value?: Maybe<Scalars['Int']>;
};

export type InvoiceNomenclatureItem = {
  __typename?: 'InvoiceNomenclatureItem';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  nomenclature?: Maybe<Nomenclature>;
};

export type ObjectOfServices = {
  __typename?: 'ObjectOfServices';
  accountantChatId?: Maybe<Scalars['Int']>;
  activeHash?: Maybe<Scalars['String']>;
  acts?: Maybe<Array<ChatForOthers>>;
  additionalType?: Maybe<TypeOfAdditional>;
  aggregateType?: Maybe<Aggregate>;
  aiParkingPass?: Maybe<ObjectParkingPass>;
  akebonoBuyer?: Maybe<Owner>;
  akebonoPenalty?: Maybe<AkebonoPenalty>;
  allKeys?: Maybe<Array<Maybe<ObjectKey>>>;
  ambassadors?: Maybe<Array<Ambassador>>;
  attachmentObjects?: Maybe<Array<ObjectOfServices>>;
  attachments?: Maybe<Scalars['String']>;
  auction?: Maybe<ObjectAuction>;
  auctionHistory?: Maybe<Array<ObjectAuctionHistory>>;
  auctionListing?: Maybe<AuctionListing>;
  auctionPaySheet?: Maybe<AuctionPaySheet>;
  bls?: Maybe<Array<Bl>>;
  bookingRequests?: Maybe<Array<BookingRequest>>;
  branchOffice?: Maybe<Scalars['String']>;
  broker?: Maybe<Broker>;
  /** @deprecated Use BrokerType */
  brokerNameForClient?: Maybe<Scalars['String']>;
  /** Можно ли поставить объект на данный рейс */
  canAddToPlan?: Maybe<AddToPlanAttention>;
  /** Можно ли сделать претензию типа Таможенное оформление */
  canMakeCustomTicket?: Maybe<Scalars['Boolean']>;
  /** Доступна ли загрузка таможенных документов */
  canUploadCustomDocuments?: Maybe<Scalars['Boolean']>;
  carBuy?: Maybe<ObjectToSale>;
  carClientParam?: Maybe<CarClientParam>;
  carsClearance?: Maybe<ObjectClearance>;
  carType?: Maybe<TypeOfExport>;
  chassisType?: Maybe<TypeOfChassis>;
  chats?: Maybe<Array<ChatForOthers>>;
  clientDebts?: Maybe<Array<Price>>;
  comment?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<ObjectComment>>;
  complexAmount?: Maybe<Scalars['Float']>;
  /** Стоимость указанные в инвойсе */
  complexByInvoice?: Maybe<ComplexByInvoice>;
  /** Состояние грузо получателя */
  conditionConsignee?: Maybe<ConditionConsigneeEnum>;
  consigneeAddress?: Maybe<Scalars['String']>;
  consigneeNameForClient?: Maybe<Scalars['String']>;
  consignment?: Maybe<Scalars['Int']>;
  container?: Maybe<Container>;
  creditsStorage?: Maybe<Array<Maybe<CreditStorage>>>;
  currentExportCertificate?: Maybe<Scalars['String']>;
  currentExportTrip?: Maybe<ExportTrip>;
  currentOnPlan?: Maybe<OnPlan>;
  currentThirdPartyTrip?: Maybe<ThirdPartyTrip>;
  /** Набор документов для таможни */
  customDocument?: Maybe<ObjectCustomDocument>;
  /**
   * Набор документов для таможни
   * @deprecated Use customDocument
   */
  customDocuments?: Maybe<Array<Maybe<ObjectCustomDocument>>>;
  /** Способ оплаты таможни */
  customPaymentType?: Maybe<CustomPayment>;
  customType?: Maybe<TypeOfCustom>;
  customZone?: Maybe<Scalars['String']>;
  /** Дата прибытия во Владивосток */
  dateArrivalVladivostok?: Maybe<Scalars['DateTime']>;
  /** Дата покупки */
  dateBuy?: Maybe<Scalars['DateTime']>;
  /** Предположительная дата получения авто с аукциона */
  dateCarWithAuction?: Maybe<Scalars['DateTime']>;
  dateDismantleForCube?: Maybe<Scalars['DateTime']>;
  /** Предположительная дата получения документов с аукциона */
  dateDocumentWithAuction?: Maybe<Scalars['DateTime']>;
  /** Дата ближайшего рейса */
  dateNearTrip?: Maybe<Scalars['DateTime']>;
  /** Предположительная дата выдачи клиету */
  dateOfIssue?: Maybe<Scalars['DateTime']>;
  dateOfManufacture?: Maybe<Scalars['String']>;
  dateRecyclingToCube?: Maybe<Scalars['DateTime']>;
  dateSoldRecyclingCube?: Maybe<Scalars['DateTime']>;
  /** Предположительная дата получения с аукциона. Max(dateCarWithAuction, dateDocumentWithAuction) */
  dateWithAuction?: Maybe<Scalars['DateTime']>;
  daysPaidStorageAutoimport?: Maybe<Scalars['Int']>;
  delayRequests?: Maybe<Array<ObjectDelayRequest>>;
  delivered?: Maybe<Array<ObjectDelivered>>;
  delivery?: Maybe<ObjectDelivery>;
  deliveryRequestFromPreDelivery?: Maybe<DeliveryRequest>;
  deliveryRequests?: Maybe<Array<DeliveryRequest>>;
  desiredTrip?: Maybe<DesiredTrip>;
  destinationCountry?: Maybe<Country>;
  diagnosticDoc?: Maybe<DiagnosticDoc>;
  disassemble?: Maybe<Disassemble>;
  /** Кол-во снятых колёс */
  disassembleWheelCount?: Maybe<Scalars['Int']>;
  disassemblyState?: Maybe<DisassemblyStateEnum>;
  draftBroker?: Maybe<DraftBroker>;
  draftClient?: Maybe<DraftClient>;
  draftFreightObject?: Maybe<DraftFreightObject>;
  elpts?: Maybe<Elpts>;
  emailMessages?: Maybe<Array<ObjectNotification>>;
  exportCertificate?: Maybe<ExportCertificate>;
  exportCertificates?: Maybe<Array<ExportCertificate>>;
  exportPrice?: Maybe<Price>;
  exportTrips?: Maybe<Array<ExportTrip>>;
  feedback?: Maybe<CarFeedback>;
  files?: Maybe<Array<S3File>>;
  firstDeliveryCarrier?: Maybe<Carrier>;
  firstDeliveryRequest?: Maybe<DeliveryRequest>;
  /** FOB цена */
  fobPrice?: Maybe<Scalars['Int']>;
  formalBroker?: Maybe<Broker>;
  formalOwner?: Maybe<FormalOwner>;
  formalOwnerState?: Maybe<FormalOwnerStateEnum>;
  freight?: Maybe<Scalars['Float']>;
  freightCountryReason?: Maybe<Scalars['String']>;
  /**
   * Количество дней до начала начислений
   * @deprecated Use paymentFreeDaysLeft
   */
  greenCornerFreeDaysLeft?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  /** @deprecated Delete un use */
  hasActiveDeliveryToAutoImport?: Maybe<Scalars['Boolean']>;
  /** @deprecated Delete un use */
  hasActiveDeliveryToTroyan?: Maybe<Scalars['Boolean']>;
  hasAkebonoJpyDebts?: Maybe<Scalars['Boolean']>;
  hasChassisNumber?: Maybe<Scalars['Boolean']>;
  /** @deprecated Delete un use */
  hasClosedDeliveryToTroyan?: Maybe<Scalars['Boolean']>;
  hasFrameNumber?: Maybe<Scalars['Boolean']>;
  hash?: Maybe<Scalars['String']>;
  hasStatus?: Maybe<Scalars['Boolean']>;
  hasVin?: Maybe<Scalars['Boolean']>;
  headObject?: Maybe<ObjectOfServices>;
  holdTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  inRussia?: Maybe<Scalars['Boolean']>;
  inventoryObjects?: Maybe<Array<InventoryObject>>;
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesCustom?: Maybe<Array<InvoiceCustom>>;
  invoiceUrl?: Maybe<Scalars['String']>;
  isAggregate?: Maybe<Scalars['Boolean']>;
  /** Загружено изображение договора */
  isAgreementClientSigned?: Maybe<Scalars['Boolean']>;
  isAutoImport?: Maybe<Scalars['Boolean']>;
  /** Признак запроса букинга */
  isBookingRequested?: Maybe<Scalars['Boolean']>;
  isBoundary?: Maybe<Scalars['Boolean']>;
  /** Признак можно ли снимать колеса */
  isCanCreateWheel?: Maybe<Scalars['Boolean']>;
  isCanEditByTroyan?: Maybe<Scalars['Boolean']>;
  isCanFillSbkts?: Maybe<Scalars['Boolean']>;
  /** Можно ли выдать объект */
  isCanIssue?: Maybe<Scalars['Boolean']>;
  isCanRequestParking?: Maybe<Scalars['Boolean']>;
  isCar?: Maybe<Scalars['Boolean']>;
  isCarFinanceClearAkebono?: Maybe<Scalars['Boolean']>;
  isCarFinanceClearFull?: Maybe<Scalars['Boolean']>;
  isCarFinanceClearNavis?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDisassemble?: Maybe<Scalars['Boolean']>;
  isFullFilledSbktsData?: Maybe<Scalars['Boolean']>;
  /** Автозачет делается на общих основаниях */
  isGeneralBasisAdvance?: Maybe<Scalars['Boolean']>;
  isGlonassNeed?: Maybe<Scalars['Boolean']>;
  /** Зелёный угол */
  isGreenCorner?: Maybe<Scalars['Boolean']>;
  isHaveVehicleRegistrationNumber?: Maybe<Scalars['Boolean']>;
  isImpassable?: Maybe<Scalars['Boolean']>;
  isMoto?: Maybe<Scalars['Boolean']>;
  isNeedConsignee?: Maybe<Scalars['Boolean']>;
  isNeedVideo?: Maybe<Scalars['Boolean']>;
  isNoAutoFreightCountry?: Maybe<Scalars['Boolean']>;
  isNumberCut?: Maybe<Scalars['Boolean']>;
  isOnDisassemblyStock?: Maybe<Scalars['Boolean']>;
  isOwnOwner?: Maybe<Scalars['Boolean']>;
  isPaidStorageStarted?: Maybe<Scalars['Boolean']>;
  isPart?: Maybe<Scalars['Boolean']>;
  /** Приняты\Не получены документы\Отклонены (если есть pbPassportDeclineComment) */
  isPbReceivedPassportCopy?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isPrimbroker?: Maybe<Scalars['Boolean']>;
  isPriorityPass?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  /** Когда у клиента запрошены документы для таможни игнорируя статус Погружен */
  isRequestDocumentsOfCustoms?: Maybe<Scalars['DateTime']>;
  /** Заказан пакет документов для жителей не ДВФО */
  isRequestedPrepareDocuments?: Maybe<Scalars['Boolean']>;
  isSaw?: Maybe<Scalars['Boolean']>;
  issueBlocks?: Maybe<Array<IssueBlock>>;
  /** Есть претензии */
  isTicket?: Maybe<Scalars['Boolean']>;
  isTroyan?: Maybe<Scalars['Boolean']>;
  /** Загружен ли набор документов и подтвержден */
  isUploadedCustomDocuments?: Maybe<Scalars['Boolean']>;
  isVladTrek?: Maybe<Scalars['Boolean']>;
  isYahoo?: Maybe<Scalars['Boolean']>;
  /** Список оплат УСО по Японии */
  japanPayments?: Maybe<Array<Maybe<JapanPaymentsToRequest>>>;
  jpTrackingNumberForPlate?: Maybe<Scalars['String']>;
  kaitaiParam?: Maybe<KaitaiParam>;
  keys?: Maybe<Array<Maybe<ObjectKey>>>;
  keysCount?: Maybe<Scalars['Int']>;
  labelElementType?: Maybe<Scalars['String']>;
  labelEmbeddedType?: Maybe<Scalars['String']>;
  /** Лаборатория */
  laboratory?: Maybe<Laboratory>;
  lastArrivalToAutoImport?: Maybe<StorageDaysByStock>;
  lastStatusToObject?: Maybe<StatusToObject>;
  /** @deprecated Use StockType */
  locationNameForChat?: Maybe<Scalars['String']>;
  /** @deprecated Replaced to logObjectEvents query */
  logEvents?: Maybe<Array<ObjectLogEvent>>;
  masterAccountClient?: Maybe<Owner>;
  media?: Maybe<Array<ObjectMedia>>;
  /** @deprecated Useless, remove */
  mirrors?: Maybe<Array<ObjectMirror>>;
  name?: Maybe<Scalars['String']>;
  name1c?: Maybe<Scalars['String']>;
  notRejectRecipient?: Maybe<Recipient>;
  numberCut?: Maybe<Scalars['String']>;
  objectActions?: Maybe<Array<ObjectActionsEnum>>;
  objectCount?: Maybe<Scalars['Int']>;
  objectDisassemble?: Maybe<ObjectDisassemble>;
  objectIcons?: Maybe<Array<ObjectIconsEnum>>;
  onPlans?: Maybe<Array<OnPlan>>;
  owner?: Maybe<Owner>;
  /** Параметры платного хранения */
  paidStorageData?: Maybe<ObjectPaidStorageData>;
  parkginRequestCancel?: Maybe<ObjectParkingRequestCancel>;
  parkginRequestsCancel?: Maybe<Array<Maybe<ObjectParkingRequestCancel>>>;
  parkingRequest?: Maybe<ObjectParkingRequest>;
  partObjects?: Maybe<Array<ObjectOfServices>>;
  partRelationType?: Maybe<ObjectRelationTypeEnum>;
  payCountry?: Maybe<Country>;
  /** Количество дней до начала начислений */
  paymentFreeDaysLeft?: Maybe<Scalars['Int']>;
  /** План (тариф) оплаты объекта */
  paymentPlan?: Maybe<PaymentPlan>;
  pbAgreement?: Maybe<PbAgreement>;
  pbAgreementBlank?: Maybe<S3File>;
  pbAgreements?: Maybe<Array<Maybe<PbAgreement>>>;
  /** Причина отмены документов */
  pbPassportDeclineComment?: Maybe<Scalars['String']>;
  photoVisibleBlock?: Maybe<ObjectMediaBlock>;
  /** Планируемый тип судна */
  plannedVesselType?: Maybe<VesselTypeInfo>;
  preDeliveryObject?: Maybe<PreDeliveryObject>;
  preDeliveryObjects?: Maybe<Array<PreDeliveryObject>>;
  quantityStorageDays?: Maybe<Scalars['Int']>;
  radiation?: Maybe<Scalars['String']>;
  /** Данные оплаты */
  receiptOfPayment?: Maybe<ReceiptOfPayment>;
  /** Данные оплаты */
  receiptOfPayments?: Maybe<Array<Maybe<ReceiptOfPayment>>>;
  recipientHash?: Maybe<Scalars['String']>;
  recipients?: Maybe<Array<Recipient>>;
  /** Ссылка на грузополучателя */
  recipientUrl?: Maybe<Scalars['String']>;
  /** Юридическое лицо «для коммерческих целей» */
  registrationForCommercialPurposes?: Maybe<Scalars['Boolean']>;
  requestToIssueObject?: Maybe<RequestToIssueObject>;
  rightSector?: Maybe<Sector>;
  sawType?: Maybe<TypeOfSaw>;
  sbktsRequest?: Maybe<SbktsRequest>;
  sbktsRequests?: Maybe<Array<SbktsRequest>>;
  scrapObject?: Maybe<ScrapObject>;
  scrapObjectsByParent?: Maybe<Array<ScrapObject>>;
  sender?: Maybe<Sender>;
  senderObject?: Maybe<ObjectSender>;
  /** Дата продажи авто Дубаи, Монголия и т.д */
  soldDate?: Maybe<Scalars['DateTime']>;
  statuses?: Maybe<Array<StatusToObject>>;
  statusReadyForClient?: Maybe<StatusToObject>;
  statusReleasedFromCustoms?: Maybe<StatusToObject>;
  statusReleasedFromStock?: Maybe<StatusToObject>;
  stock?: Maybe<Stock>;
  storageDaysByStock?: Maybe<Array<StorageDaysByStock>>;
  sumAkebonoJpyDebts?: Maybe<Scalars['Int']>;
  svhIssuedDate?: Maybe<Scalars['DateTime']>;
  tariffModel?: Maybe<Tariff>;
  thingsAttachments?: Maybe<Array<ObjectAttachment>>;
  thingsAttachmentsLog?: Maybe<Array<ObjectAttachmentTypeLog>>;
  thirdPartyTrips?: Maybe<Array<ThirdPartyTrip>>;
  tickets?: Maybe<Array<Ticket>>;
  title?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<LotTranslation>>>;
  troyanCustomer?: Maybe<TroyanCustomer>;
  troyanCustomers?: Maybe<Array<Maybe<TroyanCustomer>>>;
  vin?: Maybe<Scalars['String']>;
  watch?: Maybe<Watch>;
  wheelOptions?: Maybe<Wheel>;
  yahooCode?: Maybe<Scalars['String']>;
  yahooConsignmentName?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type ObjectOfServicesCanAddToPlanArgs = {
  planId: Scalars['Int'];
};


export type ObjectOfServicesDeliveryRequestsArgs = {
  organization?: Maybe<OrganizationEnum>;
};


export type ObjectOfServicesFilesArgs = {
  type?: Maybe<MediaTypeEnum>;
};


export type ObjectOfServicesHasStatusArgs = {
  statusId: Scalars['Int'];
};


export type ObjectOfServicesMediaArgs = {
  types?: Maybe<Array<MediaTypeEnum>>;
};


export type ObjectOfServicesObjectActionsArgs = {
  actions?: Maybe<Array<ObjectActionsEnum>>;
};


export type ObjectOfServicesObjectIconsArgs = {
  icons?: Maybe<Array<ObjectIconsEnum>>;
};

export type ChatForOthers = {
  __typename?: 'ChatForOthers';
  accessUsers?: Maybe<Array<ChatAccessUser>>;
  chatMembers?: Maybe<Array<Scalars['String']>>;
  countTasksResolved?: Maybe<Scalars['Int']>;
  countTasksUnresolved?: Maybe<Scalars['Int']>;
  countUnreadMessages?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  lastMessage?: Maybe<ChatMessage>;
  messages?: Maybe<Array<ChatMessage>>;
  object?: Maybe<ObjectOfServices>;
  subject?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  ticket?: Maybe<Ticket>;
  toGroups?: Maybe<Array<ChatGroup>>;
};

export type ChatAccessUser = {
  __typename?: 'ChatAccessUser';
  accessGranted?: Maybe<User>;
  asObserver?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  task?: Maybe<ChatMessageTask>;
  text?: Maybe<Scalars['String']>;
};

export type ChatMessageTask = {
  __typename?: 'ChatMessageTask';
  executor?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  solvedAt?: Maybe<Scalars['DateTime']>;
};

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_eng?: Maybe<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  accessUsers?: Maybe<Array<User>>;
  /** @deprecated Moved to decision */
  approvedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Moved to decision */
  approvedBy?: Maybe<User>;
  canApproveOrganization?: Maybe<Scalars['Boolean']>;
  canApproveStaff?: Maybe<Scalars['Boolean']>;
  canDecline?: Maybe<Scalars['Boolean']>;
  canPayout?: Maybe<Scalars['Boolean']>;
  canStaffActions?: Maybe<Scalars['Boolean']>;
  clientChat?: Maybe<ChatForOthers>;
  closedAt?: Maybe<Scalars['DateTime']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  decisions?: Maybe<Array<TicketDecision>>;
  declineAt?: Maybe<Scalars['DateTime']>;
  expireTime?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  history?: Maybe<Array<TicketHistory>>;
  id?: Maybe<Scalars['Int']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isDecline?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  organizationChat?: Maybe<ChatForOthers>;
  reasonInner?: Maybe<TicketReason>;
  requestAmount?: Maybe<Price>;
  review?: Maybe<TicketReview>;
  status?: Maybe<TicketStatus>;
  statuses?: Maybe<Array<TicketStatuses>>;
  ticketReason?: Maybe<TicketReason>;
  ticketSubType?: Maybe<TypeOfTicketSub>;
  ticketType?: Maybe<TypeOfTicket>;
};

export type TicketDecision = {
  __typename?: 'TicketDecision';
  amount?: Maybe<Price>;
  appealCount?: Maybe<Scalars['Int']>;
  appealMessage?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<User>;
  approveToPayoutAt?: Maybe<Scalars['DateTime']>;
  approveToPayoutBy?: Maybe<User>;
  canAppeal?: Maybe<Scalars['Boolean']>;
  canApproveOrganization?: Maybe<Scalars['Boolean']>;
  canCancel?: Maybe<Scalars['Boolean']>;
  cancelAt?: Maybe<Scalars['DateTime']>;
  cancelBy?: Maybe<User>;
  canChange?: Maybe<Scalars['Boolean']>;
  canResetWaitPayout?: Maybe<Scalars['Boolean']>;
  compensationAmount?: Maybe<Price>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  expireTime?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  guiltyMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCompensatedByThirdParty?: Maybe<Scalars['Boolean']>;
  isPayOrganization?: Maybe<Scalars['Boolean']>;
  isPenalty?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  organizationComment?: Maybe<Scalars['String']>;
  payoutAt?: Maybe<Scalars['DateTime']>;
  payoutBy?: Maybe<User>;
  status?: Maybe<TicketStatus>;
  ticket?: Maybe<Ticket>;
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Int']>;
};

export type TicketStatus = {
  __typename?: 'TicketStatus';
  forDecision?: Maybe<Scalars['Boolean']>;
  forTicket?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  innerName?: Maybe<Scalars['String']>;
  outerName?: Maybe<Scalars['String']>;
  outerNameEn?: Maybe<Scalars['String']>;
};

export type TicketHistory = {
  __typename?: 'TicketHistory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type TicketReason = {
  __typename?: 'TicketReason';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TicketReview = {
  __typename?: 'TicketReview';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
};

export type TicketStatuses = {
  __typename?: 'TicketStatuses';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<TicketStatus>;
};

export type TypeOfTicketSub = {
  __typename?: 'TypeOfTicketSub';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfTicket = {
  __typename?: 'TypeOfTicket';
  code?: Maybe<Scalars['String']>;
  defaultCurrency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type TypeOfAdditional = {
  __typename?: 'TypeOfAdditional';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  id?: Maybe<Scalars['Int']>;
  isDefaultForScrap?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<AggregateTypeEnum>;
};

export enum AggregateTypeEnum {
  Body = 'body',
  Engine = 'engine',
  Frame = 'frame'
}

export type ObjectParkingPass = {
  __typename?: 'ObjectParkingPass';
  createdAt?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isReady?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  type?: Maybe<MediaTypeEnum>;
};

export type AkebonoPenalty = {
  __typename?: 'AkebonoPenalty';
  /** Процент от стоимости покупки */
  baseAmount?: Maybe<Money>;
  createdAt?: Maybe<Scalars['Int']>;
  dayFirstPenalty?: Maybe<Scalars['DateTime']>;
  days?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  object?: Maybe<ObjectOfServices>;
  penaltyDays?: Maybe<Scalars['Int']>;
  penaltyDaysTotal?: Maybe<Scalars['Int']>;
  penaltyRubAmount?: Maybe<Money>;
  proposedPenaltyDays?: Maybe<Scalars['Int']>;
  /** Планируется начислить */
  proposedPenaltyJpyAmount?: Maybe<Money>;
  /** 100% начисленные пеналити */
  proposedPenaltyRubAmount?: Maybe<Money>;
  /** 1/x от стоимости авто */
  taxAmount?: Maybe<Money>;
};

export type ObjectKey = {
  __typename?: 'ObjectKey';
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  stage?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfObjectKey>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TypeOfObjectKey = {
  __typename?: 'TypeOfObjectKey';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type Ambassador = {
  __typename?: 'Ambassador';
  agent?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formalBroker?: Maybe<Broker>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameRod?: Maybe<Scalars['String']>;
  nameRodShort?: Maybe<Scalars['String']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShortAfter?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  requester?: Maybe<Broker>;
  thumbprint?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectAuction = {
  __typename?: 'ObjectAuction';
  additionalKeysStatus?: Maybe<AdditionalKeysStatusEnum>;
  auc?: Maybe<Auction>;
  aucId?: Maybe<Scalars['Int']>;
  commentForClient?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  freightPrice?: Maybe<Price>;
  id?: Maybe<Scalars['Int']>;
  isHaveVehicleRegistrationNumber?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  paidAuctionInvoiceDate?: Maybe<Scalars['DateTime']>;
  /**
   * Стоимость объекта в Дубае
   * @deprecated Move to carClientParam type
   */
  priceInStock?: Maybe<Price>;
  /**
   * Стоимость объекта в пути до Дубая
   * @deprecated Move to carClientParam type
   */
  priceOnDelivery?: Maybe<Price>;
  purchasePrice?: Maybe<Price>;
  receivedCopyAuctionDoc?: Maybe<Scalars['Boolean']>;
  sawPrice?: Maybe<Price>;
  stock?: Maybe<Stock>;
  troubleComment?: Maybe<Scalars['String']>;
  vanningPrice?: Maybe<Price>;
};

export enum AdditionalKeysStatusEnum {
  Exist = 'EXIST',
  PutInside = 'PUT_INSIDE',
  Received = 'RECEIVED'
}

export type Auction = {
  __typename?: 'Auction';
  address?: Maybe<Scalars['String']>;
  auctionData?: Maybe<AuctionData>;
  auctionsCountryRestrictions?: Maybe<Array<AuctionsCountryRestrictions>>;
  auctionsDealerFees?: Maybe<Array<AuctionsDealerFees>>;
  auctionsDeliveryCosts?: Maybe<Array<AuctionsDeliveryCosts>>;
  /** Лого банка */
  bankLogo?: Maybe<S3File>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rating?: Maybe<AuctionRating>;
  stock?: Maybe<Stock>;
  ticketRules?: Maybe<Scalars['String']>;
};

export type AuctionData = {
  __typename?: 'AuctionData';
  auctionDealer?: Maybe<Scalars['Int']>;
  auctionDelivery?: Maybe<Scalars['Int']>;
  auctionDeliveryUndriveable?: Maybe<Scalars['Int']>;
  auctionSbor?: Maybe<Scalars['Int']>;
  auctionSborByNegotiations?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Carrier>;
  costDelivery?: Maybe<Scalars['Float']>;
  destinationStock?: Maybe<Stock>;
  freight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  isNoGroup?: Maybe<Scalars['Boolean']>;
  isNoPallet?: Maybe<Scalars['Boolean']>;
  port?: Maybe<Port>;
  remoteness?: Maybe<AuctionRemotenessEnum>;
  sourceStocks?: Maybe<Array<Stock>>;
};

export type Port = {
  __typename?: 'Port';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  portData?: Maybe<PortData>;
  stock?: Maybe<Stock>;
};

export type PortData = {
  __typename?: 'PortData';
  countryIso?: Maybe<Scalars['String']>;
  freight?: Maybe<Scalars['Float']>;
  freightM3?: Maybe<Scalars['Float']>;
  freightPart?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};

export enum AuctionRemotenessEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type AuctionsCountryRestrictions = {
  __typename?: 'AuctionsCountryRestrictions';
  countryIso?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type AuctionsDealerFees = {
  __typename?: 'AuctionsDealerFees';
  commission?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['Int']>;
};

export type AuctionsDeliveryCosts = {
  __typename?: 'AuctionsDeliveryCosts';
  costDelivery?: Maybe<Scalars['Float']>;
  destinationCountryIso?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type AuctionRating = {
  __typename?: 'AuctionRating';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Колличество оценок */
  evaluationCount?: Maybe<Scalars['Int']>;
  /** Колличество отзывов */
  feedbackCount?: Maybe<Scalars['Int']>;
  /** Id */
  id?: Maybe<Scalars['Int']>;
  /** Рейтинг аукциона */
  rating?: Maybe<Scalars['Float']>;
};

export type ObjectAuctionHistory = {
  __typename?: 'ObjectAuctionHistory';
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  solver?: Maybe<Scalars['String']>;
  textError?: Maybe<Scalars['String']>;
  textSolve?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AuctionListing = {
  __typename?: 'AuctionListing';
  auction?: Maybe<Auction>;
  auctionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  kai?: Maybe<Scalars['String']>;
  notSoldPrice?: Maybe<Scalars['Int']>;
  numberLot?: Maybe<Scalars['String']>;
  soldPrice?: Maybe<Scalars['Int']>;
  startPrice?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  transportCompany?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  wishPrice?: Maybe<Scalars['Int']>;
};

export type Status = {
  __typename?: 'Status';
  id?: Maybe<Scalars['Int']>;
  outerNameEng?: Maybe<Scalars['String']>;
  outerNameRu?: Maybe<Scalars['String']>;
};

export type AuctionPaySheet = {
  __typename?: 'AuctionPaySheet';
  auction?: Maybe<Auction>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  isSend?: Maybe<Scalars['Boolean']>;
  objectCount?: Maybe<Scalars['Int']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  /** Информация о том кто нажимал оплачен расчётный лист */
  paidData?: Maybe<Array<AuctionPaySheetPaidData>>;
  paySheetPayments?: Maybe<Array<AuctionPaySheetPayment>>;
  sendedAt?: Maybe<Scalars['DateTime']>;
};

export type AuctionPaySheetPaidData = {
  __typename?: 'AuctionPaySheetPaidData';
  createdTime?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type AuctionPaySheetPayment = {
  __typename?: 'AuctionPaySheetPayment';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type Bl = {
  __typename?: 'Bl';
  date?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<ObjectOfServices>>;
};

export type BookingRequest = {
  __typename?: 'BookingRequest';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  cancelReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  objectStateName?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  portFrom?: Maybe<Port>;
  sender?: Maybe<Sender>;
  senderCancelledAt?: Maybe<Scalars['DateTime']>;
  trip?: Maybe<Trip>;
};

export type Sender = {
  __typename?: 'Sender';
  address?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  defaultOwner?: Maybe<DefaultOwner>;
  email?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Scalars['String']>>;
  fax?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['String']>>;
};

export type DefaultOwner = {
  __typename?: 'DefaultOwner';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Trip = {
  __typename?: 'Trip';
  bls?: Maybe<Array<Bl>>;
  countObjectByPlan?: Maybe<Scalars['Int']>;
  /** Растаможка от */
  customsClearanceFrom?: Maybe<Scalars['DateTime']>;
  /** Растаможка до */
  customsClearanceTo?: Maybe<Scalars['DateTime']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  dateVl?: Maybe<Scalars['DateTime']>;
  exportDeclarations?: Maybe<Array<ExportDeclaration>>;
  exportPaidAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  fullTitle?: Maybe<Scalars['String']>;
  guaranteedPlaces?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isArrived?: Maybe<Scalars['Boolean']>;
  isBookingAllow?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isExport?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  numberContainerLine?: Maybe<Scalars['String']>;
  /** Third party objects */
  objects?: Maybe<Array<ObjectOfServices>>;
  plan?: Maybe<Plan>;
  plans?: Maybe<Array<Plan>>;
  portFrom?: Maybe<Port>;
  portTo?: Maybe<Port>;
  reservePlaces?: Maybe<Scalars['Int']>;
  seaCarrier?: Maybe<SeaCarrier>;
  shipped?: Maybe<Scalars['Boolean']>;
  /** Выгрузка от */
  shipUnloadingFrom?: Maybe<Scalars['DateTime']>;
  /** Выгрузка до */
  shipUnloadingTo?: Maybe<Scalars['DateTime']>;
  simpleTitle?: Maybe<Scalars['String']>;
  stockTo?: Maybe<Stock>;
  tripBoxes?: Maybe<Array<TripBox>>;
  unloadingByUser?: Maybe<User>;
  unloadingFinishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vessel?: Maybe<Vessel>;
  vesselArrived?: Maybe<Scalars['Boolean']>;
  vesselNotArrived?: Maybe<Scalars['Boolean']>;
};

export type ExportDeclaration = {
  __typename?: 'ExportDeclaration';
  bookingNo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  objects?: Maybe<Array<ObjectOfServices>>;
  registrationDate?: Maybe<Scalars['DateTime']>;
};

export type SeaCarrier = {
  __typename?: 'SeaCarrier';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TripBox = {
  __typename?: 'TripBox';
  destinationOffice?: Maybe<Scalars['Int']>;
  destinationOfficeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<ObjectPackage>>;
  trip?: Maybe<Trip>;
};

export type ObjectPackage = {
  __typename?: 'ObjectPackage';
  attachments?: Maybe<Array<ObjectAttachment>>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  emailSentAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  isReturnableToOffice?: Maybe<Scalars['Boolean']>;
  isReturnableToTrip?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<User>;
  object?: Maybe<ObjectOfServices>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  recipient?: Maybe<User>;
  tripBox?: Maybe<TripBox>;
};

export type ObjectAttachment = {
  __typename?: 'ObjectAttachment';
  comment?: Maybe<Scalars['String']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  package?: Maybe<ObjectPackage>;
  quantity?: Maybe<Scalars['Int']>;
  statusName?: Maybe<Scalars['String']>;
};

export type Vessel = {
  __typename?: 'Vessel';
  id?: Maybe<Scalars['Int']>;
  isRoro?: Maybe<Scalars['Boolean']>;
  lastNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numbers?: Maybe<Array<Scalars['String']>>;
  vesselType?: Maybe<VesselTypeInfo>;
};

export type VesselTypeInfo = {
  __typename?: 'VesselTypeInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type ObjectToSale = {
  __typename?: 'ObjectToSale';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Price>;
  saleAt?: Maybe<Scalars['DateTime']>;
};

export type CarClientParam = {
  __typename?: 'CarClientParam';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hasTelegram?: Maybe<Scalars['Boolean']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isSendToChannel?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  priceInDestination?: Maybe<Price>;
  priceInJapan?: Maybe<Price>;
  priceOnDelivery?: Maybe<Price>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectClearance = {
  __typename?: 'ObjectClearance';
  createdAt?: Maybe<Scalars['DateTime']>;
  dateClearance?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
};

export type TypeOfExport = {
  __typename?: 'TypeOfExport';
  id?: Maybe<Scalars['Int']>;
  isDefaultForScrap?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
  outerNameEng?: Maybe<Scalars['String']>;
  outerNameRu?: Maybe<Scalars['String']>;
};

export type TypeOfChassis = {
  __typename?: 'TypeOfChassis';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<ChassisTypeEnum>;
};

export enum ChassisTypeEnum {
  Chassis = 'chassis',
  Frame = 'frame'
}

export type ObjectComment = {
  __typename?: 'ObjectComment';
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ComplexByInvoice = {
  __typename?: 'ComplexByInvoice';
  /** Комплекс услуг */
  complex?: Maybe<Scalars['Float']>;
  /** Фрахт */
  freight?: Maybe<Scalars['Float']>;
  /** Стоимость покупки */
  purchase?: Maybe<Scalars['Float']>;
  /** Ресайкл */
  recycle?: Maybe<Scalars['Float']>;
  /** Итого */
  total?: Maybe<Scalars['Float']>;
};

export enum ConditionConsigneeEnum {
  /** Отсутствует */
  Empty = 'Empty',
  /** Обработан */
  Processed = 'Processed',
  /** Предоставлен */
  Provided = 'Provided',
  /** Отклонен */
  Rejected = 'Rejected'
}

export type CreditStorage = {
  __typename?: 'CreditStorage';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']>;
};

export type ExportTrip = {
  __typename?: 'ExportTrip';
  cancelReason?: Maybe<Scalars['String']>;
  declarationFile?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  invoiceFile?: Maybe<S3File>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type OnPlan = {
  __typename?: 'OnPlan';
  boardingAt?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<Scalars['Int']>;
  hasGeneratedBl?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isAttention?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  jpCheckerNumber?: Maybe<Scalars['Int']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<Plan>;
  quantity?: Maybe<Scalars['Int']>;
  quantityCancelled?: Maybe<Scalars['Int']>;
  reason?: Maybe<PlanCancellationReason>;
  unloadingAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type PlanCancellationReason = {
  __typename?: 'PlanCancellationReason';
  id?: Maybe<Scalars['Int']>;
  nameEn?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
};

export type ThirdPartyTrip = {
  __typename?: 'ThirdPartyTrip';
  cancelledAt?: Maybe<Scalars['DateTime']>;
  cancelReason?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityCancelled?: Maybe<Scalars['String']>;
  trip?: Maybe<Trip>;
};

export type ObjectCustomDocument = {
  __typename?: 'ObjectCustomDocument';
  /** Список принятых типов документов которые нельзя перезагружать */
  acceptedFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Время смены статуса декларантом */
  changeStateAt?: Maybe<Scalars['DateTime']>;
  /** Требуется явка в таможню */
  comeToCustoms?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']>;
  invoiceCreateDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Документы приняты */
  isAccepted?: Maybe<Scalars['Boolean']>;
  /** Документы отклонены */
  isDecline?: Maybe<Scalars['Boolean']>;
  /** Можно ли принять или отменить набор документов */
  isNeedAction?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  /** Причина отмены */
  reasonCancel?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Пользователь */
  user?: Maybe<User>;
};

export type CustomPayment = {
  __typename?: 'CustomPayment';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfCustom = {
  __typename?: 'TypeOfCustom';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ObjectDelayRequest = {
  __typename?: 'ObjectDelayRequest';
  createdAt?: Maybe<Scalars['DateTime']>;
  holdTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  startDelayFrom?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type ObjectDelivered = {
  __typename?: 'ObjectDelivered';
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  isDelivered?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<Plan>;
  sourceStock?: Maybe<Stock>;
  systemCreatedAt?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['DateTime']>;
  vehicle?: Maybe<Vehicle>;
  watch?: Maybe<Watch>;
};

export type Watch = {
  __typename?: 'Watch';
  airbagExploded?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['Int']>;
  colorObject?: Maybe<Color>;
  commentWatch?: Maybe<Scalars['String']>;
  countKeys?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  emptyBattery?: Maybe<Scalars['Boolean']>;
  engine?: Maybe<Scalars['String']>;
  engineCapacityL?: Maybe<Scalars['Float']>;
  engineModel?: Maybe<Scalars['String']>;
  enginePowerKwh?: Maybe<Scalars['Float']>;
  engineType?: Maybe<TypeOfEngine>;
  engineVolumeCm3?: Maybe<Scalars['Float']>;
  explodeDate?: Maybe<Scalars['DateTime']>;
  explodedBy?: Maybe<User>;
  explodeReportPrintedAt?: Maybe<Scalars['DateTime']>;
  freonRemoved?: Maybe<Scalars['Boolean']>;
  glonassIdentifier?: Maybe<Scalars['String']>;
  glonassModel?: Maybe<Scalars['String']>;
  hasSdCard?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isAcceptSecurity?: Maybe<Scalars['Boolean']>;
  isBroken?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isDrivable?: Maybe<Scalars['Boolean']>;
  isNeedEngineWatch?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Int']>;
  loadCapacity?: Maybe<Scalars['Float']>;
  maker?: Maybe<Maker>;
  makerColor?: Maybe<MakerColor>;
  mark?: Maybe<Mark>;
  mileage?: Maybe<Scalars['Int']>;
  model?: Maybe<Model>;
  noFuel?: Maybe<Scalars['Boolean']>;
  permittedMaximumWeightSbkts?: Maybe<Scalars['Int']>;
  recycleNumber?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Int']>;
  transmission?: Maybe<Transmission>;
  user?: Maybe<User>;
  watchCarType?: Maybe<TypeOfWatchCar>;
  weight?: Maybe<Scalars['Float']>;
  weightFull?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
};

export type Color = {
  __typename?: 'Color';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameWithoutCode?: Maybe<Scalars['String']>;
};

export type TypeOfEngine = {
  __typename?: 'TypeOfEngine';
  alias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type MakerColor = {
  __typename?: 'MakerColor';
  colorCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  maker?: Maybe<Maker>;
};

export type Model = {
  __typename?: 'Model';
  chassisType?: Maybe<Chassis>;
  engine?: Maybe<Engine>;
  height?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageEngine?: Maybe<S3File>;
  length?: Maybe<Scalars['String']>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']>;
  places?: Maybe<Scalars['String']>;
  transmission?: Maybe<Transmission>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['String']>;
};

export type Chassis = {
  __typename?: 'Chassis';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Engine = {
  __typename?: 'Engine';
  engineType?: Maybe<TypeOfEngine>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type Transmission = {
  __typename?: 'Transmission';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfWatchCar = {
  __typename?: 'TypeOfWatchCar';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ObjectDelivery = {
  __typename?: 'ObjectDelivery';
  contract?: Maybe<Scalars['Int']>;
  destinationSector?: Maybe<Sector>;
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  isFound?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<Plan>;
  sourceSector?: Maybe<Sector>;
  sourceStock?: Maybe<Stock>;
  systemCreatedAt?: Maybe<Scalars['DateTime']>;
  watch?: Maybe<Watch>;
};

export type DeliveryRequest = {
  __typename?: 'DeliveryRequest';
  assignCompanyAt?: Maybe<Scalars['DateTime']>;
  carrier?: Maybe<Carrier>;
  carrierComment?: Maybe<Scalars['String']>;
  clientPrice?: Maybe<Price>;
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdOver3Hours?: Maybe<Scalars['Boolean']>;
  deliveredDate?: Maybe<Scalars['DateTime']>;
  deliveryPrice?: Maybe<Price>;
  destinationStock?: Maybe<Stock>;
  driver?: Maybe<Driver>;
  driverBeginAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isAddedToPlan?: Maybe<Scalars['Boolean']>;
  isFound?: Maybe<Scalars['Boolean']>;
  /** Наши японские доставки c аукциона, между складами */
  isOwnJapan?: Maybe<Scalars['Boolean']>;
  /** Наши РФ доставки по перегонам */
  isOwnRussian?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<DeliveryPlan>;
  priority?: Maybe<Scalars['Boolean']>;
  sourceStock?: Maybe<Stock>;
  status?: Maybe<Status>;
};

export type DeliveryPlan = {
  __typename?: 'DeliveryPlan';
  completedAt?: Maybe<Scalars['DateTime']>;
  countObjects?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  deliveryRequests?: Maybe<Array<DeliveryRequest>>;
  driver?: Maybe<Driver>;
  id?: Maybe<Scalars['Int']>;
  isBeginDelivery?: Maybe<Scalars['Boolean']>;
  numberPlan?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicle?: Maybe<Vehicle>;
};

export enum OrganizationEnum {
  AkebonoLtd = 'AKEBONO_LTD',
  Autoimport = 'AUTOIMPORT'
}

export type DesiredTrip = {
  __typename?: 'DesiredTrip';
  createdAt?: Maybe<Scalars['DateTime']>;
  draftObject?: Maybe<DraftFreightObject>;
  id?: Maybe<Scalars['Int']>;
  object?: Maybe<ObjectOfServices>;
  trip?: Maybe<Trip>;
};

export type DraftFreightObject = {
  __typename?: 'DraftFreightObject';
  auction?: Maybe<Auction>;
  broker?: Maybe<Broker>;
  client?: Maybe<Owner>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfManufacture?: Maybe<Scalars['DateTime']>;
  desiredTrip?: Maybe<DesiredTrip>;
  draftBroker?: Maybe<DraftBroker>;
  draftClient?: Maybe<DraftClient>;
  exportPrice?: Maybe<Price>;
  id?: Maybe<Scalars['Int']>;
  isBoundary?: Maybe<Scalars['Boolean']>;
  maker?: Maybe<Maker>;
  mark?: Maybe<Mark>;
  name?: Maybe<Scalars['String']>;
  objectStateName?: Maybe<ObjectStateEnum>;
  payCountry?: Maybe<Country>;
  sender?: Maybe<Sender>;
  senderClient?: Maybe<DraftSenderClient>;
  vin?: Maybe<Scalars['String']>;
};

export type DraftBroker = {
  __typename?: 'DraftBroker';
  address?: Maybe<Scalars['String']>;
  contacts?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DraftClient = {
  __typename?: 'DraftClient';
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
};

export enum ObjectStateEnum {
  Car = 'CAR',
  Cut = 'CUT'
}

export type DraftSenderClient = {
  __typename?: 'DraftSenderClient';
  agent?: Maybe<Agent>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type DiagnosticDoc = {
  __typename?: 'DiagnosticDoc';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  issuedDate?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Disassemble = {
  __typename?: 'Disassemble';
  backPart?: Maybe<Scalars['Boolean']>;
  enginePart?: Maybe<Scalars['Boolean']>;
  forwardPart?: Maybe<Scalars['Boolean']>;
  framePart?: Maybe<Scalars['Boolean']>;
  palletPart?: Maybe<Scalars['Boolean']>;
};

export enum DisassemblyStateEnum {
  Active = 'active',
  Await = 'await',
  Completed = 'completed',
  Finish = 'finish',
  FinishNotSend = 'finish_not_send',
  Processing = 'processing'
}

export type Elpts = {
  __typename?: 'Elpts';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  issuedDate?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectNotification = {
  __typename?: 'ObjectNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  emails?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phones?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ExportCertificate = {
  __typename?: 'ExportCertificate';
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['String']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  issuedStatus?: Maybe<StatusToObject>;
  number?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  receivedOriginal?: Maybe<Scalars['Int']>;
  receivedStatus?: Maybe<StatusToObject>;
  returnAt?: Maybe<Scalars['DateTime']>;
  returnReason?: Maybe<Scalars['String']>;
  scheduledDate?: Maybe<Scalars['String']>;
};

export type StatusToObject = {
  __typename?: 'StatusToObject';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Status>;
};

export type CarFeedback = {
  __typename?: 'CarFeedback';
  /** Аукцион */
  auction?: Maybe<Auction>;
  /** Информация об обьекте */
  carInfo?: Maybe<CarInfo>;
  /** Дата создания */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Оценка 1-5 */
  evaluation?: Maybe<Scalars['Int']>;
  /** Отзыв */
  feedback?: Maybe<Scalars['String']>;
  /** Id */
  id?: Maybe<Scalars['Int']>;
  /** Создатель отзыва */
  owner?: Maybe<OwnerFeedback>;
};

export type CarInfo = {
  __typename?: 'CarInfo';
  /** Цвет */
  color?: Maybe<Scalars['String']>;
  /** Производитель */
  maker?: Maybe<Scalars['String']>;
  /** Марка */
  mark?: Maybe<Scalars['String']>;
  /** Год выпуска */
  year?: Maybe<Scalars['String']>;
};

export type OwnerFeedback = {
  __typename?: 'OwnerFeedback';
  /** Id пользователя */
  id?: Maybe<Scalars['Int']>;
  /** Ссылка на лот */
  link?: Maybe<Scalars['String']>;
  /** Колличество покупок совешенных пользователем */
  purchase?: Maybe<Scalars['Int']>;
  /** Рейтинг пользователя */
  rating?: Maybe<UserRatingEnum>;
};

/** Рейтинг */
export enum UserRatingEnum {
  Gold = 'gold',
  Newbie = 'newbie',
  Silver = 'silver'
}

export type FormalOwner = {
  __typename?: 'FormalOwner';
  bank?: Maybe<Scalars['String']>;
  bik?: Maybe<Scalars['String']>;
  dataForDocuments?: Maybe<OwnerData>;
  dateOfBirthday?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  documentIssuedAt?: Maybe<Scalars['DateTime']>;
  documentIssuedUnit?: Maybe<Scalars['String']>;
  documentIssuedUnitName?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inn?: Maybe<Scalars['String']>;
  innFile?: Maybe<S3File>;
  isLegalPerson?: Maybe<Scalars['Boolean']>;
  kpp?: Maybe<Scalars['String']>;
  ks?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  ogrn?: Maybe<Scalars['String']>;
  ogrnFile?: Maybe<S3File>;
  passportNumber?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Scalars['String']>>;
  placeOfBirthday?: Maybe<Scalars['String']>;
  registrationAddress?: Maybe<RegistrationAddress>;
  rs?: Maybe<Scalars['String']>;
  snils?: Maybe<Scalars['String']>;
};

export type RegistrationAddress = {
  __typename?: 'RegistrationAddress';
  country?: Maybe<Country>;
  detailedRegistrationAddress?: Maybe<DetailedRegistrationAddress>;
  fullAddressNameEng?: Maybe<Scalars['String']>;
  fullAddressNameRu?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type DetailedRegistrationAddress = {
  __typename?: 'DetailedRegistrationAddress';
  cityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  flatType?: Maybe<TypeOfFlat>;
  house?: Maybe<Scalars['String']>;
  houseType?: Maybe<TypeOfHouse>;
  housing?: Maybe<Scalars['String']>;
  housingType?: Maybe<TypeOfHousing>;
  localityName?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
};

export type TypeOfFlat = {
  __typename?: 'TypeOfFlat';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfHouse = {
  __typename?: 'TypeOfHouse';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfHousing = {
  __typename?: 'TypeOfHousing';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum FormalOwnerStateEnum {
  FormalOwner = 'FORMAL_OWNER',
  None = 'NONE',
  Recipient = 'RECIPIENT'
}

export type InventoryObject = {
  __typename?: 'InventoryObject';
  checkedAt?: Maybe<Scalars['DateTime']>;
  checker?: Maybe<User>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inventory?: Maybe<Inventory>;
  inventorySource?: Maybe<Scalars['String']>;
  isFound?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  objectName?: Maybe<Scalars['String']>;
  sector?: Maybe<Sector>;
  vin?: Maybe<Scalars['String']>;
};

export type Inventory = {
  __typename?: 'Inventory';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  sectors?: Maybe<Array<Sector>>;
  solver?: Maybe<User>;
  stock?: Maybe<Stock>;
};

export type InvoiceCustom = {
  __typename?: 'InvoiceCustom';
  address?: Maybe<Scalars['String']>;
  advance?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  customInvoiceUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameRu?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceType?: Maybe<InvoiceType>;
  number?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  objectsInvoice?: Maybe<Array<InvoiceCustomObject>>;
  owner?: Maybe<Owner>;
  phone?: Maybe<Scalars['String']>;
  purposeMessage?: Maybe<Scalars['String']>;
  toBase64?: Maybe<Scalars['String']>;
  type?: Maybe<InvoiceCustomTypeEnum>;
  useDeposit?: Maybe<Scalars['Boolean']>;
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type InvoiceCustomObject = {
  __typename?: 'InvoiceCustomObject';
  amount?: Maybe<Scalars['Float']>;
  complexAmount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  freight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  object?: Maybe<ObjectOfServices>;
};

export enum InvoiceCustomTypeEnum {
  Advance = 'advance',
  Default = 'default',
  Deposit = 'deposit',
  YahooDeposit = 'yahoo_deposit'
}

export type IssueBlock = {
  __typename?: 'IssueBlock';
  comment?: Maybe<Scalars['String']>;
  sourceType?: Maybe<IssueBlockedSourceEnum>;
};

export enum IssueBlockedSourceEnum {
  Navis = 'navis',
  Sender = 'sender',
  TemporaryStorage = 'temporary_storage',
  Trip = 'trip'
}

/** Объект на который произвели начисление со связью к заявке на доставку или сгон */
export type JapanPaymentsToRequest = {
  __typename?: 'JapanPaymentsToRequest';
  /** Начисленная сумма */
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Статус отправки в 1С */
  isSend?: Maybe<Scalars['Boolean']>;
  /** Квинтанция расходов по Японии */
  japanPayment?: Maybe<JapanPayment>;
  /** Объект */
  object?: Maybe<ObjectOfServices>;
  /** Заявка с аукциона или сгон */
  request?: Maybe<DeliveryUnion>;
};

/** Квинтанция расходов по Японии */
export type JapanPayment = {
  __typename?: 'JapanPayment';
  /** Кол-во заявок */
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  /** Водитель выбрранный для начисления расходов */
  driver?: Maybe<Driver>;
  id?: Maybe<Scalars['Int']>;
  /** Заявки на доставк, сгон и объект за этот период */
  japanPaymentsToRequests?: Maybe<Array<Maybe<JapanPaymentsToRequest>>>;
  /** Статус обработки */
  status?: Maybe<Scalars['Boolean']>;
  /** Общая сумма в иенах */
  totalAmount?: Maybe<Scalars['Int']>;
  /** Тип начисления */
  type?: Maybe<TypeOfJapanPaymentEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Юзер создавший запись */
  user?: Maybe<User>;
};

export enum TypeOfJapanPaymentEnum {
  Food = 'FOOD',
  Fuel = 'FUEL',
  Highway = 'HIGHWAY'
}

export type DeliveryUnion = DeliveryRequest | ObjectDelivered;

export type KaitaiParam = {
  __typename?: 'KaitaiParam';
  id?: Maybe<Scalars['Int']>;
  purchasePrice?: Maybe<Price>;
};

export type StorageDaysByStock = {
  __typename?: 'StorageDaysByStock';
  carrier?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityStorageDays?: Maybe<Scalars['Int']>;
  stock?: Maybe<Stock>;
};

export type ObjectLogEvent = {
  __typename?: 'ObjectLogEvent';
  createdTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userName?: Maybe<Scalars['String']>;
};

export type ObjectMedia = {
  __typename?: 'ObjectMedia';
  items?: Maybe<Array<ObjectMediaItem>>;
  mediaType?: Maybe<MediaTypeEnum>;
};

export type ObjectMediaItem = {
  __typename?: 'ObjectMediaItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  fullPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageOrder?: Maybe<Scalars['Int']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isPhoto?: Maybe<Scalars['Boolean']>;
  thumbPath?: Maybe<Scalars['String']>;
};

export type ObjectMirror = {
  __typename?: 'ObjectMirror';
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
};

export type Recipient = {
  __typename?: 'Recipient';
  customPaymentType?: Maybe<CustomPayment>;
  declarant?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inn?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  /** Грузополучатель находится в Дальневосточном федеральном округе */
  isDvfo?: Maybe<Scalars['Boolean']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  isNotProcessed?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  legalName?: Maybe<Scalars['String']>;
  nameIgnoreType?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export enum ObjectActionsEnum {
  AddComment = 'add_comment',
  AddDeliveryToStock = 'add_delivery_to_stock',
  AddDisassemblyManual = 'add_disassembly_manual',
  AddDisassemblyNotice = 'add_disassembly_notice',
  AddObjectToPlan = 'add_object_to_plan',
  AddPriorityPass = 'add_priority_pass',
  AddRecipientsBroker = 'add_recipients_broker',
  AddRecipientsClient = 'add_recipients_client',
  AddRikuunAccrual = 'add_rikuun_accrual',
  Attachments = 'attachments',
  ChangeBroker = 'change_broker',
  ChangeDesiredTrip = 'change_desired_trip',
  ChangeDestinationCountry = 'change_destination_country',
  ChangeExportPrice = 'change_export_price',
  ChangeFormalOwner = 'change_formal_owner',
  ChangeFreightCountry = 'change_freight_country',
  ChangeObjectInLoading = 'change_object_in_loading',
  ChangeObjectType = 'change_object_type',
  ChangeObjectVehicleType = 'change_object_vehicle_type',
  ChangeOwner = 'change_owner',
  ChangeParentObject = 'change_parent_object',
  ChangeSawType = 'change_saw_type',
  ChangeSender = 'change_sender',
  ChangeSenderClient = 'change_sender_client',
  ChangeVin = 'change_vin',
  CheckDateManufacture = 'check_date_manufacture',
  CompleteObject = 'complete_object',
  CreateAct = 'create_act',
  CreateChat = 'create_chat',
  CreateDeliveryRequest = 'create_delivery_request',
  CreateFeedbackCar = 'create_feedback_car',
  CreateInvoiceJapanAuctionPurchase = 'create_invoice_japan_auction_purchase',
  CreateParkingRequest = 'create_parking_request',
  CreateSbktsRequest = 'create_sbkts_request',
  CreateTicket = 'create_ticket',
  DeleteObject = 'delete_object',
  ExportToAuction = 'export_to_auction',
  FillEptsForm = 'fill_epts_form',
  HideImages = 'hide_images',
  HoldTo = 'hold_to',
  LockIssuance = 'lock_issuance',
  MoveMediaToObject = 'move_media_to_object',
  PbAgreementSended = 'pb_agreement_sended',
  PbAgreementUpload = 'pb_agreement_upload',
  PickupAppointment = 'pickup_appointment',
  PrintQr = 'print_qr',
  PutUpForSaleCreate = 'put_up_for_sale_create',
  PutUpForSaleSoldOut = 'put_up_for_sale_sold_out',
  PutUpForSaleUpdate = 'put_up_for_sale_update',
  PutUpForSaleWithdraw = 'put_up_for_sale_withdraw',
  RemoveFromStock = 'remove_from_stock',
  RemoveObjectFromPlan = 'remove_object_from_plan',
  ReplaceDouble = 'replace_double',
  ReplaceToStock = 'replace_to_stock',
  ReportPaymentAkebono = 'report_payment_akebono',
  RequestDeliveryRussia = 'request_delivery_russia',
  RequestInvoicePrimBroker = 'request_invoice_prim_broker',
  RequestPrepareDocuments = 'request_prepare_documents',
  RequestToIssue = 'request_to_issue',
  ResetObject = 'reset_object',
  RestoreObject = 'restore_object',
  SendObjectTo_1c = 'send_object_to_1c',
  SetDateDismantleForCube = 'set_date_dismantle_for_cube',
  SetDateRecyclingToCube = 'set_date_recycling_to_cube',
  SetDateSoldRecyclingCube = 'set_date_sold_recycling_cube',
  SetGeneralBasisAdvance = 'set_general_basis_advance',
  SetGlonassNeed = 'set_glonass_need',
  SetGuidFrom_1c = 'set_guid_from_1c',
  SetIsBoundary = 'set_is_boundary',
  SetIsGreenCorner = 'set_is_green_corner',
  SetMasterAccountClient = 'set_master_account_client',
  SetObjectCatalogPrices = 'set_object_catalog_prices',
  SetObjectSoldDate = 'set_object_sold_date',
  ShowImages = 'show_images',
  UnlockIssuance = 'unlock_issuance',
  UploadClientImages = 'upload_client_images',
  UploadCustomPaymentDoc = 'upload_custom_payment_doc',
  UploadFiles = 'upload_files',
  VideoWatchRequest = 'video_watch_request',
  WatchObjectInLoading = 'watch_object_in_loading'
}

export type ObjectDisassemble = {
  __typename?: 'ObjectDisassemble';
  assignedAt?: Maybe<Scalars['DateTime']>;
  assignedBy?: Maybe<User>;
  assistants?: Maybe<Array<User>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  finishWorkAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isCurrentUserJob?: Maybe<Scalars['Boolean']>;
  manual?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  selfAssistant?: Maybe<Scalars['Boolean']>;
  startWorkAt?: Maybe<Scalars['DateTime']>;
  worker?: Maybe<User>;
};

export enum ObjectIconsEnum {
  CustomsPaymentClient = 'CUSTOMS_PAYMENT_CLIENT',
  CustomsReceiptsInvoice = 'CUSTOMS_RECEIPTS_INVOICE',
  HasElpts = 'HAS_ELPTS',
  HasSbkts = 'HAS_SBKTS',
  IconAdditionalKeys = 'ICON_ADDITIONAL_KEYS',
  IconAttachments = 'ICON_ATTACHMENTS',
  IconAttention = 'ICON_ATTENTION',
  IconAuctionDocuments = 'ICON_AUCTION_DOCUMENTS',
  IconAuctionPaid = 'ICON_AUCTION_PAID',
  IconBl = 'ICON_BL',
  IconBlockedBy_1C = 'ICON_BLOCKED_BY_1C',
  IconBlockedBySender = 'ICON_BLOCKED_BY_SENDER',
  IconBlockedByTrip = 'ICON_BLOCKED_BY_TRIP',
  IconBoundary = 'ICON_BOUNDARY',
  IconBroken = 'ICON_BROKEN',
  IconCarDelete = 'ICON_CAR_DELETE',
  IconComplete = 'ICON_COMPLETE',
  IconCopyAuctionDocs = 'ICON_COPY_AUCTION_DOCS',
  IconCut = 'ICON_CUT',
  IconDebt = 'ICON_DEBT',
  IconDelayedDelivery = 'ICON_DELAYED_DELIVERY',
  IconDocuments = 'ICON_DOCUMENTS',
  IconEmptyBattery = 'ICON_EMPTY_BATTERY',
  IconExportCertificate = 'ICON_EXPORT_CERTIFICATE',
  IconExportCertificateOriginal = 'ICON_EXPORT_CERTIFICATE_ORIGINAL',
  IconExportPrice = 'ICON_EXPORT_PRICE',
  IconFreightInvoiceNotPaid = 'ICON_FREIGHT_INVOICE_NOT_PAID',
  IconFreightInvoicePaid = 'ICON_FREIGHT_INVOICE_PAID',
  IconHasDisassemblyPhoto = 'ICON_HAS_DISASSEMBLY_PHOTO',
  IconHasDisassemblyVideo = 'ICON_HAS_DISASSEMBLY_VIDEO',
  IconHasOcrObject = 'ICON_HAS_OCR_OBJECT',
  IconHasRecipient = 'ICON_HAS_RECIPIENT',
  IconHasSbktsPdf = 'ICON_HAS_SBKTS_PDF',
  IconIsGreenCorner = 'ICON_IS_GREEN_CORNER',
  IconNeedFixSaw = 'ICON_NEED_FIX_SAW',
  IconNoAuctionDocuments = 'ICON_NO_AUCTION_DOCUMENTS',
  IconNoCopyAuctionDocs = 'ICON_NO_COPY_AUCTION_DOCS',
  IconNoExportCertificate = 'ICON_NO_EXPORT_CERTIFICATE',
  IconNoExportCertificateOriginal = 'ICON_NO_EXPORT_CERTIFICATE_ORIGINAL',
  IconNoFuel = 'ICON_NO_FUEL',
  IconNonPassLab = 'ICON_NON_PASS_LAB',
  IconNotDrivable = 'ICON_NOT_DRIVABLE',
  IconObjectMedia = 'ICON_OBJECT_MEDIA',
  IconPackages = 'ICON_PACKAGES',
  IconPaidStock = 'ICON_PAID_STOCK',
  IconPaySheet = 'ICON_PAY_SHEET',
  IconPbDocumentsIsDeclined = 'ICON_PB_DOCUMENTS_IS_DECLINED',
  IconPbDocumentsIsNotReceived = 'ICON_PB_DOCUMENTS_IS_NOT_RECEIVED',
  IconPbDocumentsIsReceived = 'ICON_PB_DOCUMENTS_IS_RECEIVED',
  IconPickUpAppointmentPlanned = 'ICON_PICK_UP_APPOINTMENT_PLANNED',
  IconPlannedVessel = 'ICON_PLANNED_VESSEL',
  IconReceiptOfPayment = 'ICON_RECEIPT_OF_PAYMENT',
  IconTemporaryPass = 'ICON_TEMPORARY_PASS',
  IconTickets = 'ICON_TICKETS',
  IconTrackingNumber = 'ICON_TRACKING_NUMBER'
}

export type ObjectPaidStorageData = {
  __typename?: 'ObjectPaidStorageData';
  /** Дней до начала начислений на склад */
  freeDaysLeft?: Maybe<Scalars['Int']>;
  /** Прошло дней с начислением */
  paidDays?: Maybe<Scalars['Int']>;
  /** Сумма к начислению */
  paidDaysSum?: Maybe<Scalars['Int']>;
};

export type ObjectParkingRequestCancel = {
  __typename?: 'ObjectParkingRequestCancel';
  comment?: Maybe<Scalars['String']>;
  commentAi?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ParkingRequestCanceledTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export enum ParkingRequestCanceledTypeEnum {
  Both = 'BOTH',
  Parking = 'PARKING',
  ToAutoimport = 'TO_AUTOIMPORT'
}

export type ObjectParkingRequest = {
  __typename?: 'ObjectParkingRequest';
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasOutFromSVHStatus?: Maybe<Scalars['Boolean']>;
  histories?: Maybe<Array<ObjectParkingRequestHistory>>;
  id?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Invoice>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isCanCancel?: Maybe<Scalars['Boolean']>;
  isCanClose?: Maybe<Scalars['Boolean']>;
  isCanRequestPass?: Maybe<Scalars['Boolean']>;
  isCanUploadFiles?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHasBeforeOuterParkingPhoto?: Maybe<Scalars['Boolean']>;
  isHasOuterParkingPhoto?: Maybe<Scalars['Boolean']>;
  isPrepared?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectParkingRequestHistory = {
  __typename?: 'ObjectParkingRequestHistory';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  user?: Maybe<User>;
};

export enum ObjectRelationTypeEnum {
  Attachment = 'ATTACHMENT',
  Saw = 'SAW',
  Self = 'SELF'
}

export type PaymentPlan = {
  __typename?: 'PaymentPlan';
  /** Ставки плана оплаты */
  conditions?: Maybe<Array<PaymentPlanCondition>>;
  /** Доп. описание */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Краткое обозначение */
  name: Scalars['String'];
};

export type PaymentPlanCondition = {
  __typename?: 'PaymentPlanCondition';
  /** Период до(-)/после(+) события */
  days: Scalars['Int'];
  /** Событие */
  event: PaymentPlanEventEnum;
  id: Scalars['Int'];
  /** Процентная ставка */
  percentage: Scalars['Int'];
};

export enum PaymentPlanEventEnum {
  /** Добавлен в план */
  AddToPlan = 'ADD_TO_PLAN',
  /** Дата прибытия в порт */
  BeforeArrived = 'BEFORE_ARRIVED',
  /** BL отправлен */
  BlSend = 'BL_SEND',
  /** Куплен */
  Buy = 'BUY',
  /** Погружен */
  LoadingToBoat = 'LOADING_TO_BOAT',
  /** Выгружен */
  Unload = 'UNLOAD'
}

export type PbAgreement = {
  __typename?: 'PbAgreement';
  cancelReason?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inn?: Maybe<Scalars['String']>;
  isAcceptedInOffice?: Maybe<Scalars['Boolean']>;
  isSendToOffice?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  passport?: Maybe<Scalars['String']>;
  passportAddress?: Maybe<DetailedRegistrationAddress>;
  passportIssued?: Maybe<Scalars['String']>;
  passportIssuedAt?: Maybe<Scalars['String']>;
  passportIssueUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectMediaBlock = {
  __typename?: 'ObjectMediaBlock';
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
};

export type PreDeliveryObject = {
  __typename?: 'PreDeliveryObject';
  delivery?: Maybe<DeliveryRequest>;
  executeAfterPaymentDate?: Maybe<Scalars['Boolean']>;
  frameNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDrivable?: Maybe<Scalars['Boolean']>;
  lotId?: Maybe<Scalars['String']>;
  maker?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  preDeliveryAuction?: Maybe<PreDeliveryAuction>;
  price?: Maybe<Price>;
  reasonReject?: Maybe<Scalars['String']>;
  status?: Maybe<DeliveryObjectStatusEnum>;
};

export type PreDeliveryAuction = {
  __typename?: 'PreDeliveryAuction';
  aucName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  delivery?: Maybe<DeliveryRequest>;
  id?: Maybe<Scalars['Int']>;
  isNumberCut?: Maybe<Scalars['Boolean']>;
  numberCut?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<PreDeliveryObject>>;
  port?: Maybe<Port>;
  preDeliveryRequest?: Maybe<PreDeliveryRequest>;
  stock?: Maybe<Stock>;
};

export type PreDeliveryRequest = {
  __typename?: 'PreDeliveryRequest';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  navisInternalId?: Maybe<Scalars['String']>;
  preDeliveryRequest?: Maybe<Array<PreDeliveryAuction>>;
};

export enum DeliveryObjectStatusEnum {
  Active = 'active',
  Reject = 'reject'
}

export type ReceiptOfPayment = {
  __typename?: 'ReceiptOfPayment';
  /** Сумма платежа */
  amount?: Maybe<Scalars['Float']>;
  /** Валюта платежа */
  currency?: Maybe<Currency>;
  /** Дата оплаты */
  dateOfPayment?: Maybe<Scalars['DateTime']>;
  /** Файл чека */
  file?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']>;
  /** Номер инвойса */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Комментарий */
  message?: Maybe<Scalars['String']>;
  /** Имя плательщика */
  senderName?: Maybe<Scalars['String']>;
};

export type RequestToIssueObject = {
  __typename?: 'RequestToIssueObject';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  plannedAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Sender>;
  storageDaysCount?: Maybe<Scalars['Int']>;
};

export type TypeOfSaw = {
  __typename?: 'TypeOfSaw';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plan?: Maybe<DisassemblyPlan>;
};

export type DisassemblyPlan = {
  __typename?: 'DisassemblyPlan';
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']>;
  isDelete?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  saw?: Maybe<TypeOfSaw>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SbktsRequest = {
  __typename?: 'SbktsRequest';
  activeAdditionalBlanks?: Maybe<Array<SbktsAdditionalBlank>>;
  activeHold?: Maybe<SbktsHold>;
  ambassador?: Maybe<Ambassador>;
  approve?: Maybe<SbktsApprove>;
  assignedUser?: Maybe<User>;
  assignedUserAt?: Maybe<Scalars['DateTime']>;
  beginWorkAt?: Maybe<Scalars['DateTime']>;
  blankNumber?: Maybe<Scalars['String']>;
  blankSeria?: Maybe<SbktsBlankSeria>;
  cancelReason?: Maybe<SbktsReason>;
  categoryCar?: Maybe<CategoryCar>;
  comments?: Maybe<Array<SbktsRequestComment>>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  damagedBlanks?: Maybe<Array<SbktsDamagedBlank>>;
  deletedAdditionalBlanks?: Maybe<Array<SbktsAdditionalBlank>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryToAi?: Maybe<DeliveryRequest>;
  deliveryToTroyan?: Maybe<DeliveryRequest>;
  events?: Maybe<Array<SbktsRequestEvent>>;
  expertDailyParams?: Maybe<Array<ExpertDailyParam>>;
  files?: Maybe<Array<Maybe<SbktsFile>>>;
  hasClosedHolds?: Maybe<Scalars['Boolean']>;
  holds?: Maybe<Array<SbktsHold>>;
  id?: Maybe<Scalars['Int']>;
  inBoxing?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isCanEditClosed?: Maybe<Scalars['Boolean']>;
  isCanPrint?: Maybe<Scalars['Boolean']>;
  isCanRestore?: Maybe<Scalars['Boolean']>;
  isCompleteRelease?: Maybe<Scalars['Boolean']>;
  isCreateBlank1c?: Maybe<Scalars['Boolean']>;
  isCreateObject1c?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmptyThumbprint?: Maybe<Scalars['Boolean']>;
  isHasFewTemplate?: Maybe<Scalars['Boolean']>;
  isHasRequestPdf?: Maybe<Scalars['Boolean']>;
  isHasRequiredAgentMedia?: Maybe<Scalars['Boolean']>;
  isLegalPerson?: Maybe<Scalars['Boolean']>;
  isPaperType?: Maybe<Scalars['Boolean']>;
  isPassedTests?: Maybe<Scalars['Boolean']>;
  isPassedTestsAt?: Maybe<Scalars['DateTime']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  isQuickly?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['String']>;
  isTroyanStock?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<Laboratory>;
  laboratoryExpert?: Maybe<LaboratoryExpert>;
  media?: Maybe<Array<Maybe<SbktsObjectMedia>>>;
  notApprovedByDays?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ObjectOfServices>;
  protocolNumber?: Maybe<Scalars['String']>;
  readySendToFgisAt?: Maybe<Scalars['DateTime']>;
  requester?: Maybe<Broker>;
  requestNumber?: Maybe<Scalars['String']>;
  sbktsObject?: Maybe<SbktsObject>;
  sendToFgisAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};


export type SbktsRequestNotApprovedByDaysArgs = {
  days: Scalars['Int'];
};

export type SbktsAdditionalBlank = {
  __typename?: 'SbktsAdditionalBlank';
  blankSeria?: Maybe<SbktsBlankSeria>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SbktsBlankSeria = {
  __typename?: 'SbktsBlankSeria';
  blankRegistration?: Maybe<SbktsBlankRegistration>;
  blankType?: Maybe<SbktsBlankSeriaEnum>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  usedCount?: Maybe<Scalars['Int']>;
};

export type SbktsBlankRegistration = {
  __typename?: 'SbktsBlankRegistration';
  id?: Maybe<Scalars['Int']>;
  numberFrom?: Maybe<Scalars['Int']>;
  numberTo?: Maybe<Scalars['Int']>;
};

export enum SbktsBlankSeriaEnum {
  TypeAdditional = 'TYPE_ADDITIONAL',
  TypeMain = 'TYPE_MAIN'
}

export type SbktsHold = {
  __typename?: 'SbktsHold';
  holdAt?: Maybe<Scalars['DateTime']>;
  holdType?: Maybe<TypeOfHold>;
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<SbktsReason>;
  unHoldAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type TypeOfHold = {
  __typename?: 'TypeOfHold';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SbktsReason = {
  __typename?: 'SbktsReason';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type SbktsApprove = {
  __typename?: 'SbktsApprove';
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type SbktsRequestComment = {
  __typename?: 'SbktsRequestComment';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type SbktsDamagedBlank = {
  __typename?: 'SbktsDamagedBlank';
  additionalBlank?: Maybe<SbktsAdditionalBlank>;
  blankSeria?: Maybe<SbktsBlankSeria>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  sbktsRequest?: Maybe<SbktsRequest>;
  status?: Maybe<SbktsDamagedStatusEnum>;
  user?: Maybe<User>;
};

export enum SbktsDamagedStatusEnum {
  StatusDamaged = 'STATUS_DAMAGED',
  StatusRevoke = 'STATUS_REVOKE'
}

export type SbktsRequestEvent = {
  __typename?: 'SbktsRequestEvent';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Модель с данными для заполнения ежедневных измерений */
export type ExpertDailyParam = {
  __typename?: 'ExpertDailyParam';
  /** Аффикс для названия передается во ФГИС */
  affixText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Ключ в документах */
  docName?: Maybe<Scalars['String']>;
  /** Подгруппа параметров */
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Лаборатория */
  laboratory?: Maybe<Laboratory>;
  /** Название параметра */
  name?: Maybe<Scalars['String']>;
  /** Порядок отображения */
  position?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SbktsFile = {
  __typename?: 'SbktsFile';
  createdAt?: Maybe<Scalars['DateTime']>;
  doc?: Maybe<DocxTemplate>;
  fileDocx?: Maybe<S3File>;
  filePdf?: Maybe<S3File>;
  fileSig?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  /** Список незаполненных полей в документе */
  undefinedVariables?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocxTemplate = {
  __typename?: 'DocxTemplate';
  categoriesCar?: Maybe<Array<CategoryCar>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentType?: Maybe<TypeOfDocumentEnum>;
  fileFizik?: Maybe<S3File>;
  filesOld?: Maybe<Array<Maybe<S3File>>>;
  fileUrik?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  internalUse?: Maybe<Scalars['Boolean']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<Laboratory>;
  name?: Maybe<Scalars['String']>;
  needSignDeputy?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  protocolToFgis?: Maybe<Scalars['Boolean']>;
  pteToFgis?: Maybe<Scalars['Boolean']>;
  template?: Maybe<DocxTemplateInfo>;
};

export enum TypeOfDocumentEnum {
  Header = 'header',
  Template = 'template'
}

export type DocxTemplateInfo = {
  __typename?: 'DocxTemplateInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SbktsObjectMedia = {
  __typename?: 'SbktsObjectMedia';
  createdAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<S3File>;
  fileOld?: Maybe<MediaUnion>;
  fileSig?: Maybe<S3File>;
  id?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<SbktsObjectMediaTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaUnion = ObjectMediaItem | S3File;

export enum SbktsObjectMediaTypeEnum {
  HeadlightTest = 'headlight_test',
  ImgBrakeSystem = 'img_brake_system',
  ImgConsignment = 'img_consignment',
  ImgEmissions = 'img_emissions',
  ImgGeneralView_1 = 'img_general_view_1',
  ImgGeneralView_2 = 'img_general_view_2',
  ImgIdentificationPlate = 'img_identification_plate',
  ImgInnLegalOwner = 'img_inn_legal_owner',
  ImgNoise = 'img_noise',
  ImgOgrnLegalOwner = 'img_ogrn_legal_owner',
  ImgPassportFirst = 'img_passport_first',
  ImgPassportMain = 'img_passport_main',
  ImgPassportRegistration = 'img_passport_registration',
  ImgTpo_1 = 'img_tpo_1',
  ImgTpo_2 = 'img_tpo_2',
  MeasuringPlatform = 'measuring_platform',
  VehicleFromBelow = 'vehicle_from_below'
}

export type SbktsObject = {
  __typename?: 'SbktsObject';
  axesNumber?: Maybe<Scalars['String']>;
  baseSize?: Maybe<Scalars['String']>;
  batterySystem?: Maybe<Scalars['String']>;
  brakeSystem?: Maybe<Scalars['String']>;
  cabine?: Maybe<Scalars['String']>;
  carCategory?: Maybe<Scalars['String']>;
  cargoPlace?: Maybe<Scalars['String']>;
  carLinkage?: Maybe<Scalars['String']>;
  chassis?: Maybe<Scalars['String']>;
  chassisOther?: Maybe<Scalars['String']>;
  clutchSystem?: Maybe<Scalars['String']>;
  collectorSystem?: Maybe<Scalars['String']>;
  combisionEngine?: Maybe<Scalars['String']>;
  combisionEngineCompression?: Maybe<Scalars['String']>;
  combisionEngineCylinder?: Maybe<Scalars['String']>;
  combisionEngineCylindersNumber?: Maybe<Scalars['String']>;
  combisionEngineCylindersPosition?: Maybe<Scalars['String']>;
  combisionEngineFuel?: Maybe<Scalars['String']>;
  combisionEngineFuelSystem?: Maybe<Scalars['String']>;
  combisionEngineIgnitionSystem?: Maybe<Scalars['String']>;
  combisionEngineMaxPowerKWt?: Maybe<Scalars['String']>;
  combisionEngineMaxRpm?: Maybe<Scalars['String']>;
  combisionEngineMinRpm?: Maybe<Scalars['String']>;
  combisionEngineVolumeCm3?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<DocumentTemplate>;
  driveType?: Maybe<Scalars['String']>;
  ecologyClass?: Maybe<Scalars['String']>;
  electroSystem?: Maybe<Scalars['String']>;
  electroSystem30mMaxPowerKWt?: Maybe<Scalars['String']>;
  electroSystemVoltage?: Maybe<Scalars['String']>;
  enginePosition?: Maybe<Scalars['String']>;
  forwardSuspensionSystem?: Maybe<Scalars['String']>;
  frameType?: Maybe<Scalars['String']>;
  fullWeight?: Maybe<Scalars['String']>;
  gearBoxSystem?: Maybe<Scalars['String']>;
  generatorSystem?: Maybe<Scalars['String']>;
  generatorSystem30mMaxPower?: Maybe<Scalars['String']>;
  generatorSystemVoltage?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  hybridSystem?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['String']>;
  luggagePlace?: Maybe<Scalars['String']>;
  mainBrakeSystem?: Maybe<Scalars['String']>;
  makerAddress?: Maybe<Scalars['String']>;
  makerName?: Maybe<Scalars['String']>;
  makerPlant?: Maybe<Scalars['String']>;
  manualBrakeSystem?: Maybe<Scalars['String']>;
  markName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  passengerPlaces?: Maybe<Scalars['String']>;
  places?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  protocol?: Maybe<DocumentProtocol>;
  protocolFields?: Maybe<Array<ProtocolField>>;
  rearSuspensionSystem?: Maybe<Scalars['String']>;
  request?: Maybe<SbktsRequest>;
  sbktsMaker?: Maybe<Scalars['String']>;
  sbktsMark?: Maybe<Scalars['String']>;
  sbktsModel?: Maybe<Scalars['String']>;
  seatPlace?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  spareBrakeSystem?: Maybe<Scalars['String']>;
  steerageSystem?: Maybe<Scalars['String']>;
  steeringWheelPosition?: Maybe<Scalars['String']>;
  suspensionSystem?: Maybe<Scalars['String']>;
  tires?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transmittionSystem?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['String']>;
  wheelsTrack?: Maybe<Scalars['String']>;
  wheelsType?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  comment?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<DocumentField>>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isElectricCar?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Int']>;
  maker?: Maybe<DocumentValue>;
  mark?: Maybe<DocumentValue>;
  model?: Maybe<DocumentValue>;
  name?: Maybe<Scalars['String']>;
  objectMaker?: Maybe<Maker>;
  objectMark?: Maybe<Mark>;
  objectModel?: Maybe<Model>;
  protocols?: Maybe<Array<DocumentProtocol>>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type DocumentField = {
  __typename?: 'DocumentField';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['Int']>;
};

export type DocumentValue = {
  __typename?: 'DocumentValue';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type DocumentProtocol = {
  __typename?: 'DocumentProtocol';
  additionalFields?: Maybe<Array<DocumentAdditionField>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  docTemplate?: Maybe<DocumentTemplate>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  model?: Maybe<Model>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentAdditionField = {
  __typename?: 'DocumentAdditionField';
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldName?: Maybe<DocumentFieldName>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type DocumentFieldName = {
  __typename?: 'DocumentFieldName';
  description?: Maybe<Scalars['String']>;
  docName?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isProtocol?: Maybe<Scalars['Boolean']>;
  isRegularFill?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<Laboratory>;
  position?: Maybe<Scalars['Int']>;
};

export type ProtocolField = {
  __typename?: 'ProtocolField';
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldName?: Maybe<DocumentFieldName>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ScrapObject = {
  __typename?: 'ScrapObject';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  auctionSheet?: Maybe<ObjectMediaItem>;
  autoExtension?: Maybe<Scalars['Boolean']>;
  basket?: Maybe<Scalars['String']>;
  buyoutPrice?: Maybe<Scalars['Int']>;
  carrier?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  countResubmitted?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Int']>;
  error?: Maybe<YahooLotError>;
  id?: Maybe<Scalars['Int']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDelivered?: Maybe<Scalars['Boolean']>;
  isNotSold?: Maybe<Scalars['Boolean']>;
  isResubmitted?: Maybe<Scalars['Boolean']>;
  isSentToYahoo?: Maybe<Scalars['Boolean']>;
  isSold?: Maybe<Scalars['Boolean']>;
  isUtilized?: Maybe<Scalars['Boolean']>;
  isWaitDelivery?: Maybe<Scalars['Boolean']>;
  isWaitUtilization?: Maybe<Scalars['Boolean']>;
  lastSubject?: Maybe<Scalars['String']>;
  lotSize?: Maybe<Scalars['String']>;
  lotSizeMultiplier?: Maybe<Scalars['Int']>;
  markdownRatio?: Maybe<YahooLotResubmitRatioEnum>;
  numResubmit?: Maybe<Scalars['Int']>;
  parentObject?: Maybe<ObjectOfServices>;
  price?: Maybe<Price>;
  scrapObject?: Maybe<ObjectOfServices>;
  scrapType?: Maybe<Scrap>;
  sendToYahooAt?: Maybe<Scalars['DateTime']>;
  sendToYahooBy?: Maybe<User>;
  shipNames?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['String']>;
  shippingInput?: Maybe<YahooLotShippingInputEnum>;
  shipschedule?: Maybe<Scalars['String']>;
  shipSizes?: Maybe<YahooLotShipSizes>;
  state?: Maybe<YahooStateLotEnum>;
  status?: Maybe<Status>;
  trackNumber?: Maybe<Scalars['String']>;
  yahooAccount?: Maybe<Scalars['String']>;
  yahooBuyerId?: Maybe<Scalars['Int']>;
  yahooCategoryCode?: Maybe<Scalars['String']>;
  yahooCategoryId?: Maybe<Scalars['String']>;
  yahooCount?: Maybe<Scalars['Int']>;
  yahooLotDescription?: Maybe<Scalars['String']>;
  yahooLotId?: Maybe<Scalars['String']>;
  yahooLotName?: Maybe<Scalars['String']>;
  yahooPlace?: Maybe<Scalars['String']>;
  yahooPlaceStorage?: Maybe<Scalars['String']>;
};

export type YahooLotError = {
  __typename?: 'YahooLotError';
  message?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
};

export enum YahooLotResubmitRatioEnum {
  V_0 = 'v_0',
  V_10 = 'v_10',
  V_15 = 'v_15',
  V_20 = 'v_20',
  V_25 = 'v_25',
  V_30 = 'v_30',
  V_5 = 'v_5'
}

export type Scrap = {
  __typename?: 'Scrap';
  aggregateType?: Maybe<Aggregate>;
  blockingParts?: Maybe<Array<Scrap>>;
  costInPercent?: Maybe<Scalars['Int']>;
  countObjects?: Maybe<Scalars['Int']>;
  description?: Maybe<ScrapTypeDescription>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isNeedEngineNumber?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  nameJp?: Maybe<Scalars['String']>;
  objectType?: Maybe<TypeOfExport>;
};

export type ScrapTypeDescription = {
  __typename?: 'ScrapTypeDescription';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  value?: Maybe<Scalars['String']>;
};

export enum YahooLotShippingInputEnum {
  Arrival = 'arrival',
  Later = 'later'
}

export type YahooLotShipSizes = {
  __typename?: 'YahooLotShipSizes';
  isJpYupacketOfficialShip?: Maybe<Scalars['Boolean']>;
  isJpYupackOfficialShip?: Maybe<Scalars['Boolean']>;
  isYahunekoNekoposuShip?: Maybe<Scalars['Boolean']>;
  isYahunekoTaqbinBigShip?: Maybe<Scalars['Boolean']>;
  isYahunekoTaqbinCompactShip?: Maybe<Scalars['Boolean']>;
  isYahunekoTaqbinShip?: Maybe<Scalars['Boolean']>;
};

export enum YahooStateLotEnum {
  New = 'new',
  Used10 = 'used10',
  Used20 = 'used20',
  Used40 = 'used40',
  Used60 = 'used60',
  Used80 = 'used80'
}

export type ObjectSender = {
  __typename?: 'ObjectSender';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  senderClient?: Maybe<SenderAgent>;
};

export type SenderAgent = {
  __typename?: 'SenderAgent';
  data?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sender?: Maybe<Sender>;
};

export type Tariff = {
  __typename?: 'Tariff';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ObjectAttachmentTypeLog = {
  __typename?: 'ObjectAttachmentTypeLog';
  message?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  user?: Maybe<Scalars['String']>;
};

export type LotTranslation = {
  __typename?: 'LotTranslation';
  file?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  translator?: Maybe<LotTranslator>;
};

export type LotTranslator = {
  __typename?: 'LotTranslator';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type TroyanCustomer = {
  __typename?: 'TroyanCustomer';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Wheel = {
  __typename?: 'Wheel';
  /** Объект с которого сняли колеса */
  disassembleObject?: Maybe<ObjectOfServices>;
  /** Производитель диска */
  diskManufacturer?: Maybe<Scalars['String']>;
  /** Тип диска */
  diskType?: Maybe<Scalars['String']>;
  /** ID обьекта */
  id?: Maybe<Scalars['Int']>;
  /** Собран на полете */
  isLoaded?: Maybe<Scalars['Boolean']>;
  /** Куплен */
  isPurchased?: Maybe<Scalars['Boolean']>;
  /** Палета */
  pallet?: Maybe<Pallet>;
  /** Цена */
  price?: Maybe<Price>;
  /** Производитель резины */
  tireManufacturer?: Maybe<Scalars['String']>;
  /** Высота */
  tireProfileHeight?: Maybe<Scalars['Int']>;
  /** Ширина */
  tireProfileWidth?: Maybe<Scalars['Int']>;
  /** Радиус */
  tireRadius?: Maybe<Scalars['Int']>;
  /** Тип резины */
  tireType?: Maybe<Scalars['String']>;
  /** Процент износа резины */
  tireWear?: Maybe<Scalars['Int']>;
};

export type Pallet = {
  __typename?: 'Pallet';
  /** ID палеты */
  id?: Maybe<Scalars['Int']>;
  /** Объект на складе порта */
  isInPort?: Maybe<Scalars['Boolean']>;
  /** Название плаеты */
  name?: Maybe<Scalars['String']>;
  /** ID номер */
  number?: Maybe<Scalars['String']>;
  /** Список колес */
  objects?: Maybe<Array<Maybe<ObjectOfServices>>>;
  /** Колличество бъектов */
  objectsCount?: Maybe<Scalars['Int']>;
  /** План отгрузки */
  plan?: Maybe<Plan>;
  /** Колличество колес */
  wheelCount?: Maybe<Scalars['Int']>;
};

export type LotteryTicker = {
  __typename?: 'LotteryTicker';
  agentId?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type PaidBidsDisplayStatus = {
  __typename?: 'PaidBidsDisplayStatus';
  createdAt?: Maybe<Scalars['DateTime']>;
  datePaidAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  paidBidsDisplayPrice?: Maybe<Scalars['Int']>;
};

export enum TypeOfOwnerEnum {
  Broker = 'BROKER',
  FormalOwner = 'FORMAL_OWNER',
  Owner = 'OWNER',
  Sender = 'SENDER'
}

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  createdAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
};

export type Permission = {
  __typename?: 'Permission';
  action?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  id?: Maybe<Scalars['Int']>;
  lastAt?: Maybe<Scalars['DateTime']>;
  period?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  systemCreatedAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  sync1C?: Maybe<Scalars['Boolean']>;
  systemCreatedAt?: Maybe<Scalars['String']>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['String']>;
};

export type TypeOfUser = {
  __typename?: 'TypeOfUser';
  id?: Maybe<Scalars['Int']>;
  nameEn?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfUserEnum>;
};

export enum TypeOfUserEnum {
  UserTypeBrokerAccount = 'USER_TYPE_BROKER_ACCOUNT',
  UserTypeClientAccount = 'USER_TYPE_CLIENT_ACCOUNT',
  UserTypeEmployeeAccount = 'USER_TYPE_EMPLOYEE_ACCOUNT',
  UserTypeRecipientAccount = 'USER_TYPE_RECIPIENT_ACCOUNT',
  UserTypeSenderAccount = 'USER_TYPE_SENDER_ACCOUNT',
  UserTypeSvhManagerAccount = 'USER_TYPE_SVH_MANAGER_ACCOUNT',
  UserTypeTroyanEmployeeAccount = 'USER_TYPE_TROYAN_EMPLOYEE_ACCOUNT'
}

export type UserParameter = {
  __typename?: 'UserParameter';
  agent?: Maybe<Agent>;
  baseModule?: Maybe<Module>;
  chatAlias?: Maybe<ChatAlias>;
  commentCssClass?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  driverMode?: Maybe<DriverMode>;
  expireAccount?: Maybe<Scalars['String']>;
  expirePassword?: Maybe<Scalars['Int']>;
  expirePermission?: Maybe<Scalars['Int']>;
  expireToken?: Maybe<Scalars['Int']>;
  freightPaymentCountry?: Maybe<Country>;
  guid1C?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isChangePassword?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  language?: Maybe<LanguageEnum>;
  oldRole?: Maybe<UserRole>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
  smsPasswordChangedAt?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userType?: Maybe<TypeOfUser>;
};

export type Module = {
  __typename?: 'Module';
  applicationId?: Maybe<Scalars['Int']>;
  applicationName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ChatAlias = {
  __typename?: 'ChatAlias';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
};

export type Policy = {
  __typename?: 'Policy';
  baseModule?: Maybe<Module>;
  carrier?: Maybe<CarrierInfo>;
  chats?: Maybe<Array<ChatGroup>>;
  createdAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  digests?: Maybe<Array<Digest>>;
  emailMaintainer?: Maybe<Scalars['String']>;
  expirePassword?: Maybe<Scalars['Int']>;
  expireToken?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isNewRoute?: Maybe<Scalars['Boolean']>;
  language?: Maybe<LanguageEnum>;
  name?: Maybe<Scalars['String']>;
  oldRole?: Maybe<UserRole>;
  oldRouteUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  permissionGroups?: Maybe<Array<PermissionGroup>>;
  permissions?: Maybe<Array<Permission>>;
  ports?: Maybe<Array<Port>>;
  stocks?: Maybe<Array<Stock>>;
  updatedAt?: Maybe<Scalars['Int']>;
  userType?: Maybe<UserType>;
  vessels?: Maybe<Array<Vessel>>;
};

export type CarrierInfo = {
  __typename?: 'CarrierInfo';
  /** @deprecated Refactored */
  carrier?: Maybe<Scalars['Int']>;
  carrierId?: Maybe<Scalars['Int']>;
  carrierName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TypeOfFreight = {
  __typename?: 'TypeOfFreight';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AddresseesUsersToChatsPaginatedList = {
  __typename?: 'AddresseesUsersToChatsPaginatedList';
  nodes: Array<User>;
  total: Scalars['Int'];
};

export type PackagesFilter = {
  package?: Maybe<PackageFilter>;
};

export type PackageFilter = {
  partVin?: Maybe<Scalars['String']>;
  /** Готовые к выдачи */
  showIssued?: Maybe<Scalars['Boolean']>;
  tripId?: Maybe<Scalars['Int']>;
};

export type PackagesPaginatedList = {
  __typename?: 'PackagesPaginatedList';
  nodes: Array<ObjectPackage>;
  total: Scalars['Int'];
};

export type AmbassadorsPaginatedList = {
  __typename?: 'AmbassadorsPaginatedList';
  nodes: Array<Ambassador>;
  total: Scalars['Int'];
};

export type AttachmentsTemplate = {
  __typename?: 'AttachmentsTemplate';
  engName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type AuctionFeedbacksPaginatedList = {
  __typename?: 'AuctionFeedbacksPaginatedList';
  nodes: Array<CarFeedback>;
  total: Scalars['Int'];
};

export type BrokerExcelTemplate = {
  __typename?: 'BrokerExcelTemplate';
  broker?: Maybe<Broker>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isLegalEntity?: Maybe<Scalars['Boolean']>;
  mask?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  type?: Maybe<BrokerExcelTemplateEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum BrokerExcelTemplateEnum {
  Address = 'address',
  Applicant = 'applicant',
  Color = 'color',
  Consignment = 'consignment',
  DateOfManufacture = 'date_of_manufacture',
  DryWeight = 'dry_weight',
  EngineModel = 'engine_model',
  EngineNumber = 'engine_number',
  EnginePower = 'engine_power',
  EngineType = 'engine_type',
  EngineVolume = 'engine_volume',
  GlonassModel = 'glonass_model',
  GlonassNumber = 'glonass_number',
  IndividualApplicant = 'individual_applicant',
  LegalApplicant = 'legal_applicant',
  Maker = 'maker',
  Mark = 'mark',
  MaxWeight = 'max_weight',
  Model = 'model',
  Ogrn = 'ogrn',
  Seats = 'seats',
  Vin = 'vin'
}

export type BrokersPaginatedList = {
  __typename?: 'BrokersPaginatedList';
  nodes: Array<Broker>;
  total: Scalars['Int'];
};

export type ChatMessagessFilter = {
  onlyTasks?: Maybe<OnlyTasksFilter>;
};

export type OnlyTasksFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type ChatMessagessPaginatedList = {
  __typename?: 'ChatMessagessPaginatedList';
  nodes: Array<ChatMessage>;
  total: Scalars['Int'];
};

export type ChatsForOtherssFilter = {
  asObserver?: Maybe<AsObserverFilter>;
  chatAll?: Maybe<ChatAllFilter>;
  chatObjectId?: Maybe<ChatObjectIdFilter>;
  chatSearch?: Maybe<ChatSearchFilter>;
  chatTagId?: Maybe<ChatTagIdFilter>;
  showArchive: ShowArchiveFilter;
  unread?: Maybe<UnreadFilter>;
  unResolvedTask?: Maybe<UnResolvedTaskFilter>;
  withUserMessages?: Maybe<WithUserMessagesFilter>;
};

export type AsObserverFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type ChatAllFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type ChatObjectIdFilter = {
  eq?: Maybe<Scalars['Int']>;
};

export type ChatSearchFilter = {
  eq?: Maybe<Scalars['String']>;
};

export type ChatTagIdFilter = {
  eq?: Maybe<Scalars['Int']>;
};

export type ShowArchiveFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type UnreadFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type UnResolvedTaskFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type WithUserMessagesFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type ChatsForOtherssPaginatedList = {
  __typename?: 'ChatsForOtherssPaginatedList';
  nodes: Array<ChatForOthers>;
  total: Scalars['Int'];
};

export type ClientContact = {
  __typename?: 'ClientContact';
  hasTelegram?: Maybe<Scalars['Boolean']>;
  hasWhatsapp?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
};

export type ClientObjectAttachmentsFilter = {
  clientObjectAttachment?: Maybe<ClientObjectAttachmentFilter>;
};

export type ClientObjectAttachmentFilter = {
  partVin?: Maybe<Scalars['String']>;
  /** Готовые к выдачи */
  showIssued?: Maybe<Scalars['Boolean']>;
  tripId?: Maybe<Scalars['Int']>;
};

export type ClientObjectAttachmentsPaginatedList = {
  __typename?: 'ClientObjectAttachmentsPaginatedList';
  nodes: Array<ObjectAttachment>;
  total: Scalars['Int'];
};

export type ClientObjectDashboardFilter = {
  /** Заблокированные */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** Готовые к выдаче */
  isHasIssuedStatus?: Maybe<Scalars['Boolean']>;
  /** С долгами */
  withDebts?: Maybe<Scalars['Boolean']>;
};

export type ClientObjectsTrip = {
  __typename?: 'ClientObjectsTrip';
  /** Кол-во готовых к выдаче */
  countHasIssuedStatus?: Maybe<Scalars['Int']>;
  /** Кол-во без грузополучателя */
  countWithoutConsignee?: Maybe<Scalars['Int']>;
  debts?: Maybe<Array<DebtPrice>>;
  id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  trip?: Maybe<Trip>;
};

export type DebtPrice = {
  __typename?: 'DebtPrice';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Float']>;
};

export type ClientObjectLogsFilter = {
  clientObjectLog?: Maybe<ClientObjectLogFilter>;
};

export type ClientObjectLogFilter = {
  byGuid?: Maybe<Scalars['Boolean']>;
  byVin?: Maybe<Scalars['Boolean']>;
  isFull?: Maybe<Scalars['Boolean']>;
};

export type ClientObjectLogsPaginatedList = {
  __typename?: 'ClientObjectLogsPaginatedList';
  nodes: Array<ClientObjectLog>;
  total: Scalars['Int'];
};

export type ClientObjectLog = {
  __typename?: 'ClientObjectLog';
  guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  msg?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ClientObjectQueuesPaginatedList = {
  __typename?: 'ClientObjectQueuesPaginatedList';
  nodes: Array<ClientObjectQueue>;
  total: Scalars['Int'];
};

export type ClientObjectQueue = {
  __typename?: 'ClientObjectQueue';
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  messageId?: Maybe<Scalars['String']>;
  routeKey?: Maybe<Scalars['String']>;
};

export type ClientObjectsFilter = {
  objectAuctionListing?: Maybe<ObjectAuctionListingFilter>;
  objectGreenCorner?: Maybe<ObjectGreenCornerFilter>;
  objectOfService?: Maybe<ObjectOfServiceFilter>;
  objectPlan?: Maybe<ObjectPlanFilter>;
  objectWorldWide?: Maybe<ObjectWorldWideFilter>;
  scrap?: Maybe<ScrapFilter>;
};

/** Фильтр для выставленных на аукцион */
export type ObjectAuctionListingFilter = {
  /** Поиск по номеру лота */
  aucNumber?: Maybe<Scalars['String']>;
  /** Авто выставленные на выбранные аукцион */
  auction?: Maybe<Scalars['Int']>;
  /** Выставлено от */
  createdFrom?: Maybe<Scalars['DateTime']>;
  /** Выставлено до */
  createdTo?: Maybe<Scalars['DateTime']>;
  /** Дата торгов от */
  dateFrom?: Maybe<Scalars['DateTime']>;
  /** Дата торгов до */
  dateTo?: Maybe<Scalars['DateTime']>;
  /** Объекты которые можно выставить */
  forAuctionListing?: Maybe<Scalars['Boolean']>;
  /** Все выставленные */
  isListenedAuction?: Maybe<Scalars['Boolean']>;
  /** Фильтр по статусу */
  status?: Maybe<ObjectAuctionListingStatusEnum>;
};

export enum ObjectAuctionListingStatusEnum {
  /** Добавлен к выставлению */
  Listed = 'LISTED',
  /** Не продан */
  NotSold = 'NOT_SOLD',
  /** Подготовлен */
  Prepared = 'PREPARED',
  /** Отправлен */
  Send = 'SEND',
  /** Продан */
  Sold = 'SOLD'
}

export type ObjectGreenCornerFilter = {
  isGreenCorner?: Maybe<Scalars['Boolean']>;
  noPrice?: Maybe<Scalars['Boolean']>;
};

export type ObjectOfServiceFilter = {
  aggregateTypes?: Maybe<Array<Scalars['Int']>>;
  /** Дата покупки от */
  auctionDate?: Maybe<Scalars['String']>;
  /** Дата покупки до (Если null берется конец дня auctionDate) */
  auctionDateTo?: Maybe<Scalars['String']>;
  auctions?: Maybe<Array<Scalars['Int']>>;
  /** По названию филиала офиса */
  branchOffice?: Maybe<Scalars['String']>;
  brokers?: Maybe<Array<Scalars['Int']>>;
  buyByMonth?: Maybe<Scalars['String']>;
  byDateIssueElpts?: Maybe<Scalars['String']>;
  byDelayRequestDay?: Maybe<Scalars['String']>;
  byInvoicePaymentType?: Maybe<Scalars['Boolean']>;
  carTypes?: Maybe<Array<Scalars['Int']>>;
  city?: Maybe<CityEnum>;
  containerNumber?: Maybe<Scalars['String']>;
  customTypes?: Maybe<Array<Scalars['Int']>>;
  dashboardTrip?: Maybe<Scalars['Int']>;
  expiredDelayRequestDay?: Maybe<Scalars['Boolean']>;
  formalOwners?: Maybe<Array<Scalars['Int']>>;
  forTrip?: Maybe<Scalars['Boolean']>;
  /** С долгами по клиенту */
  hasClientDebts?: Maybe<Scalars['Boolean']>;
  hasTicket?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  independentlyPaymentType?: Maybe<Scalars['Boolean']>;
  inJapan?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAkebono?: Maybe<Scalars['Boolean']>;
  /** Заблокированные */
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBoundary?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDelayDelivery?: Maybe<Scalars['Boolean']>;
  isDisassembly?: Maybe<Scalars['Boolean']>;
  isHasCustomInvoice?: Maybe<Scalars['Boolean']>;
  isHasCustomPayment?: Maybe<Scalars['Boolean']>;
  isHasElpts?: Maybe<Scalars['Boolean']>;
  /** Готовые к выдаче */
  isHasIssuedStatus?: Maybe<Scalars['Boolean']>;
  isHasPenalty?: Maybe<Scalars['Boolean']>;
  isHasSbkts?: Maybe<Scalars['Boolean']>;
  isHold?: Maybe<Scalars['Boolean']>;
  isKaitai?: Maybe<Scalars['Boolean']>;
  isPart?: Maybe<Scalars['Boolean']>;
  isPassedTestDate?: Maybe<Scalars['String']>;
  isRecycleCube?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  /** Является донором */
  isScrap?: Maybe<Scalars['Boolean']>;
  /** Является запчастью */
  isScrapObjects?: Maybe<Scalars['Boolean']>;
  isSoldRecycleCube?: Maybe<Scalars['Boolean']>;
  isYahoo?: Maybe<Scalars['Boolean']>;
  owners?: Maybe<Array<Scalars['Int']>>;
  ownStocks?: Maybe<Scalars['Boolean']>;
  partVin?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['Int']>;
  sawTypes?: Maybe<Array<Scalars['Int']>>;
  sectorId?: Maybe<Scalars['Int']>;
  senders?: Maybe<Array<Scalars['Int']>>;
  stockId?: Maybe<Scalars['Int']>;
  stocks?: Maybe<Array<Scalars['Int']>>;
  trip?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  /** С долгами по типу пользователя */
  withDebts?: Maybe<Scalars['Boolean']>;
  withoutIssued?: Maybe<Scalars['Boolean']>;
  withStorageData?: Maybe<Scalars['Boolean']>;
};

export enum CityEnum {
  All = 'all',
  Otaru = 'otaru',
  Tomakomai = 'tomakomai',
  Toyama = 'toyama'
}

export type ObjectPlanFilter = {
  contract?: Maybe<Scalars['Int']>;
  plan?: Maybe<Scalars['Int']>;
  trip?: Maybe<Scalars['Int']>;
};

export type ObjectWorldWideFilter = {
  container?: Maybe<ContainerFilterEnum>;
  countryId?: Maybe<Scalars['Int']>;
  exportCertificate?: Maybe<Scalars['String']>;
  isBookingRequested?: Maybe<Scalars['Boolean']>;
  isWorldWide?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['Int']>;
  trip?: Maybe<TripFilterEnum>;
  vesselTypeId?: Maybe<Scalars['Int']>;
};

export enum ContainerFilterEnum {
  All = 'all',
  WithContainer = 'with_container',
  WithoutContainer = 'without_container'
}

export enum TripFilterEnum {
  All = 'all',
  WithTrip = 'with_trip',
  WithoutTrip = 'without_trip'
}

export type ScrapFilter = {
  akbVin?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  dateEnd?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
  /** Находится в статусе Ожидает выставления, Выставлен, Не продан, Продан, Отправлен в Яху, Ожидает отправки */
  inStock?: Maybe<Scalars['Boolean']>;
  isKaitai?: Maybe<Scalars['Boolean']>;
  /** Является запчастью */
  isScrap?: Maybe<Scalars['Boolean']>;
  maker?: Maybe<Scalars['Int']>;
  mark?: Maybe<Scalars['Int']>;
  notSold?: Maybe<Scalars['Boolean']>;
  scrapTypeId?: Maybe<Scalars['Int']>;
  sold?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  waitDelivery?: Maybe<Scalars['Boolean']>;
  waitSendToYahoo?: Maybe<Scalars['Boolean']>;
  waitToYahoo?: Maybe<Scalars['Boolean']>;
  waitUtilization?: Maybe<Scalars['Boolean']>;
  waitYahooApprove?: Maybe<Scalars['Boolean']>;
  withoutPlace?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['Int']>;
};

export type ClientObjectsSort = {
  objectOfService?: Maybe<ObjectOfServiceSort>;
};

export type ObjectOfServiceSort = {
  holdAt?: Maybe<SortOrderEnum>;
  id?: Maybe<SortOrderEnum>;
  listingCreatedAt?: Maybe<SortOrderEnum>;
  locationId?: Maybe<SortOrderEnum>;
  statusAt?: Maybe<SortOrderEnum>;
};

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type ClientObjectsPaginatedList = {
  __typename?: 'ClientObjectsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type ClientObjectsReportPaymentAkebonoAllowsPaginatedList = {
  __typename?: 'ClientObjectsReportPaymentAkebonoAllowsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type ClientObjectsYear = {
  __typename?: 'ClientObjectsYear';
  months?: Maybe<Array<ClientObjectsMonth>>;
  year?: Maybe<Scalars['String']>;
};

export type ClientObjectsMonth = {
  __typename?: 'ClientObjectsMonth';
  countWithoutConsignee?: Maybe<Scalars['Int']>;
  debts?: Maybe<Array<Price>>;
  hasPenaltyTax?: Maybe<Scalars['Boolean']>;
  hasProposedPenalty?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type ClientRecipientsPaginatedList = {
  __typename?: 'ClientRecipientsPaginatedList';
  nodes: Array<ClientRecipients>;
  total: Scalars['Int'];
};

export type ClientRecipients = {
  __typename?: 'ClientRecipients';
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  objectId?: Maybe<Scalars['Int']>;
  objectName?: Maybe<Scalars['String']>;
  recipientObject?: Maybe<Scalars['String']>;
  recipientRequest?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type ContainersFilter = {
  container?: Maybe<ContainerFilter>;
};

export type ContainerFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  notSended?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ContainersPaginatedList = {
  __typename?: 'ContainersPaginatedList';
  nodes: Array<Container>;
  total: Scalars['Int'];
};

export type CountrysFilter = {
  isPaymentCountry?: Maybe<IsPaymentCountryFilter>;
};

export type IsPaymentCountryFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type CountrysPaginatedList = {
  __typename?: 'CountrysPaginatedList';
  nodes: Array<Country>;
  total: Scalars['Int'];
};

export type CurrencyFilter = {
  isPayment?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
};

export type SbktsPlan = {
  __typename?: 'SbktsPlan';
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  limitObjects?: Maybe<Scalars['Int']>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
  planDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SbktsObjectToPlan = {
  __typename?: 'SbktsObjectToPlan';
  approvedAt?: Maybe<Scalars['DateTime']>;
  approver?: Maybe<User>;
  broker?: Maybe<Broker>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completeUser?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  driverToAi?: Maybe<Driver>;
  driverToTroyan?: Maybe<Driver>;
  elptsAt?: Maybe<Scalars['DateTime']>;
  expert?: Maybe<User>;
  expertAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isExtra?: Maybe<Scalars['Boolean']>;
  movedToAiAt?: Maybe<Scalars['DateTime']>;
  movedToTroyanAt?: Maybe<Scalars['DateTime']>;
  object?: Maybe<ObjectOfServices>;
  plan?: Maybe<SbktsPlan>;
  request?: Maybe<SbktsRequest>;
  sbktsAt?: Maybe<Scalars['DateTime']>;
  unloadAt?: Maybe<Scalars['DateTime']>;
};

export type CurrentUserNotifyMessage = {
  __typename?: 'CurrentUserNotifyMessage';
  bot?: Maybe<NotifyBotInfo>;
  channels?: Maybe<Array<UserChannel>>;
  messageTypes?: Maybe<Array<NotifyMessage>>;
};

export type NotifyBotInfo = {
  __typename?: 'NotifyBotInfo';
  clientName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserChannel = {
  __typename?: 'UserChannel';
  enabled?: Maybe<Scalars['Boolean']>;
  enabledMessage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type NotifyMessage = {
  __typename?: 'NotifyMessage';
  channels?: Maybe<Array<UserChannel>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export type DiffEntityFilter = {
  entityId?: Maybe<Scalars['Int']>;
  entityType?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
};

export type LogEntity = {
  __typename?: 'LogEntity';
  createdTime?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['Int']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  newData?: Maybe<Scalars['String']>;
  oldData?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ExchangeJobsFilter = {
  exchangeJob?: Maybe<ExchangeJobFilter>;
};

export type ExchangeJobFilter = {
  action?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  isHasFail?: Maybe<Scalars['Boolean']>;
  isNotResolve?: Maybe<Scalars['Boolean']>;
  isTroyan?: Maybe<Scalars['Boolean']>;
  objectId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  transport?: Maybe<Scalars['String']>;
};

export type ExchangeJobsPaginatedList = {
  __typename?: 'ExchangeJobsPaginatedList';
  nodes: Array<ExchangeJob>;
  total: Scalars['Int'];
};

export type ExchangeJob = {
  __typename?: 'ExchangeJob';
  action?: Maybe<Scalars['String']>;
  connection?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  jobFail?: Maybe<ExchangeJobFail>;
  parameters?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  resolvedMessage?: Maybe<Scalars['String']>;
  transport?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
};

export type ExchangeJobFail = {
  __typename?: 'ExchangeJobFail';
  attempts?: Maybe<Scalars['Int']>;
  exception?: Maybe<Scalars['String']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ExportTripsFilter = {
  trip?: Maybe<TripFilter>;
};

export type TripFilter = {
  /** По названию филиала офиса */
  branchOffice?: Maybe<Scalars['String']>;
  /** Ближайшие */
  comingSoon?: Maybe<Scalars['Boolean']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['Int']>;
  forBoxes?: Maybe<Scalars['Boolean']>;
  forParking?: Maybe<Scalars['Boolean']>;
  forTickets?: Maybe<Scalars['Boolean']>;
  fromCountry?: Maybe<Array<Scalars['Int']>>;
  fromJapan?: Maybe<Scalars['Boolean']>;
  /** С долгами */
  hasDebts?: Maybe<Scalars['Boolean']>;
  /** По состоянию */
  isActive?: Maybe<Scalars['Boolean']>;
  isRoro?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  tripsIds?: Maybe<Array<Scalars['Int']>>;
  vesselId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ExportTripsSort = {
  trip?: Maybe<TripSort>;
};

export type TripSort = {
  createdAt?: Maybe<SortOrderEnum>;
  dateFrom?: Maybe<SortOrderEnum>;
  dateTo?: Maybe<SortOrderEnum>;
};

export type ExportTripsPaginatedList = {
  __typename?: 'ExportTripsPaginatedList';
  nodes: Array<Trip>;
  total: Scalars['Int'];
};

export type FastObjectsByTrip = {
  __typename?: 'FastObjectsByTrip';
  byTypes?: Maybe<Array<FastObjectsByTypeOf>>;
  plan?: Maybe<Plan>;
};

export type FastObjectsByTypeOf = {
  __typename?: 'FastObjectsByTypeOf';
  objects?: Maybe<Array<FastObjects>>;
  type?: Maybe<TypeOfExport>;
};

export type FastObjects = {
  __typename?: 'FastObjects';
  all?: Maybe<Scalars['Int']>;
  object?: Maybe<ObjectOfServices>;
  stockToPort?: Maybe<Scalars['Int']>;
  toStock?: Maybe<Scalars['Int']>;
};

export type FeedbackssFilter = {
  feedback?: Maybe<FeedbackFilter>;
};

export type FeedbackFilter = {
  /** Период начало */
  dateFrom?: Maybe<Scalars['String']>;
  /** Период конец */
  dateTo?: Maybe<Scalars['String']>;
  /** По отценке */
  evaluation?: Maybe<Scalars['Int']>;
  /** По содержимому отзыва */
  feedback?: Maybe<Scalars['String']>;
  /** По пользователю */
  userId?: Maybe<Scalars['Int']>;
};

export type FeedbackssPaginatedList = {
  __typename?: 'FeedbackssPaginatedList';
  nodes: Array<CarFeedback>;
  total: Scalars['Int'];
};

export type FinanceReport = {
  __typename?: 'FinanceReport';
  balanceIn?: Maybe<Scalars['Float']>;
  balanceOut?: Maybe<Scalars['Float']>;
  contractName?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  documents?: Maybe<Array<FinanceReportDocument>>;
};

export type FinanceReportDocument = {
  __typename?: 'FinanceReportDocument';
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  objects?: Maybe<Array<FinanceReportObject>>;
  type?: Maybe<Scalars['String']>;
};

export type FinanceReportObject = {
  __typename?: 'FinanceReportObject';
  amountIn?: Maybe<Scalars['Float']>;
  amountOut?: Maybe<Scalars['Float']>;
  balanceIn?: Maybe<Scalars['Float']>;
  object?: Maybe<ObjectOfServices>;
  type?: Maybe<Scalars['Int']>;
};

export type FormalOwnersPaginatedList = {
  __typename?: 'FormalOwnersPaginatedList';
  nodes: Array<FormalOwner>;
  total: Scalars['Int'];
};

export type GreenCornerObjects = {
  __typename?: 'GreenCornerObjects';
  /** Всего ЗУ с грузополучателем */
  all?: Maybe<Scalars['Int']>;
  /** кол-во объектов не добавленных в список ожидания */
  notInAwaitingList?: Maybe<Scalars['Int']>;
  /** Объекты не добавленные в список ожидания */
  objects?: Maybe<Array<Maybe<ObjectOfServices>>>;
};

export type InvoiceCustomerInfosPaginatedList = {
  __typename?: 'InvoiceCustomerInfosPaginatedList';
  nodes: Array<InvoiceCustomerInfo>;
  total: Scalars['Int'];
};

export type InvoiceCustomerInfo = {
  __typename?: 'InvoiceCustomerInfo';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameRu?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
};

export type InvoiceLogsPaginatedList = {
  __typename?: 'InvoiceLogsPaginatedList';
  nodes: Array<InvoiceLog>;
  total: Scalars['Int'];
};

export type InvoiceLog = {
  __typename?: 'InvoiceLog';
  createdAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  newAmount?: Maybe<Scalars['Int']>;
  nomenclature?: Maybe<Nomenclature>;
  object?: Maybe<ObjectOfServices>;
  oldAmount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type InvoicesSearchsFilter = {
  invoice: InvoiceFilter;
};

export type InvoiceFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type InvoicesSearchsPaginatedList = {
  __typename?: 'InvoicesSearchsPaginatedList';
  nodes: Array<InvoiceSearch>;
  total: Scalars['Int'];
};

export type InvoiceSearch = {
  __typename?: 'InvoiceSearch';
  amount?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyIso?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
  ownerName?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export enum KaitaiAutoResubmitStateEnum {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type QueueStatus = {
  __typename?: 'QueueStatus';
  currentMessageCount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type LimitsPlan = {
  __typename?: 'LimitsPlan';
  addExtraDays?: Maybe<Array<Scalars['String']>>;
  generatePlanDays?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
};

export type TypeOfLocality = {
  __typename?: 'TypeOfLocality';
  fullName?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type LogAgentEventsFilter = {
  logAgentEvent?: Maybe<LogAgentEventFilter>;
};

export type LogAgentEventFilter = {
  id?: Maybe<Scalars['Int']>;
};

export type LogAgentEventsPaginatedList = {
  __typename?: 'LogAgentEventsPaginatedList';
  nodes: Array<LogAgentEvent>;
  total: Scalars['Int'];
};

export type LogAgentEvent = {
  __typename?: 'LogAgentEvent';
  agent?: Maybe<Owner>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  serviceCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type LogsFilter = {
  log?: Maybe<LogFilter>;
};

export type LogFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['Int']>;
};

export type LogsPaginatedList = {
  __typename?: 'LogsPaginatedList';
  nodes: Array<Log>;
  total: Scalars['Int'];
};

export type Log = {
  __typename?: 'Log';
  id?: Maybe<Scalars['Int']>;
  isJson?: Maybe<Scalars['Boolean']>;
  msg?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type LogsUserRequestsFilter = {
  logsUserRequest?: Maybe<LogsUserRequestFilter>;
};

export type LogsUserRequestFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['Int']>;
};

export type LogsUserRequestsPaginatedList = {
  __typename?: 'LogsUserRequestsPaginatedList';
  nodes: Array<LogsUserRequest>;
  total: Scalars['Int'];
};

export type LogsUserRequest = {
  __typename?: 'LogsUserRequest';
  bodyParams?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpRequestMethod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  remoteIp?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userAgent?: Maybe<Scalars['String']>;
  userIp?: Maybe<Scalars['String']>;
};

export type LotteryTicket = {
  __typename?: 'LotteryTicket';
  isVacant?: Maybe<Scalars['Boolean']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type MasterAccountsClientsPaginatedList = {
  __typename?: 'MasterAccountsClientsPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int'];
};

export type MediaStatistics = {
  __typename?: 'MediaStatistics';
  countObjectsWithMedia?: Maybe<Scalars['Int']>;
  countObjectsWoMedia?: Maybe<Scalars['Int']>;
};

export type TripSchedule = {
  __typename?: 'TripSchedule';
  trips?: Maybe<Array<TripScheduleInfo>>;
  tripsUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type TripScheduleInfo = {
  __typename?: 'TripScheduleInfo';
  id?: Maybe<Scalars['Int']>;
  serialNum?: Maybe<Scalars['Int']>;
  ship?: Maybe<VesselInfo>;
  wayPoints?: Maybe<Array<WayPoint>>;
};

export type VesselInfo = {
  __typename?: 'VesselInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type WayPoint = {
  __typename?: 'WayPoint';
  city?: Maybe<CityInfo>;
  date?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
};

export type CityInfo = {
  __typename?: 'CityInfo';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type NewsPaginatedList = {
  __typename?: 'NewsPaginatedList';
  nodes: Array<News>;
  total: Scalars['Int'];
};

export type News = {
  __typename?: 'News';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isAttention?: Maybe<Scalars['Boolean']>;
  shortContent?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type NotificationLogsFilter = {
  notificationLog?: Maybe<NotificationLogFilter>;
};

export type NotificationLogFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  entity?: Maybe<NotificationLogEntityEnum>;
  entityId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sendTo?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export enum NotificationLogEntityEnum {
  Agents = 'AGENTS',
  Objects = 'OBJECTS',
  Tickets = 'TICKETS'
}

export type NotificationLogsPaginatedList = {
  __typename?: 'NotificationLogsPaginatedList';
  nodes: Array<NotificationLog>;
  total: Scalars['Int'];
};

export type NotificationLog = {
  __typename?: 'NotificationLog';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entity?: Maybe<NotificationLogEntityEnum>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isSuccess?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['String']>;
  sendFrom?: Maybe<Scalars['String']>;
  sendTo?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OperationalObjectsFilter = {
  operationalObject?: Maybe<OperationalObjectFilter>;
};

export type OperationalObjectFilter = {
  broker?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isExtra?: Maybe<Scalars['Boolean']>;
  planDate?: Maybe<Scalars['String']>;
};

export type OperationalObjectsPaginatedList = {
  __typename?: 'OperationalObjectsPaginatedList';
  nodes: Array<SbktsObjectToPlan>;
  total: Scalars['Int'];
};

export type OwnersPaginatedList = {
  __typename?: 'OwnersPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int'];
};

export type PalletsFilter = {
  pallet?: Maybe<PalletFilter>;
};

export type PalletFilter = {
  /** Пустой */
  empty?: Maybe<Scalars['Boolean']>;
  /** Готов к отгрузке */
  readyToTrip?: Maybe<Scalars['Boolean']>;
  /** Поставлен на рейс */
  toTrip?: Maybe<Scalars['Boolean']>;
};

export type PalletsPaginatedList = {
  __typename?: 'PalletsPaginatedList';
  nodes: Array<Pallet>;
  total: Scalars['Int'];
};

export type PartTemplate = {
  __typename?: 'PartTemplate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<PaymentHistoryItem>>;
  sum?: Maybe<Scalars['String']>;
};

export type PaymentHistoryItem = {
  __typename?: 'PaymentHistoryItem';
  contractName?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  name?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  sum?: Maybe<Scalars['String']>;
};

export type SbktsPlansPaginatedList = {
  __typename?: 'SbktsPlansPaginatedList';
  nodes: Array<SbktsPlan>;
  total: Scalars['Int'];
};

export type PortInfo = {
  __typename?: 'PortInfo';
  country?: Maybe<CountryInfo>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryInfo = {
  __typename?: 'CountryInfo';
  engName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Reason = {
  __typename?: 'Reason';
  id?: Maybe<Scalars['Int']>;
  nameEn?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
};

export type SbktsStatistic = {
  __typename?: 'SbktsStatistic';
  brokers?: Maybe<Array<SbktsBrokerStatistic>>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
};

export type SbktsBrokerStatistic = {
  __typename?: 'SbktsBrokerStatistic';
  approved?: Maybe<Scalars['Int']>;
  broker?: Maybe<Broker>;
  created?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Int']>;
  elpts?: Maybe<Scalars['Int']>;
  expertise?: Maybe<Scalars['Int']>;
  hold?: Maybe<Scalars['Int']>;
  movedToAi?: Maybe<Scalars['Int']>;
  movedToTroyan?: Maybe<Scalars['Int']>;
  sbkts?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  unHold?: Maybe<Scalars['Int']>;
};

export type SbktsPlanStatistic = {
  __typename?: 'SbktsPlanStatistic';
  data?: Maybe<Array<SbktsPlanStatisticByDate>>;
  totalByBrokers?: Maybe<Array<SbktsBrokerStatistic>>;
};

export type SbktsPlanStatisticByDate = {
  __typename?: 'SbktsPlanStatisticByDate';
  brokers?: Maybe<Array<SbktsBrokerStatistic>>;
  date?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<SbktsObjectToPlan>>;
  totalByOneDateWork?: Maybe<Scalars['Int']>;
};

export type SbktsRequestsFilter = {
  sbktsRequest?: Maybe<SbktsRequestFilter>;
};

export type SbktsRequestFilter = {
  assignedUsers?: Maybe<Array<Scalars['Int']>>;
  blankNumber?: Maybe<Scalars['String']>;
  broken?: Maybe<Scalars['Boolean']>;
  broker?: Maybe<Scalars['Int']>;
  canceled?: Maybe<Scalars['Boolean']>;
  consignment?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  hasHolds?: Maybe<Scalars['Boolean']>;
  isBeginWork?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isCompleteReleased?: Maybe<Scalars['Boolean']>;
  isFailExchange?: Maybe<Scalars['Boolean']>;
  isHasData?: Maybe<Scalars['Boolean']>;
  isHasElpts?: Maybe<Scalars['Boolean']>;
  isHold?: Maybe<Scalars['Boolean']>;
  isInBox?: Maybe<Scalars['Boolean']>;
  isMoto?: Maybe<Scalars['Boolean']>;
  isNotApproved?: Maybe<Scalars['Boolean']>;
  isNotApproveOver3Day?: Maybe<Scalars['Boolean']>;
  isNotApproveOverWeek?: Maybe<Scalars['Boolean']>;
  isNotHold?: Maybe<Scalars['Boolean']>;
  isOnWork?: Maybe<Scalars['Boolean']>;
  isPassedTests?: Maybe<Scalars['Boolean']>;
  isPriority?: Maybe<Scalars['Boolean']>;
  isThirdPartyObject?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<Scalars['Int']>;
  maker?: Maybe<Scalars['Int']>;
  mark?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['Int']>;
  moreThirdDays?: Maybe<Scalars['Boolean']>;
  onTroyan?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<Scalars['Int']>;
  requestNumber?: Maybe<Scalars['String']>;
  unfinished?: Maybe<Scalars['Boolean']>;
  unHold?: Maybe<Scalars['Boolean']>;
  vin?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type SbktsRequestsSort = {
  sbktsRequest?: Maybe<SbktsRequestSort>;
};

export type SbktsRequestSort = {
  active?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<SortOrderEnum>;
  completedAt?: Maybe<SortOrderEnum>;
  createdAt?: Maybe<SortOrderEnum>;
  passedTestsAt?: Maybe<SortOrderEnum>;
  requestNumber?: Maybe<SortOrderEnum>;
};

export type SbktsRequestsPaginatedList = {
  __typename?: 'SbktsRequestsPaginatedList';
  nodes: Array<SbktsRequest>;
  total: Scalars['Int'];
};

export type DisposalsPaginatedList = {
  __typename?: 'DisposalsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type ScrapTypeDescriptionsPaginatedList = {
  __typename?: 'ScrapTypeDescriptionsPaginatedList';
  nodes: Array<ScrapTypeDescription>;
  total: Scalars['Int'];
};

export type SeaCarriersPaginatedList = {
  __typename?: 'SeaCarriersPaginatedList';
  nodes: Array<SeaCarrier>;
  total: Scalars['Int'];
};

export type SearchObjectsPaginatedList = {
  __typename?: 'SearchObjectsPaginatedList';
  nodes: Array<SearchResult>;
  total: Scalars['Int'];
};

export type SearchResult = Invoice | InvoiceCustom | ObjectOfServices;

export type OwnerAgentsFilter = {
  senderAgentsSearch?: Maybe<SenderAgentsSearchFilter>;
};

export type SenderAgentsSearchFilter = {
  partName?: Maybe<Scalars['String']>;
};

export type OwnerAgentsPaginatedList = {
  __typename?: 'OwnerAgentsPaginatedList';
  nodes: Array<Owner>;
  total: Scalars['Int'];
};

export type SendersPaginatedList = {
  __typename?: 'SendersPaginatedList';
  nodes: Array<Sender>;
  total: Scalars['Int'];
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  amount?: Maybe<Scalars['Int']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  canceledBy?: Maybe<User>;
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<Maybe<S3File>>>;
  id?: Maybe<Scalars['Int']>;
  lotLink?: Maybe<Scalars['String']>;
  object?: Maybe<ObjectOfServices>;
  owner?: Maybe<Owner>;
  requestType?: Maybe<TypeOfServiceRequest>;
  status?: Maybe<ServiceRequestStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TypeOfServiceRequest = {
  __typename?: 'TypeOfServiceRequest';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameEng?: Maybe<Scalars['String']>;
};

export enum ServiceRequestStatusEnum {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed'
}

/** Типы инспекции */
export enum VideoWatchTypeEnum {
  /** С аукциона USS TOKYO */
  Auction = 'AUCTION',
  /** С аукциона USS Yokohama */
  AuctionYokohama = 'AUCTION_YOKOHAMA',
  /** Базовый видео досмотр */
  Basic = 'BASIC',
  /** Детальный видео досмотр */
  Detailed = 'DETAILED'
}

export type ServiceRequestsFilter = {
  serviceRequest?: Maybe<ServiceRequestFilter>;
};

export type ServiceRequestFilter = {
  /** Поиск по кузову */
  dateFrom?: Maybe<Scalars['DateTime']>;
  /** Поиск по кузову */
  dateTo?: Maybe<Scalars['DateTime']>;
  /** Поиск по кузову */
  searchByVin?: Maybe<Scalars['String']>;
  /** По статусу заявки */
  status?: Maybe<ServiceRequestStatusEnum>;
  /** Список типов инспекций */
  types?: Maybe<Array<Maybe<VideoWatchTypeEnum>>>;
};

export type ServiceRequestsPaginatedList = {
  __typename?: 'ServiceRequestsPaginatedList';
  nodes: Array<ServiceRequest>;
  total: Scalars['Int'];
};

export enum StatisticTypeEnum {
  Auction = 'auction',
  Date = 'date',
  None = 'none'
}

export type CarInPlantStatistic = {
  __typename?: 'CarInPlantStatistic';
  /** Список аукционов за период */
  auctions?: Maybe<Array<Maybe<Auction>>>;
  /** Всего закупленно */
  buyCount: Scalars['Int'];
  /** Период начало */
  dateFrom: Scalars['String'];
  /** Период конец */
  dateTo: Scalars['String'];
  /** Список дней за период */
  purchaseDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Зарегистрированно на рейс */
  registeredTotal: Scalars['Int'];
  /** Зарегистрированно на рейс в % от buyCount */
  registeredTotalPercent: Scalars['Float'];
  /** Статистка за период */
  statistic?: Maybe<Array<Maybe<CarInPlantDayStatisticTotal>>>;
};

export type CarInPlantDayStatisticTotal = {
  __typename?: 'CarInPlantDayStatisticTotal';
  /** Всего зарегистрированно */
  count: Scalars['Int'];
  /** На какой день зарегистрированно в рейс. Значение null для не заригистрированно. */
  dayDiff?: Maybe<Scalars['Int']>;
  /** Всего зарегистрированно в % */
  percent: Scalars['Float'];
};

export enum TypeOfStatusEnum {
  Object = 'OBJECT',
  Troyan = 'TROYAN',
  YahooLot = 'YAHOO_LOT'
}

export type StockCountiesPaginatedList = {
  __typename?: 'StockCountiesPaginatedList';
  nodes: Array<Country>;
  total: Scalars['Int'];
};

export type StocksFilter = {
  countryStock?: Maybe<CountryStockFilter>;
};

export type CountryStockFilter = {
  active?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['ID']>;
  inJapan?: Maybe<Scalars['Boolean']>;
};

export type StocksPaginatedList = {
  __typename?: 'StocksPaginatedList';
  nodes: Array<Stock>;
  total: Scalars['Int'];
};

export type TicketDecisionsFilter = {
  ticketDecision?: Maybe<TicketDecisionFilter>;
};

export type TicketDecisionFilter = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  decisionStatuses?: Maybe<Array<Scalars['Int']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAppeal?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isPayDirector?: Maybe<Scalars['Boolean']>;
  isPayout?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['Int']>;
  owners?: Maybe<Array<Scalars['Int']>>;
  payoutDateFrom?: Maybe<Scalars['String']>;
  payoutDateTo?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['Int']>;
  ticketNumber?: Maybe<Scalars['String']>;
  ticketStatuses?: Maybe<Array<Scalars['Int']>>;
  trips?: Maybe<Array<Scalars['Int']>>;
  vessels?: Maybe<Array<Scalars['Int']>>;
  vin?: Maybe<Scalars['String']>;
};

export type TicketDecisionsPaginatedList = {
  __typename?: 'TicketDecisionsPaginatedList';
  nodes: Array<TicketDecision>;
  total: Scalars['Int'];
};

export type TicketsFilter = {
  ticket?: Maybe<TicketFilter>;
};

export type TicketFilter = {
  activeForReport?: Maybe<Scalars['Boolean']>;
  byStatus?: Maybe<TicketClientStatusEnum>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  decisionCreate?: Maybe<Scalars['String']>;
  forReport?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isPayDirector?: Maybe<Scalars['Boolean']>;
  isPayout?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['Int']>;
  owners?: Maybe<Array<Scalars['Int']>>;
  payoutDateFrom?: Maybe<Scalars['String']>;
  payoutDateTo?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Scalars['Int']>>;
  ticketNumber?: Maybe<Scalars['String']>;
  trips?: Maybe<Array<Scalars['Int']>>;
  types?: Maybe<Array<Scalars['Int']>>;
  vessels?: Maybe<Array<Scalars['Int']>>;
  vin?: Maybe<Scalars['String']>;
};

export enum TicketClientStatusEnum {
  Cancel = 'CANCEL',
  Done = 'DONE',
  New = 'NEW',
  Progress = 'PROGRESS',
  WaitPayout = 'WAIT_PAYOUT'
}

export type TicketsSort = {
  ticket?: Maybe<TicketSort>;
};

export type TicketSort = {
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<SortOrderEnum>;
  id?: Maybe<SortOrderEnum>;
};

export type TicketsPaginatedList = {
  __typename?: 'TicketsPaginatedList';
  nodes: Array<Ticket>;
  total: Scalars['Int'];
};

export type TicketsReasonsPaginatedList = {
  __typename?: 'TicketsReasonsPaginatedList';
  nodes: Array<TicketReason>;
  total: Scalars['Int'];
};

export type TicketStatistic = {
  __typename?: 'TicketStatistic';
  closedByCloseRange?: Maybe<Scalars['Float']>;
  closedByCreatedRange?: Maybe<Scalars['Float']>;
  closedTotal?: Maybe<Scalars['Float']>;
  createdByRange?: Maybe<Scalars['Float']>;
  createdTotal?: Maybe<Scalars['Float']>;
  declinedByCreatedRange?: Maybe<Scalars['Float']>;
  declinedByDeclineRange?: Maybe<Scalars['Float']>;
  declineTotal?: Maybe<Scalars['Float']>;
  inProgressCreateByRange?: Maybe<Scalars['Float']>;
  inProgressTotal?: Maybe<Scalars['Float']>;
  waitApproveByCreateRange?: Maybe<Scalars['Float']>;
  waitApproveByDecisionRange?: Maybe<Scalars['Float']>;
  waitApproveTotal?: Maybe<Scalars['Float']>;
  waitDecisionByCreateRange?: Maybe<Scalars['Float']>;
  waitDecisionTotal?: Maybe<Scalars['Float']>;
  waitOrganizationApproveByApproveRange?: Maybe<Scalars['Float']>;
  waitOrganizationApproveByCreatedRange?: Maybe<Scalars['Float']>;
  waitOrganizationApproveTotal?: Maybe<Scalars['Float']>;
  waitPayoutByApproveRange?: Maybe<Scalars['Float']>;
  waitPayoutByCreatedRange?: Maybe<Scalars['Float']>;
  waitPayoutTotal?: Maybe<Scalars['Float']>;
};

export type TotalCustomsClearanceTime = {
  __typename?: 'TotalCustomsClearanceTime';
  bySenders?: Maybe<Array<CustomsClearanceTimeBySender>>;
  byTypes?: Maybe<Array<CustomsClearanceTimeByTypes>>;
};

export type CustomsClearanceTimeBySender = {
  __typename?: 'CustomsClearanceTimeBySender';
  byTypes?: Maybe<Array<CustomsClearanceTimeByTypes>>;
  senderName?: Maybe<Scalars['String']>;
};

export type CustomsClearanceTimeByTypes = {
  __typename?: 'CustomsClearanceTimeByTypes';
  totalByInvoicePaymentCount?: Maybe<Scalars['Int']>;
  totalByInvoicePaymentDay?: Maybe<Scalars['Int']>;
  totalDays?: Maybe<Scalars['Int']>;
  totalIndependentlyPaymentCount?: Maybe<Scalars['Int']>;
  totalIndependentlyPaymentDay?: Maybe<Scalars['Int']>;
  totalObjectsCount?: Maybe<Scalars['Int']>;
  totalPrimbrokerPaymentCount?: Maybe<Scalars['Int']>;
  totalPrimbrokerPaymentDay?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type TripsFilter = {
  trip?: Maybe<TripFilter>;
};

export type TripsSort = {
  trip?: Maybe<TripSort>;
};

export type TripsPaginatedList = {
  __typename?: 'TripsPaginatedList';
  nodes: Array<Trip>;
  total: Scalars['Int'];
};

export type TripsCalendar = {
  __typename?: 'TripsCalendar';
  events?: Maybe<Array<TripCalendarEvent>>;
  ships?: Maybe<Array<VesselInfoCalendar>>;
};

export type TripCalendarEvent = {
  __typename?: 'TripCalendarEvent';
  allDay?: Maybe<Scalars['Boolean']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  classNames?: Maybe<Scalars['String']>;
  colorWarning?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconWarning?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleWarning?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type VesselInfoCalendar = {
  __typename?: 'VesselInfoCalendar';
  backgroundColorEvent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UndefinedPaymentsFilter = {
  undefinedPayment?: Maybe<UndefinedPaymentFilter>;
};

export type UndefinedPaymentFilter = {
  maxMays?: Maybe<Scalars['Int']>;
  minDays?: Maybe<Scalars['Int']>;
};

export type UndefinedPaymentsSort = {
  undefinedPayment?: Maybe<UndefinedPaymentSort>;
};

export type UndefinedPaymentSort = {
  amount?: Maybe<SortOrderEnum>;
  date?: Maybe<SortOrderEnum>;
  name?: Maybe<SortOrderEnum>;
};

export type UndefinedPaymentsPaginatedList = {
  __typename?: 'UndefinedPaymentsPaginatedList';
  nodes: Array<UndefinedPayment>;
  total: Scalars['Int'];
};

export type UndefinedPayment = {
  __typename?: 'UndefinedPayment';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['Int']>;
  payerName?: Maybe<Scalars['String']>;
  receiptDate?: Maybe<Scalars['DateTime']>;
};

export type ClientUnionLogsPaginatedList = {
  __typename?: 'ClientUnionLogsPaginatedList';
  nodes: Array<ClientUnionLog>;
  total: Scalars['Int'];
};

export type ClientUnionLog = {
  __typename?: 'ClientUnionLog';
  action?: Maybe<Scalars['String']>;
  connection?: Maybe<Scalars['String']>;
  /** Время создания события */
  createdAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
  /** Уникальный идентификатор события */
  guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  jobFail?: Maybe<ExchangeJobFail>;
  /** Сообщение */
  msg?: Maybe<Scalars['String']>;
  /** Параметры запроса */
  parameters?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  /** Время решения события */
  resolvedAt?: Maybe<Scalars['DateTime']>;
  resolvedMessage?: Maybe<Scalars['String']>;
  /** Сообщение */
  response?: Maybe<Scalars['String']>;
  /** Выборка из которой полученны данные */
  tableName?: Maybe<Scalars['String']>;
  transport?: Maybe<Scalars['String']>;
  /** Тип события */
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
  /** Идентификатор VIN обьекта */
  vin?: Maybe<Scalars['String']>;
};

export type PermissionMenuItem = {
  __typename?: 'PermissionMenuItem';
  permission?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type UsersFilter = {
  user?: Maybe<UserFilter>;
};

export type UserFilter = {
  eq?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  oldRoleId?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  policyId?: Maybe<Scalars['Int']>;
  userTypeId?: Maybe<Scalars['Int']>;
};

export type UsersPaginatedList = {
  __typename?: 'UsersPaginatedList';
  nodes: Array<User>;
  total: Scalars['Int'];
};

export type WatchCommentTemplate = {
  __typename?: 'WatchCommentTemplate';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type WheelsFilter = {
  wheel?: Maybe<WheelFilter>;
};

export type WheelFilter = {
  /** Купленны */
  asPurchased?: Maybe<Scalars['Boolean']>;
  /** Производитель диска */
  diskManufacturer?: Maybe<Scalars['String']>;
  /** Тип диска */
  diskType?: Maybe<Array<Maybe<DiskTypeEnum>>>;
  /** Производитель резины */
  tireManufacturer?: Maybe<Scalars['String']>;
  /** Высота резины максимум */
  tireProfileHeightMax?: Maybe<Scalars['Int']>;
  /** Высота резины минимум */
  tireProfileHeightMin?: Maybe<Scalars['Int']>;
  /** Ширина резины максимум */
  tireProfileWidthMax?: Maybe<Scalars['Int']>;
  /** Ширина резины минимум */
  tireProfileWidthMin?: Maybe<Scalars['Int']>;
  /** Радиус максимум */
  tireRadiusMax?: Maybe<Scalars['Int']>;
  /** Радиус минимум */
  tireRadiusMin?: Maybe<Scalars['Int']>;
  /** Тип резины */
  tireType?: Maybe<Array<Maybe<TireTypeEnum>>>;
  /** Процент износа резины максимум */
  tireWearMax?: Maybe<Scalars['Int']>;
  /** Процент износа резины минимум */
  tireWearMin?: Maybe<Scalars['Int']>;
  /** Без цены */
  withoutPrice?: Maybe<Scalars['Boolean']>;
};

export enum DiskTypeEnum {
  Aluminum = 'ALUMINUM',
  Iron = 'IRON'
}

export enum TireTypeEnum {
  Allseason = 'ALLSEASON',
  Dirt = 'DIRT',
  Spike = 'SPIKE',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export type WheelsPaginatedList = {
  __typename?: 'WheelsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type WheelAllsFilter = {
  wheel?: Maybe<WheelFilter>;
};

export type WheelAllsPaginatedList = {
  __typename?: 'WheelAllsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type WheelFilterValue = {
  __typename?: 'WheelFilterValue';
  /** Список производителей дисков */
  diskManufacturer?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Список типов дисков */
  diskType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Список производителей резины */
  tireManufacturer?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Список размеров по высоте резины */
  tireProfileHeight?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Список размеров по ширине резины */
  tireProfileWidth?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Список радиусов */
  tireRadius?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Список типов резины */
  tireType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WheelStatistic = {
  __typename?: 'WheelStatistic';
  /** Колес купленно */
  wheelBuyCount?: Maybe<Scalars['Int']>;
  /** Колес упакованно на складе */
  wheelPackedCount?: Maybe<Scalars['Int']>;
  /** Колес на складе */
  wheelStockCount?: Maybe<Scalars['Int']>;
  /** Колес не упакованно на складе  */
  wheelUnpackedCount?: Maybe<Scalars['Int']>;
};

export type WithoutMediaObjectsPaginatedList = {
  __typename?: 'WithoutMediaObjectsPaginatedList';
  nodes: Array<ObjectOfServices>;
  total: Scalars['Int'];
};

export type YahooOwnersPaginatedList = {
  __typename?: 'YahooOwnersPaginatedList';
  nodes: Array<YahooOwner>;
  total: Scalars['Int'];
};

export type YahooOwner = {
  __typename?: 'YahooOwner';
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isHasBlockedObjects?: Maybe<Scalars['Boolean']>;
  isHasOldDebts?: Maybe<Scalars['Boolean']>;
  isHasPenalty?: Maybe<Scalars['Boolean']>;
  isUnfairPartner?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<AddCommentOutput>;
  /** Добавить контейнер в план WorldWide */
  addContainerToPlan?: Maybe<AddContainerToPlanOutput>;
  addDisassemblyInfo?: Maybe<AddDisassemblyInfoOutput>;
  addExportCertificateToWorldwideObject?: Maybe<AddExportCertificateToWorldwideObjectOutput>;
  /** Добавить объект в план WorldWide */
  addExportObjectToPlan?: Maybe<AddExportObjectToPlanOutput>;
  addFilesToObject?: Maybe<AddFilesToObjectOutput>;
  addFirebaseToken?: Maybe<AddFirebaseTokenOutput>;
  addMessageToChat?: Maybe<AddMessageToChatOutput>;
  addObjectToPlan?: Maybe<AddObjectToPlanOutput>;
  addTagToChat?: Maybe<AddTagToChatOutput>;
  allowVisiblePhoto?: Maybe<AllowVisiblePhotoOutput>;
  /** Добавить авто к выставлению на аукцион */
  auctionListingCreate?: Maybe<AuctionListingCreateOutput>;
  /** Отметить что авто не продано */
  auctionListingNotSold?: Maybe<AuctionListingNotSoldOutput>;
  /** Указать транспортную компанию для перевозки на аукцион */
  auctionListingSetTransport?: Maybe<AuctionListingSetTransportOutput>;
  /** Отметить что авто продано */
  auctionListingSold?: Maybe<AuctionListingSoldOutput>;
  /** Обновить данные по выставлению авто */
  auctionListingUpdateLot?: Maybe<AuctionListingUpdateLotOutput>;
  blockTheIssuance?: Maybe<BlockTheIssuanceOutput>;
  bookingRequest?: Maybe<BookingRequestOutput>;
  /** Купить лотерейный билет */
  buyLotteryTicket?: Maybe<BuyLotteryTicketOutput>;
  /** Создание|Изменений цен авто ЗУ|Другие страны */
  carClientParamsUpdate?: Maybe<CarClientParamsUpdateOutput>;
  /** Скрыты/Не скрыты фото */
  carMediaHiddenToggle?: Maybe<CarMediaHiddenToggleOutput>;
  changeAdditionalType?: Maybe<ChangeAdditionalTypeOutput>;
  changeDesiredTrip?: Maybe<ChangeDesiredTripOutput>;
  changeExportPrice?: Maybe<ChangeExportPriceOutput>;
  changeObjectBroker?: Maybe<ChangeObjectBrokerOutput>;
  changeObjectFormalOwner?: Maybe<ChangeObjectFormalOwnerOutput>;
  changeObjectFreightCountry?: Maybe<ChangeObjectFreightCountryOutput>;
  changeObjectOwner?: Maybe<ChangeObjectOwnerOutput>;
  changeObjectSender?: Maybe<ChangeObjectSenderOutput>;
  changeObjectType?: Maybe<ChangeObjectTypeOutput>;
  changeObjectVin?: Maybe<ChangeObjectVinOutput>;
  changeParentObject?: Maybe<ChangeParentObjectOutput>;
  changeSenderClient?: Maybe<ChangeSenderClientOutput>;
  /** Добавить группу пользователь в чат */
  chatAddGroupUsers?: Maybe<ChatAddGroupUsersOutput>;
  chatAddUser?: Maybe<ChatAddUserOutput>;
  chatArchiving?: Maybe<ChatArchivingOutput>;
  chatCreate?: Maybe<CreateChatOutput>;
  chatDeleteMessage?: Maybe<ChatDeleteMessageOutput>;
  chatRemoveUser?: Maybe<ChatRemoveUserOutput>;
  chatUnArchiving?: Maybe<ChatUnArchivingOutput>;
  checkDateOfManufacture?: Maybe<CheckDateOfManufactureOutput>;
  completeObject?: Maybe<CompleteObjectOutput>;
  /** Загрузить подтверждение букинга */
  containerConfirmBooked?: Maybe<ContainerConfirmBookedOutput>;
  containerCreate?: Maybe<ContainerCreateOutput>;
  containerDelete?: Maybe<ContainerDeleteOutput>;
  containerUpdate?: Maybe<ContainerUpdateOutput>;
  countryCreate?: Maybe<CountryCreateOutput>;
  createDeliveryRequest?: Maybe<CreateDeliveryRequestOutput>;
  createInvoiceJapanAuctionPurchase?: Maybe<CreateInvoiceJapanAuctionPurchaseOutput>;
  createObjectAct?: Maybe<CreateObjectActOutput>;
  /** Создать квитанцию об оплате */
  createReceiptOfPayment?: Maybe<CreateReceiptOfPaymentOutput>;
  createSbktsRequest?: Maybe<CreateSbktsRequestOutput>;
  deleteObject?: Maybe<DeleteObjectOutput>;
  deleteUserNotifyMessage?: Maybe<DeleteUserNotifyMessageOutput>;
  deliveryToStock?: Maybe<DeliveryToStockOutput>;
  disallowVisiblePhoto?: Maybe<DisallowVisiblePhotoOutput>;
  /** Проверить статус оплаты услуги показа ставок */
  displayBidsCheck?: Maybe<DisplayBidsCheckOutput>;
  exchangeJobClose?: Maybe<ExchangeJobCloseOutput>;
  exchangeJobSend?: Maybe<ExchangeJobSendOutput>;
  /** Добавить объекты в экспортную декларацию */
  exportDeclarationAddObjects?: Maybe<ExportDeclarationAddObjectsOutput>;
  /** Создать экспортную декларацию */
  exportDeclarationCreate?: Maybe<ExportDeclarationCreateOutput>;
  /** Экспортная декларация оплачена */
  exportDeclarationPaid?: Maybe<ExportDeclarationPaidOutput>;
  /** Убрать объект из экспортной декларации */
  exportDeclarationRemoveObject?: Maybe<ExportDeclarationRemoveObjectOutput>;
  /** Изменить данные экспортной декларации */
  exportDeclarationUpdate?: Maybe<ExportDeclarationUpdateOutput>;
  exportToAuction?: Maybe<ExportToAuctionOutput>;
  fileDelete?: Maybe<FileDeleteOutput>;
  invoiceByObjectsGenerate?: Maybe<InvoiceByObjectsGenerateOutput>;
  invoiceCustomGenerate?: Maybe<InvoiceCustomGenerateOutput>;
  /** Выдать объект клиенту */
  issueObject?: Maybe<IssueObjectOutput>;
  /** Включить/выключить автовыставление лотов */
  kaitaiAutoResubmitChangeState?: Maybe<KaitaiAutoResubmitChangeStateOutput>;
  /** Запустить/остановить очередь Kaitai емэйлов */
  kaitaiQueueStatusChange?: Maybe<KaitaiQueueStatusChangeOutput>;
  magadanObjectCreate?: Maybe<MagadanObjectCreateOutput>;
  magadanObjectUpdate?: Maybe<MagadanObjectUpdateOutput>;
  makerCreate?: Maybe<MakerCreateOutput>;
  markCreate?: Maybe<MarkCreateOutput>;
  moveMediaToObject?: Maybe<MoveMediaToObjectOutput>;
  objectAddPriorityPass?: Maybe<ObjectAddPriorityPassOutput>;
  /** Добавить объекту статус "Запрошен букинг" */
  objectAddStatusBookingRequest?: Maybe<ObjectAddStatusBookingRequestOutput>;
  objectChangeDestinationCountry?: Maybe<ObjectChangeDestinationCountryOutput>;
  /** Запрос смены владельца объекта */
  objectChangeOwner?: Maybe<ObjectChangeOwnerOutput>;
  objectChangeSawType?: Maybe<ObjectChangeSawTypeOutput>;
  objectCheckDebts?: Maybe<ObjectCheckDebtsOutput>;
  /** Удаление файла таможенных документов */
  objectDeleteCustomDocuments?: Maybe<ObjectDeleteCustomDocumentsOutput>;
  objectDeleteMedia?: Maybe<ObjectDeleteMediaOutput>;
  /** Оставить отзыв. */
  objectFeedbackCreate?: Maybe<ObjectFeedbackCreateOutput>;
  objectGreenCornerTG?: Maybe<ObjectGreenCornerTgOutput>;
  objectHoldTo?: Maybe<ObjectHoldToOutput>;
  objectHoldToClose?: Maybe<ObjectHoldToCloseOutput>;
  /** Запросить видео досмотр */
  objectNeedVideoWatch?: Maybe<ObjectNeedVideoWatchOutput>;
  objectOfServiceRemoveFromContainer?: Maybe<ObjectOfServiceRemoveFromContainerOutput>;
  objectRecipientPaymentEmail?: Maybe<ObjectRecipientPaymentEmailOutput>;
  objectRequestToParking?: Maybe<ObjectRequestToParkingOutput>;
  /** Запрос услуги Подготовка пакета документов для жителей не ДВФО */
  objectRequestToPreparationDocuments?: Maybe<ObjectRequestToPreparationDocumentsOutput>;
  objectReset?: Maybe<ObjectResetOutput>;
  objectSendInvoiceEmail?: Maybe<ObjectSendInvoiceEmailOutput>;
  objectSetDateDismantleForCube?: Maybe<ObjectSetDateDismantleForCubeOutput>;
  objectSetDateRecyclingToCube?: Maybe<ObjectSetDateRecyclingToCubeOutput>;
  objectSetDateSoldRecyclingCube?: Maybe<ObjectSetDateSoldRecyclingCubeOutput>;
  objectSetGuidFrom1c?: Maybe<ObjectSetGuidFrom1cOutput>;
  /** Автозачет делается на общих основаниях */
  objectSetIsGeneralBasisAdvance?: Maybe<ObjectSetIsGeneralBasisAdvanceOutput>;
  /** Привязать авто к тарифу ЗУ */
  objectSetIsGreenCorner?: Maybe<ObjectSetIsGreenCornerOutput>;
  /** Указать стоимость объекта утилизации */
  objectSetKaitaiPrice?: Maybe<ObjectSetKaitaiPriceOutput>;
  /** Указать клиента покупки (конечный клиент) */
  objectSetMasterAccountClient?: Maybe<ObjectSetMasterAccountClientOutput>;
  /** Указать дату продажи авто Дубаи, Монголия и т.д */
  objectSetSoldDate?: Maybe<ObjectSetSoldDateOutput>;
  /** Загрузка файлов для таможни */
  objectUploadCustomDocuments?: Maybe<ObjectUploadCustomDocumentsOutput>;
  objectUploadCustomPayment?: Maybe<ObjectUploadCustomPaymentOutput>;
  objectUploadFiles?: Maybe<ObjectUploadFilesOutput>;
  ownerSearchByParams?: Maybe<GetAgentsByParamsOutput>;
  /** Добавить в палет */
  palletAddWheel?: Maybe<PalletAddWheelOutput>;
  /** Создать палет */
  palletCreate?: Maybe<PalletCreateOutput>;
  /** Добавить колеса в план */
  palletToPlan?: Maybe<PalletToPlanOutput>;
  /** Переместить паллет в порт */
  palletToPort?: Maybe<PalletToPortOutput>;
  /** Соглашение ПБ отправлено в офис */
  pbAgreementSended?: Maybe<PbAgreementSendedOutput>;
  pickupAppointment?: Maybe<PickupAppointmentOutput>;
  /** Запланировать на тип судна */
  planOnVesselType?: Maybe<PlanOnVesselTypeOutput>;
  portCreate?: Maybe<PortCreateOutput>;
  preSignedPostCreate?: Maybe<PreSignedPostCreateOutput>;
  putUpForSaleCreate?: Maybe<PutUpForSaleCreateOutput>;
  putUpForSaleSoldOut?: Maybe<PutUpForSaleSoldOutOutput>;
  putUpForSaleUpdate?: Maybe<PutUpForSaleUpdateOutput>;
  putUpForSaleWithdraw?: Maybe<PutUpForSaleWithdrawOutput>;
  reminderRemove?: Maybe<ReminderRemoveOutput>;
  removeFromStock?: Maybe<RemoveFromStockOutput>;
  /** Снять объект с плана */
  removeObjectFromPlan?: Maybe<RemoveObjectFromPlanOutput>;
  renewExportCertificate?: Maybe<RenewExportCertificateOutput>;
  replaceDouble?: Maybe<ReplaceDoubleOutput>;
  replaceToStock?: Maybe<ReplaceToStockOutput>;
  /** Осмотр с аукциона */
  requestPhotoFromAuction?: Maybe<RequestPhotoFromAuctionOutput>;
  requestPrimbrokerInvoice?: Maybe<RequestPrimbrokerInvoiceOutput>;
  requestSbktsCreate?: Maybe<RequestSbktsCreateOutput>;
  requestSbktsFromExcelCreate?: Maybe<RequestSbktsFromExcelCreateOutput>;
  requestToIssue?: Maybe<RequestToIssueOutput>;
  requestUnHold?: Maybe<RequestUnHoldOutput>;
  restoreObject?: Maybe<RestoreObjectOutput>;
  /** Добавить комментарий к объекту */
  scrapObjectCommentAdd?: Maybe<ScrapObjectCommentAddOutput>;
  /** Завести объект(запчасть) утилизации */
  scrapObjectCreate?: Maybe<ScrapObjectCreateOutput>;
  scrapObjectDeliverySet?: Maybe<ScrapObjectDeliverySetOutput>;
  /** Перевыставить объекты со скидкой */
  scrapObjectDiscountToYahoo?: Maybe<ScrapObjectsDiscountToYahooOutput>;
  /** Отменить лот в яху */
  scrapObjectLotCancel?: Maybe<ScrapObjectLotCancelOutput>;
  /** Расположение объекта на складе */
  scrapObjectPlaceSet?: Maybe<ScrapObjectPlaceSetOutput>;
  /** Отвязать запчасть и привязать к другому объекту */
  scrapObjectRebind?: Maybe<ScrapObjectRebindOutput>;
  /** Указать размер объекта */
  scrapObjectSizeSet?: Maybe<ScrapObjectSizeSetOutput>;
  /** Указать статус объекта */
  scrapObjectStatusSet?: Maybe<ScrapObjectStatusSetOutput>;
  /** Отправить объект в яху */
  scrapObjectToYahoo?: Maybe<ScrapObjectToYahooOutput>;
  /** Отвязать запчасть утилизации от головного */
  scrapObjectUnbind?: Maybe<ScrapObjectUnbindOutput>;
  /** Изменить объект(запчасть) утилизации */
  scrapObjectUpdate?: Maybe<ScrapObjectUpdateOutput>;
  /** Загрузить накладную отправки и списать объект */
  scrapObjectUploadShippingInvoice?: Maybe<ScrapObjectUploadShippingInvoiceOutput>;
  /** Списать объект */
  scrapObjectWriteOff?: Maybe<ScrapObjectWriteOffOutput>;
  scrapTypeCreate?: Maybe<ScrapTypeCreateOutput>;
  scrapTypeDescriptionCreate?: Maybe<ScrapTypeDescriptionCreateOutput>;
  scrapTypeDescriptionDelete?: Maybe<ScrapTypeDescriptionDeleteOutput>;
  scrapTypeDescriptionUpdate?: Maybe<ScrapTypeDescriptionUpdateOutput>;
  scrapTypeUpdate?: Maybe<ScrapTypeUpdateOutput>;
  scrapUtilized?: Maybe<ScrapUtilizedOutput>;
  /** Добавить морского доставщика */
  seaCarrierCreate?: Maybe<SeaCarrierCreateOutput>;
  /** Удалить морского доставщика */
  seaCarrierDelete?: Maybe<SeaCarrierDeleteOutput>;
  /** Изменгить морского доставщика */
  seaCarrierEdit?: Maybe<SeaCarrierEditOutput>;
  sendAllClientsNotification?: Maybe<SendAllClientsNotificationOutput>;
  sendDirectorFeedback?: Maybe<SendDirectorFeedbackOutput>;
  sendFeedback?: Maybe<SendFeedbackOutput>;
  sendNotificationEmail?: Maybe<SendNotificationEmailOutput>;
  sendPaymentInfo?: Maybe<SendPaymentInfoOutput>;
  /** @deprecated Use createReceiptOfPayment */
  sendReportAkebonoPayment?: Maybe<SendReportAkebonoPaymentOutput>;
  sendRussianDeliveryRequest?: Maybe<SendRussianDeliveryRequestOutput>;
  /** Отменить заявку инспекции */
  serviceRequestCanceled?: Maybe<ServiceRequestCanceledOutput>;
  /** Завершить работу над заявкой инспекции */
  serviceRequestCompleted?: Maybe<ServiceRequestCompletedOutput>;
  setBoundary?: Maybe<SetBoundaryOutput>;
  setIsGlonassNeed?: Maybe<SetIsGlonassNeedOutput>;
  sortObjectMedia?: Maybe<SortObjectMediaOutput>;
  storageCreate?: Maybe<StorageCreateOutput>;
  /** Редактирование параметров склада */
  storageEdit?: Maybe<StorageUpdateOutput>;
  storageSectorCreate?: Maybe<StorageSectorCreateOutput>;
  storageSectorEdit?: Maybe<StorageSectorEditOutput>;
  subscribeUserToTelegram?: Maybe<SubscribeUserToTelegramOutput>;
  syncObject?: Maybe<SyncObjectOutput>;
  ticketAddReview?: Maybe<TicketAddReviewOutput>;
  /** Добавить пользователя */
  ticketAddUser?: Maybe<TicketAddUserOutput>;
  /** Изменить дополнительную отметку притензии */
  ticketChangeSubType?: Maybe<TicketChangeSubTypeOutput>;
  /** Изменить тип претензии */
  ticketChangeType?: Maybe<TicketChangeTypeOutput>;
  ticketCreate?: Maybe<TicketCreateOutput>;
  /** Принять решение */
  ticketDecision?: Maybe<TicketDecisionOutput>;
  /** Добавить решение */
  ticketDecisionAdd?: Maybe<TicketDecisionAddOutput>;
  /** Апеллировать решение */
  ticketDecisionAppeal?: Maybe<TicketDecisionAppealOutput>;
  /** Подтвердить тикет */
  ticketDecisionApprove?: Maybe<TicketDecisionApproveOutput>;
  /** Подтвердить решение для руководителя организации */
  ticketDecisionApproveOrganization?: Maybe<TicketDecisionApproveOrganizationOutput>;
  /** Отменить решение */
  ticketDecisionCancel?: Maybe<TicketDecisionCancelOutput>;
  /** Удалить решение претензии из любого состояния */
  ticketDecisionCancelOnlyAdmin?: Maybe<TicketDecisionCancelOnlyAdminOutput>;
  /** Выплатить по претензии */
  ticketDecisionPayout?: Maybe<TicketDecisionPayoutOutput>;
  /** Сбросить из ожидает оплаты */
  ticketDecisionResetPayout?: Maybe<TicketDecisionResetPayoutOutput>;
  /** Изменить сумму решения */
  ticketDecisionUpdate?: Maybe<TicketDecisionUpdateOutput>;
  /** Отклонить претензию */
  ticketDecline?: Maybe<TicketDeclineOutput>;
  /** Удалить пользователя из претензии */
  ticketRemoveUser?: Maybe<TicketRemoveUserOutput>;
  /** Восстановить претензию */
  ticketReset?: Maybe<TicketResetOutput>;
  /** Закрыть без уведомления */
  ticketSilentClose?: Maybe<TicketSilentCloseOutput>;
  toggleUserNotifyMessage?: Maybe<ToggleUserNotifyMessageOutput>;
  /** Сменить порядок объектов на рейсе */
  tripChangeObjectsOrder?: Maybe<TripChangeObjectsOrderOutput>;
  tripClose?: Maybe<TripCloseOutput>;
  tripCreate?: Maybe<TripCreateOutput>;
  /** Удалить пустой рейс */
  tripDelete?: Maybe<TripDeleteOutput>;
  tripEdit?: Maybe<TripEditOutput>;
  /** Погрузить объекты на судно */
  tripLoading?: Maybe<TripLoadingOutput>;
  /** Вернуть объекты в порт погрузки */
  tripReturnToSourcePort?: Maybe<TripReturnToSourcePortOutput>;
  /** Рейс прибыл, выгрузка */
  tripToPort?: Maybe<TripToPortOutput>;
  typeOfExportEdit?: Maybe<TypeOfExportEditOutput>;
  unlockTheIssuance?: Maybe<UnlockTheIssuanceOutput>;
  unViewChat?: Maybe<UnViewChatOutput>;
  updateInvoiceAuctionPurchase?: Maybe<UpdateInvoiceAuctionPurchaseOutput>;
  uploadFiles?: Maybe<UploadFilesOutput>;
  userChangePassword?: Maybe<UserChangePasswordOutput>;
  userProfileUpdate?: Maybe<UserProfileUpdateOutput>;
  /** Создать судно */
  vesselCreate?: Maybe<VesselCreateOutput>;
  /** Редактировать судно */
  vesselUpdate?: Maybe<VesselUpdateOutput>;
  viewChat?: Maybe<ViewChatOutput>;
  watchCarUpdate?: Maybe<WatchCarUpdateOutput>;
  /** Купить колеса */
  wheelBuy?: Maybe<WheelBuyOutput>;
  /** Снять колёса с машины */
  wheelCreate?: Maybe<WheelCreateOutput>;
  /** Убрать колеса с пелеты */
  wheelRemoveFromPalle?: Maybe<WheelRemoveFromPalletOutput>;
  /** Собрано в палете */
  wheelSetIsLoaded?: Maybe<WheelSetIsLoadedOutput>;
  /** Установить цену колес */
  wheelSetPrice?: Maybe<WheelSetPriceOutput>;
};


export type MutationAddCommentArgs = {
  input?: Maybe<AddCommentInput>;
};


export type MutationAddContainerToPlanArgs = {
  input?: Maybe<AddContainerToPlanInput>;
};


export type MutationAddDisassemblyInfoArgs = {
  input?: Maybe<AddDisassemblyInfoInput>;
};


export type MutationAddExportCertificateToWorldwideObjectArgs = {
  input?: Maybe<AddExportCertificateToWorldwideObjectInput>;
};


export type MutationAddExportObjectToPlanArgs = {
  input?: Maybe<AddExportObjectToPlanInput>;
};


export type MutationAddFilesToObjectArgs = {
  input?: Maybe<AddFilesToObjectInput>;
};


export type MutationAddFirebaseTokenArgs = {
  input?: Maybe<AddFirebaseTokenInput>;
};


export type MutationAddMessageToChatArgs = {
  input?: Maybe<AddMessageToChatInput>;
};


export type MutationAddObjectToPlanArgs = {
  input?: Maybe<AddObjectToPlanInput>;
};


export type MutationAddTagToChatArgs = {
  input?: Maybe<AddTagToChatInput>;
};


export type MutationAllowVisiblePhotoArgs = {
  input?: Maybe<AllowVisiblePhotoInput>;
};


export type MutationAuctionListingCreateArgs = {
  input?: Maybe<AuctionListingCreateInput>;
};


export type MutationAuctionListingNotSoldArgs = {
  input?: Maybe<AuctionListingNotSoldInput>;
};


export type MutationAuctionListingSetTransportArgs = {
  input?: Maybe<AuctionListingSetTransportInput>;
};


export type MutationAuctionListingSoldArgs = {
  input?: Maybe<AuctionListingSoldInput>;
};


export type MutationAuctionListingUpdateLotArgs = {
  input?: Maybe<AuctionListingUpdateLotInput>;
};


export type MutationBlockTheIssuanceArgs = {
  input?: Maybe<BlockTheIssuanceInput>;
};


export type MutationBookingRequestArgs = {
  input?: Maybe<BookingRequestInput>;
};


export type MutationBuyLotteryTicketArgs = {
  input?: Maybe<BuyLotteryTicketInput>;
};


export type MutationCarClientParamsUpdateArgs = {
  input?: Maybe<CarClientParamsUpdateInput>;
};


export type MutationCarMediaHiddenToggleArgs = {
  input?: Maybe<CarMediaHiddenToggleInput>;
};


export type MutationChangeAdditionalTypeArgs = {
  input?: Maybe<ChangeAdditionalTypeInput>;
};


export type MutationChangeDesiredTripArgs = {
  input?: Maybe<ChangeDesiredTripInput>;
};


export type MutationChangeExportPriceArgs = {
  input?: Maybe<ChangeExportPriceInput>;
};


export type MutationChangeObjectBrokerArgs = {
  input?: Maybe<ChangeObjectBrokerInput>;
};


export type MutationChangeObjectFormalOwnerArgs = {
  input?: Maybe<ChangeObjectFormalOwnerInput>;
};


export type MutationChangeObjectFreightCountryArgs = {
  input?: Maybe<ChangeObjectFreightCountryInput>;
};


export type MutationChangeObjectOwnerArgs = {
  input?: Maybe<ChangeObjectOwnerInput>;
};


export type MutationChangeObjectSenderArgs = {
  input?: Maybe<ChangeObjectSenderInput>;
};


export type MutationChangeObjectTypeArgs = {
  input?: Maybe<ChangeObjectTypeInput>;
};


export type MutationChangeObjectVinArgs = {
  input?: Maybe<ChangeObjectVinInput>;
};


export type MutationChangeParentObjectArgs = {
  input?: Maybe<ChangeParentObjectInput>;
};


export type MutationChangeSenderClientArgs = {
  input?: Maybe<ChangeSenderClientInput>;
};


export type MutationChatAddGroupUsersArgs = {
  input?: Maybe<ChatAddGroupUsersInput>;
};


export type MutationChatAddUserArgs = {
  input?: Maybe<ChatAddUserInput>;
};


export type MutationChatArchivingArgs = {
  input?: Maybe<ChatArchivingInput>;
};


export type MutationChatCreateArgs = {
  input?: Maybe<CreateChatInput>;
};


export type MutationChatDeleteMessageArgs = {
  input?: Maybe<ChatDeleteMessageInput>;
};


export type MutationChatRemoveUserArgs = {
  input?: Maybe<ChatRemoveUserInput>;
};


export type MutationChatUnArchivingArgs = {
  input?: Maybe<ChatUnArchivingInput>;
};


export type MutationCheckDateOfManufactureArgs = {
  input?: Maybe<CheckDateOfManufactureInput>;
};


export type MutationCompleteObjectArgs = {
  input?: Maybe<CompleteObjectInput>;
};


export type MutationContainerConfirmBookedArgs = {
  input?: Maybe<ContainerConfirmBookedInput>;
};


export type MutationContainerCreateArgs = {
  input?: Maybe<ContainerCreateInput>;
};


export type MutationContainerDeleteArgs = {
  input?: Maybe<ContainerDeleteInput>;
};


export type MutationContainerUpdateArgs = {
  input?: Maybe<ContainerUpdateInput>;
};


export type MutationCountryCreateArgs = {
  input?: Maybe<CountryCreateInput>;
};


export type MutationCreateDeliveryRequestArgs = {
  input?: Maybe<CreateDeliveryRequestInput>;
};


export type MutationCreateInvoiceJapanAuctionPurchaseArgs = {
  input?: Maybe<CreateInvoiceJapanAuctionPurchaseInput>;
};


export type MutationCreateObjectActArgs = {
  input?: Maybe<CreateObjectActInput>;
};


export type MutationCreateReceiptOfPaymentArgs = {
  input?: Maybe<CreateReceiptOfPaymentInput>;
};


export type MutationCreateSbktsRequestArgs = {
  input?: Maybe<CreateSbktsRequestInput>;
};


export type MutationDeleteObjectArgs = {
  input?: Maybe<DeleteObjectInput>;
};


export type MutationDeleteUserNotifyMessageArgs = {
  input?: Maybe<DeleteUserNotifyMessageInput>;
};


export type MutationDeliveryToStockArgs = {
  input?: Maybe<DeliveryToStockInput>;
};


export type MutationDisallowVisiblePhotoArgs = {
  input?: Maybe<DisallowVisiblePhotoInput>;
};


export type MutationDisplayBidsCheckArgs = {
  input?: Maybe<DisplayBidsCheckInput>;
};


export type MutationExchangeJobCloseArgs = {
  input?: Maybe<ExchangeJobCloseInput>;
};


export type MutationExchangeJobSendArgs = {
  input?: Maybe<ExchangeJobSendInput>;
};


export type MutationExportDeclarationAddObjectsArgs = {
  input?: Maybe<ExportDeclarationAddObjectsInput>;
};


export type MutationExportDeclarationCreateArgs = {
  input?: Maybe<ExportDeclarationCreateInput>;
};


export type MutationExportDeclarationPaidArgs = {
  input?: Maybe<ExportDeclarationPaidInput>;
};


export type MutationExportDeclarationRemoveObjectArgs = {
  input?: Maybe<ExportDeclarationRemoveObjectInput>;
};


export type MutationExportDeclarationUpdateArgs = {
  input?: Maybe<ExportDeclarationUpdateInput>;
};


export type MutationExportToAuctionArgs = {
  input?: Maybe<ExportToAuctionInput>;
};


export type MutationFileDeleteArgs = {
  input?: Maybe<FileDeleteInput>;
};


export type MutationInvoiceByObjectsGenerateArgs = {
  input?: Maybe<InvoiceByObjectsGenerateInput>;
};


export type MutationInvoiceCustomGenerateArgs = {
  input?: Maybe<InvoiceCustomGenerateInput>;
};


export type MutationIssueObjectArgs = {
  input?: Maybe<IssueObjectInput>;
};


export type MutationKaitaiAutoResubmitChangeStateArgs = {
  input?: Maybe<KaitaiAutoResubmitChangeStateInput>;
};


export type MutationKaitaiQueueStatusChangeArgs = {
  input?: Maybe<KaitaiQueueStatusChangeInput>;
};


export type MutationMagadanObjectCreateArgs = {
  input?: Maybe<MagadanObjectCreateInput>;
};


export type MutationMagadanObjectUpdateArgs = {
  input?: Maybe<MagadanObjectUpdateInput>;
};


export type MutationMakerCreateArgs = {
  input?: Maybe<MakerCreateInput>;
};


export type MutationMarkCreateArgs = {
  input?: Maybe<MarkCreateInput>;
};


export type MutationMoveMediaToObjectArgs = {
  input?: Maybe<MoveMediaToObjectInput>;
};


export type MutationObjectAddPriorityPassArgs = {
  input?: Maybe<ObjectAddPriorityPassInput>;
};


export type MutationObjectAddStatusBookingRequestArgs = {
  input?: Maybe<ObjectAddStatusBookingRequestInput>;
};


export type MutationObjectChangeDestinationCountryArgs = {
  input?: Maybe<ObjectChangeDestinationCountryInput>;
};


export type MutationObjectChangeOwnerArgs = {
  input?: Maybe<ObjectChangeOwnerInput>;
};


export type MutationObjectChangeSawTypeArgs = {
  input?: Maybe<ObjectChangeSawTypeInput>;
};


export type MutationObjectCheckDebtsArgs = {
  input?: Maybe<ObjectCheckDebtsInput>;
};


export type MutationObjectDeleteCustomDocumentsArgs = {
  input?: Maybe<ObjectDeleteCustomDocumentsInput>;
};


export type MutationObjectDeleteMediaArgs = {
  input?: Maybe<ObjectDeleteMediaInput>;
};


export type MutationObjectFeedbackCreateArgs = {
  input?: Maybe<ObjectFeedbackCreateInput>;
};


export type MutationObjectGreenCornerTgArgs = {
  input?: Maybe<ObjectGreenCornerTgInput>;
};


export type MutationObjectHoldToArgs = {
  input?: Maybe<ObjectHoldToInput>;
};


export type MutationObjectHoldToCloseArgs = {
  input?: Maybe<ObjectHoldToCloseInput>;
};


export type MutationObjectNeedVideoWatchArgs = {
  input?: Maybe<ObjectNeedVideoWatchInput>;
};


export type MutationObjectOfServiceRemoveFromContainerArgs = {
  input?: Maybe<ObjectOfServiceRemoveFromContainerInput>;
};


export type MutationObjectRecipientPaymentEmailArgs = {
  input?: Maybe<ObjectRecipientPaymentEmailInput>;
};


export type MutationObjectRequestToParkingArgs = {
  input?: Maybe<ObjectRequestToParkingInput>;
};


export type MutationObjectRequestToPreparationDocumentsArgs = {
  input?: Maybe<ObjectRequestToPreparationDocumentsInput>;
};


export type MutationObjectResetArgs = {
  input?: Maybe<ObjectResetInput>;
};


export type MutationObjectSendInvoiceEmailArgs = {
  input?: Maybe<ObjectSendInvoiceEmailInput>;
};


export type MutationObjectSetDateDismantleForCubeArgs = {
  input?: Maybe<ObjectSetDateDismantleForCubeInput>;
};


export type MutationObjectSetDateRecyclingToCubeArgs = {
  input?: Maybe<ObjectSetDateRecyclingToCubeInput>;
};


export type MutationObjectSetDateSoldRecyclingCubeArgs = {
  input?: Maybe<ObjectSetDateSoldRecyclingCubeInput>;
};


export type MutationObjectSetGuidFrom1cArgs = {
  input?: Maybe<ObjectSetGuidFrom1cInput>;
};


export type MutationObjectSetIsGeneralBasisAdvanceArgs = {
  input?: Maybe<ObjectSetIsGeneralBasisAdvanceInput>;
};


export type MutationObjectSetIsGreenCornerArgs = {
  input?: Maybe<ObjectSetIsGreenCornerInput>;
};


export type MutationObjectSetKaitaiPriceArgs = {
  input?: Maybe<ObjectSetKaitaiPriceInput>;
};


export type MutationObjectSetMasterAccountClientArgs = {
  input?: Maybe<ObjectSetMasterAccountClientInput>;
};


export type MutationObjectSetSoldDateArgs = {
  input?: Maybe<ObjectSetSoldDateInput>;
};


export type MutationObjectUploadCustomDocumentsArgs = {
  input?: Maybe<ObjectUploadCustomDocumentsInput>;
};


export type MutationObjectUploadCustomPaymentArgs = {
  input?: Maybe<ObjectUploadCustomPaymentInput>;
};


export type MutationObjectUploadFilesArgs = {
  input?: Maybe<ObjectUploadFilesInput>;
};


export type MutationOwnerSearchByParamsArgs = {
  input?: Maybe<GetAgentsByParamsInput>;
};


export type MutationPalletAddWheelArgs = {
  input?: Maybe<PalletAddWheelInput>;
};


export type MutationPalletCreateArgs = {
  input?: Maybe<PalletCreateInput>;
};


export type MutationPalletToPlanArgs = {
  input?: Maybe<PalletToPlanInput>;
};


export type MutationPalletToPortArgs = {
  input?: Maybe<PalletToPortInput>;
};


export type MutationPbAgreementSendedArgs = {
  input?: Maybe<PbAgreementSendedInput>;
};


export type MutationPickupAppointmentArgs = {
  input?: Maybe<PickupAppointmentInput>;
};


export type MutationPlanOnVesselTypeArgs = {
  input?: Maybe<PlanOnVesselTypeInput>;
};


export type MutationPortCreateArgs = {
  input?: Maybe<PortCreateInput>;
};


export type MutationPreSignedPostCreateArgs = {
  input?: Maybe<PreSignedPostCreateInput>;
};


export type MutationPutUpForSaleCreateArgs = {
  input?: Maybe<PutUpForSaleCreateInput>;
};


export type MutationPutUpForSaleSoldOutArgs = {
  input?: Maybe<PutUpForSaleSoldOutInput>;
};


export type MutationPutUpForSaleUpdateArgs = {
  input?: Maybe<PutUpForSaleUpdateInput>;
};


export type MutationPutUpForSaleWithdrawArgs = {
  input?: Maybe<PutUpForSaleWithdrawInput>;
};


export type MutationReminderRemoveArgs = {
  input?: Maybe<ReminderRemoveInput>;
};


export type MutationRemoveFromStockArgs = {
  input?: Maybe<RemoveFromStockInput>;
};


export type MutationRemoveObjectFromPlanArgs = {
  input?: Maybe<RemoveObjectFromPlanInput>;
};


export type MutationRenewExportCertificateArgs = {
  input?: Maybe<RenewExportCertificateInput>;
};


export type MutationReplaceDoubleArgs = {
  input?: Maybe<ReplaceDoubleInput>;
};


export type MutationReplaceToStockArgs = {
  input?: Maybe<ReplaceToStockInput>;
};


export type MutationRequestPhotoFromAuctionArgs = {
  input?: Maybe<RequestPhotoFromAuctionInput>;
};


export type MutationRequestPrimbrokerInvoiceArgs = {
  input?: Maybe<RequestPrimbrokerInvoiceInput>;
};


export type MutationRequestSbktsCreateArgs = {
  input?: Maybe<RequestSbktsCreateInput>;
};


export type MutationRequestSbktsFromExcelCreateArgs = {
  input?: Maybe<RequestSbktsFromExcelCreateInput>;
};


export type MutationRequestToIssueArgs = {
  input?: Maybe<RequestToIssueInput>;
};


export type MutationRequestUnHoldArgs = {
  input?: Maybe<RequestUnHoldInput>;
};


export type MutationRestoreObjectArgs = {
  input?: Maybe<RestoreObjectInput>;
};


export type MutationScrapObjectCommentAddArgs = {
  input?: Maybe<ScrapObjectCommentAddInput>;
};


export type MutationScrapObjectCreateArgs = {
  input?: Maybe<ScrapObjectCreateInput>;
};


export type MutationScrapObjectDeliverySetArgs = {
  input?: Maybe<ScrapObjectDeliverySetInput>;
};


export type MutationScrapObjectDiscountToYahooArgs = {
  input?: Maybe<ScrapObjectsDiscountToYahooInput>;
};


export type MutationScrapObjectLotCancelArgs = {
  input?: Maybe<ScrapObjectLotCancelInput>;
};


export type MutationScrapObjectPlaceSetArgs = {
  input?: Maybe<ScrapObjectPlaceSetInput>;
};


export type MutationScrapObjectRebindArgs = {
  input?: Maybe<ScrapObjectRebindInput>;
};


export type MutationScrapObjectSizeSetArgs = {
  input?: Maybe<ScrapObjectSizeSetInput>;
};


export type MutationScrapObjectStatusSetArgs = {
  input?: Maybe<ScrapObjectStatusSetInput>;
};


export type MutationScrapObjectToYahooArgs = {
  input?: Maybe<ScrapObjectToYahooInput>;
};


export type MutationScrapObjectUnbindArgs = {
  input?: Maybe<ScrapObjectUnbindInput>;
};


export type MutationScrapObjectUpdateArgs = {
  input?: Maybe<ScrapObjectUpdateInput>;
};


export type MutationScrapObjectUploadShippingInvoiceArgs = {
  input?: Maybe<ScrapObjectUploadShippingInvoiceInput>;
};


export type MutationScrapObjectWriteOffArgs = {
  input?: Maybe<ScrapObjectWriteOffInput>;
};


export type MutationScrapTypeCreateArgs = {
  input?: Maybe<ScrapTypeCreateInput>;
};


export type MutationScrapTypeDescriptionCreateArgs = {
  input?: Maybe<ScrapTypeDescriptionCreateInput>;
};


export type MutationScrapTypeDescriptionDeleteArgs = {
  input?: Maybe<ScrapTypeDescriptionDeleteInput>;
};


export type MutationScrapTypeDescriptionUpdateArgs = {
  input?: Maybe<ScrapTypeDescriptionUpdateInput>;
};


export type MutationScrapTypeUpdateArgs = {
  input?: Maybe<ScrapTypeUpdateInput>;
};


export type MutationScrapUtilizedArgs = {
  input?: Maybe<ScrapUtilizedInput>;
};


export type MutationSeaCarrierCreateArgs = {
  input?: Maybe<SeaCarrierCreateInput>;
};


export type MutationSeaCarrierDeleteArgs = {
  input?: Maybe<SeaCarrierDeleteInput>;
};


export type MutationSeaCarrierEditArgs = {
  input?: Maybe<SeaCarrierEditInput>;
};


export type MutationSendAllClientsNotificationArgs = {
  input?: Maybe<SendAllClientsNotificationInput>;
};


export type MutationSendDirectorFeedbackArgs = {
  input?: Maybe<SendDirectorFeedbackInput>;
};


export type MutationSendFeedbackArgs = {
  input?: Maybe<SendFeedbackInput>;
};


export type MutationSendNotificationEmailArgs = {
  input?: Maybe<SendNotificationEmailInput>;
};


export type MutationSendPaymentInfoArgs = {
  input?: Maybe<SendPaymentInfoInput>;
};


export type MutationSendReportAkebonoPaymentArgs = {
  input?: Maybe<SendReportAkebonoPaymentInput>;
};


export type MutationSendRussianDeliveryRequestArgs = {
  input?: Maybe<SendRussianDeliveryRequestInput>;
};


export type MutationServiceRequestCanceledArgs = {
  input?: Maybe<ServiceRequestCanceledInput>;
};


export type MutationServiceRequestCompletedArgs = {
  input?: Maybe<ServiceRequestCompletedInput>;
};


export type MutationSetBoundaryArgs = {
  input?: Maybe<SetBoundaryInput>;
};


export type MutationSetIsGlonassNeedArgs = {
  input?: Maybe<SetIsGlonassNeedInput>;
};


export type MutationSortObjectMediaArgs = {
  input?: Maybe<SortObjectMediaInput>;
};


export type MutationStorageCreateArgs = {
  input?: Maybe<StorageCreateInput>;
};


export type MutationStorageEditArgs = {
  input?: Maybe<StorageUpdateInput>;
};


export type MutationStorageSectorCreateArgs = {
  input?: Maybe<StorageSectorCreateInput>;
};


export type MutationStorageSectorEditArgs = {
  input?: Maybe<StorageSectorEditInput>;
};


export type MutationSubscribeUserToTelegramArgs = {
  input?: Maybe<SubscribeUserToTelegramInput>;
};


export type MutationSyncObjectArgs = {
  input?: Maybe<SyncObjectInput>;
};


export type MutationTicketAddReviewArgs = {
  input?: Maybe<TicketAddReviewInput>;
};


export type MutationTicketAddUserArgs = {
  input?: Maybe<TicketAddUserInput>;
};


export type MutationTicketChangeSubTypeArgs = {
  input?: Maybe<TicketChangeSubTypeInput>;
};


export type MutationTicketChangeTypeArgs = {
  input?: Maybe<TicketChangeTypeInput>;
};


export type MutationTicketCreateArgs = {
  input?: Maybe<TicketCreateInput>;
};


export type MutationTicketDecisionArgs = {
  input?: Maybe<TicketDecisionInput>;
};


export type MutationTicketDecisionAddArgs = {
  input?: Maybe<TicketDecisionAddInput>;
};


export type MutationTicketDecisionAppealArgs = {
  input?: Maybe<TicketDecisionAppealInput>;
};


export type MutationTicketDecisionApproveArgs = {
  input?: Maybe<TicketDecisionApproveInput>;
};


export type MutationTicketDecisionApproveOrganizationArgs = {
  input?: Maybe<TicketDecisionApproveOrganizationInput>;
};


export type MutationTicketDecisionCancelArgs = {
  input?: Maybe<TicketDecisionCancelInput>;
};


export type MutationTicketDecisionCancelOnlyAdminArgs = {
  input?: Maybe<TicketDecisionCancelOnlyAdminInput>;
};


export type MutationTicketDecisionPayoutArgs = {
  input?: Maybe<TicketDecisionPayoutInput>;
};


export type MutationTicketDecisionResetPayoutArgs = {
  input?: Maybe<TicketDecisionResetPayoutInput>;
};


export type MutationTicketDecisionUpdateArgs = {
  input?: Maybe<TicketDecisionUpdateInput>;
};


export type MutationTicketDeclineArgs = {
  input?: Maybe<TicketDeclineInput>;
};


export type MutationTicketRemoveUserArgs = {
  input?: Maybe<TicketRemoveUserInput>;
};


export type MutationTicketResetArgs = {
  input?: Maybe<TicketResetInput>;
};


export type MutationTicketSilentCloseArgs = {
  input?: Maybe<TicketSilentCloseInput>;
};


export type MutationToggleUserNotifyMessageArgs = {
  input?: Maybe<ToggleUserNotifyMessageInput>;
};


export type MutationTripChangeObjectsOrderArgs = {
  input?: Maybe<TripChangeObjectsOrderInput>;
};


export type MutationTripCloseArgs = {
  input?: Maybe<TripCloseInput>;
};


export type MutationTripCreateArgs = {
  input?: Maybe<TripCreateInput>;
};


export type MutationTripDeleteArgs = {
  input?: Maybe<TripDeleteInput>;
};


export type MutationTripEditArgs = {
  input?: Maybe<TripEditInput>;
};


export type MutationTripLoadingArgs = {
  input?: Maybe<TripLoadingInput>;
};


export type MutationTripReturnToSourcePortArgs = {
  input?: Maybe<TripReturnToSourcePortInput>;
};


export type MutationTripToPortArgs = {
  input?: Maybe<TripToPortInput>;
};


export type MutationTypeOfExportEditArgs = {
  input?: Maybe<TypeOfExportEditInput>;
};


export type MutationUnlockTheIssuanceArgs = {
  input?: Maybe<UnlockTheIssuanceInput>;
};


export type MutationUnViewChatArgs = {
  input?: Maybe<UnViewChatInput>;
};


export type MutationUpdateInvoiceAuctionPurchaseArgs = {
  input?: Maybe<UpdateInvoiceAuctionPurchaseInput>;
};


export type MutationUploadFilesArgs = {
  input?: Maybe<UploadFilesInput>;
};


export type MutationUserChangePasswordArgs = {
  input?: Maybe<UserChangePasswordInput>;
};


export type MutationUserProfileUpdateArgs = {
  input?: Maybe<UserProfileUpdateInput>;
};


export type MutationVesselCreateArgs = {
  input?: Maybe<VesselCreateInput>;
};


export type MutationVesselUpdateArgs = {
  input?: Maybe<VesselUpdateInput>;
};


export type MutationViewChatArgs = {
  input?: Maybe<ViewChatInput>;
};


export type MutationWatchCarUpdateArgs = {
  input?: Maybe<WatchCarUpdateInput>;
};


export type MutationWheelBuyArgs = {
  input?: Maybe<WheelBuyInput>;
};


export type MutationWheelCreateArgs = {
  input?: Maybe<WheelCreateInput>;
};


export type MutationWheelRemoveFromPalleArgs = {
  input?: Maybe<WheelRemoveFromPalletInput>;
};


export type MutationWheelSetIsLoadedArgs = {
  input?: Maybe<WheelSetIsLoadedInput>;
};


export type MutationWheelSetPriceArgs = {
  input?: Maybe<WheelSetPriceInput>;
};

export type AddCommentInput = {
  comment: Scalars['String'];
  objectId: Scalars['Int'];
};

export type AddCommentOutput = {
  __typename?: 'AddCommentOutput';
  error?: Maybe<MutationError>;
};

export type MutationError = {
  __typename?: 'MutationError';
  code?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AddContainerToPlanInput = {
  containerId: Scalars['Int'];
  planId: Scalars['Int'];
};

export type AddContainerToPlanOutput = {
  __typename?: 'AddContainerToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddDisassemblyInfoInput = {
  message: Scalars['String'];
  objectId: Scalars['Int'];
  type: DisassemblyInfoEnum;
};

export enum DisassemblyInfoEnum {
  Manual = 'manual',
  Notice = 'notice'
}

export type AddDisassemblyInfoOutput = {
  __typename?: 'AddDisassemblyInfoOutput';
  error?: Maybe<MutationError>;
};

export type AddExportCertificateToWorldwideObjectInput = {
  expirationDate: Scalars['String'];
  exportCertificateKeyS3File: Scalars['String'];
  number: Scalars['String'];
  objectId: Scalars['Int'];
};

export type AddExportCertificateToWorldwideObjectOutput = {
  __typename?: 'AddExportCertificateToWorldwideObjectOutput';
  error?: Maybe<MutationError>;
};

export type AddExportObjectToPlanInput = {
  chassisId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  customId?: Maybe<Scalars['Int']>;
  engine?: Maybe<Scalars['String']>;
  engineTypeId?: Maybe<Scalars['Int']>;
  engineVolumeCm3?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  isAddDelivery?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Int']>;
  loadCapacity?: Maybe<Scalars['Int']>;
  makerId: Scalars['Int'];
  markId: Scalars['Int'];
  objectId: Scalars['Int'];
  ownerId: Scalars['Int'];
  payCountryId?: Maybe<Scalars['Int']>;
  planId: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  priceCurrencyId?: Maybe<Scalars['Int']>;
  seats?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  typeId: Scalars['Int'];
  vin: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};

export type AddExportObjectToPlanOutput = {
  __typename?: 'AddExportObjectToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddFilesToObjectInput = {
  contentType: Scalars['String'];
  keyS3Files: Array<Scalars['String']>;
  objectId: Scalars['Int'];
  type: MediaTypeEnum;
};

export type AddFilesToObjectOutput = {
  __typename?: 'AddFilesToObjectOutput';
  error?: Maybe<MutationError>;
};

export type AddFirebaseTokenInput = {
  token: Scalars['String'];
};

export type AddFirebaseTokenOutput = {
  __typename?: 'AddFirebaseTokenOutput';
  error?: Maybe<MutationError>;
};

export type AddMessageToChatInput = {
  chatId: Scalars['Int'];
  filesKeys: Array<Maybe<Scalars['String']>>;
  isTask: Scalars['Boolean'];
  text: Scalars['String'];
};

export type AddMessageToChatOutput = {
  __typename?: 'AddMessageToChatOutput';
  error?: Maybe<MutationError>;
};

export type AddObjectToPlanInput = {
  contract: Scalars['Int'];
  isAddDelivery?: Maybe<Scalars['Boolean']>;
  objectId: Scalars['Int'];
  planId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type AddObjectToPlanOutput = {
  __typename?: 'AddObjectToPlanOutput';
  error?: Maybe<MutationError>;
};

export type AddTagToChatInput = {
  chatId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type AddTagToChatOutput = {
  __typename?: 'AddTagToChatOutput';
  error?: Maybe<MutationError>;
};

export type AllowVisiblePhotoInput = {
  objectId: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};

export type AllowVisiblePhotoOutput = {
  __typename?: 'AllowVisiblePhotoOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingCreateInput = {
  auctionId: Scalars['Int'];
  objectId: Scalars['Int'];
  startPrice: Scalars['Int'];
  wishPrice: Scalars['Int'];
};

export type AuctionListingCreateOutput = {
  __typename?: 'AuctionListingCreateOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingNotSoldInput = {
  notSoldPrice: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type AuctionListingNotSoldOutput = {
  __typename?: 'AuctionListingNotSoldOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingSetTransportInput = {
  objectId: Scalars['Int'];
  transportCompany: Scalars['String'];
};

export type AuctionListingSetTransportOutput = {
  __typename?: 'AuctionListingSetTransportOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingSoldInput = {
  objectId: Scalars['Int'];
  soldPrice: Scalars['Int'];
};

export type AuctionListingSoldOutput = {
  __typename?: 'AuctionListingSoldOutput';
  error?: Maybe<MutationError>;
};

export type AuctionListingUpdateLotInput = {
  auctionDate: Scalars['String'];
  auctionId: Scalars['Int'];
  kai?: Maybe<Scalars['String']>;
  numberLot?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  startPrice: Scalars['Int'];
  wishPrice: Scalars['Int'];
};

export type AuctionListingUpdateLotOutput = {
  __typename?: 'AuctionListingUpdateLotOutput';
  error?: Maybe<MutationError>;
};

export type BlockTheIssuanceInput = {
  comment: Scalars['String'];
  objectId: Scalars['Int'];
};

export type BlockTheIssuanceOutput = {
  __typename?: 'BlockTheIssuanceOutput';
  error?: Maybe<MutationError>;
};

export type BookingRequestInput = {
  isBoundary: Scalars['Boolean'];
  makerId?: Maybe<Scalars['Int']>;
  markId?: Maybe<Scalars['Int']>;
  mediaKeyS3ExportCertificate: Scalars['String'];
  mediaKeysS3Documents?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectId?: Maybe<Scalars['Int']>;
  objectState: ObjectStateEnum;
  tripId?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['Int']>;
};

export type BookingRequestOutput = {
  __typename?: 'BookingRequestOutput';
  error?: Maybe<MutationError>;
};

export type BuyLotteryTicketInput = {
  ticketId: Scalars['Int'];
};

export type BuyLotteryTicketOutput = {
  __typename?: 'BuyLotteryTicketOutput';
  error?: Maybe<MutationError>;
};

export type CarClientParamsUpdateInput = {
  currencyInDestination?: Maybe<Scalars['Int']>;
  currencyInJapanId?: Maybe<Scalars['Int']>;
  currencyOnDeliveryId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  priceInDestination?: Maybe<Scalars['Int']>;
  priceInJapan?: Maybe<Scalars['Int']>;
  priceOnDelivery?: Maybe<Scalars['Int']>;
};

export type CarClientParamsUpdateOutput = {
  __typename?: 'CarClientParamsUpdateOutput';
  error?: Maybe<MutationError>;
};

export type CarMediaHiddenToggleInput = {
  carMediaId: Scalars['Int'];
};

export type CarMediaHiddenToggleOutput = {
  __typename?: 'CarMediaHiddenToggleOutput';
  error?: Maybe<MutationError>;
};

export type ChangeAdditionalTypeInput = {
  additionalTypeId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ChangeAdditionalTypeOutput = {
  __typename?: 'ChangeAdditionalTypeOutput';
  error?: Maybe<MutationError>;
};

export type ChangeDesiredTripInput = {
  objectId: Scalars['Int'];
  tripId: Scalars['Int'];
};

export type ChangeDesiredTripOutput = {
  __typename?: 'ChangeDesiredTripOutput';
  error?: Maybe<MutationError>;
};

export type ChangeExportPriceInput = {
  currencyId: Scalars['Int'];
  exportPrice: Scalars['Float'];
  objectId: Scalars['Int'];
};

export type ChangeExportPriceOutput = {
  __typename?: 'ChangeExportPriceOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectBrokerInput = {
  brokerAddress?: Maybe<Scalars['String']>;
  brokerContacts?: Maybe<Scalars['String']>;
  brokerId?: Maybe<Scalars['Int']>;
  brokerName?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
};

export type ChangeObjectBrokerOutput = {
  __typename?: 'ChangeObjectBrokerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectFormalOwnerInput = {
  formalOwnerId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ChangeObjectFormalOwnerOutput = {
  __typename?: 'ChangeObjectFormalOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectFreightCountryInput = {
  countryId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ChangeObjectFreightCountryOutput = {
  __typename?: 'ChangeObjectFreightCountryOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectOwnerInput = {
  objectId: Scalars['Int'];
  ownerId: Scalars['Int'];
};

export type ChangeObjectOwnerOutput = {
  __typename?: 'ChangeObjectOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectSenderInput = {
  objectId: Scalars['Int'];
  senderId: Scalars['Int'];
};

export type ChangeObjectSenderOutput = {
  __typename?: 'ChangeObjectSenderOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectTypeInput = {
  objectId: Scalars['Int'];
  objectTypeId: Scalars['Int'];
};

export type ChangeObjectTypeOutput = {
  __typename?: 'ChangeObjectTypeOutput';
  error?: Maybe<MutationError>;
};

export type ChangeObjectVinInput = {
  newVin: Scalars['String'];
  objectId: Scalars['Int'];
};

export type ChangeObjectVinOutput = {
  __typename?: 'ChangeObjectVinOutput';
  error?: Maybe<MutationError>;
};

export type ChangeParentObjectInput = {
  objectId: Scalars['Int'];
};

export type ChangeParentObjectOutput = {
  __typename?: 'ChangeParentObjectOutput';
  error?: Maybe<MutationError>;
};

export type ChangeSenderClientInput = {
  clientId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ChangeSenderClientOutput = {
  __typename?: 'ChangeSenderClientOutput';
  error?: Maybe<MutationError>;
};

export type ChatAddGroupUsersInput = {
  chatId: Scalars['Int'];
  groupId: Scalars['Int'];
};

export type ChatAddGroupUsersOutput = {
  __typename?: 'ChatAddGroupUsersOutput';
  error?: Maybe<MutationError>;
};

export type ChatAddUserInput = {
  chatId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChatAddUserOutput = {
  __typename?: 'ChatAddUserOutput';
  error?: Maybe<MutationError>;
};

export type ChatArchivingInput = {
  chatId: Scalars['Int'];
};

export type ChatArchivingOutput = {
  __typename?: 'ChatArchivingOutput';
  error?: Maybe<MutationError>;
};

export type CreateChatInput = {
  isTask: Scalars['Boolean'];
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectId?: Maybe<Scalars['Int']>;
  subject: Scalars['String'];
  toGroupsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  toUsersIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CreateChatOutput = {
  __typename?: 'CreateChatOutput';
  error?: Maybe<MutationError>;
};

export type ChatDeleteMessageInput = {
  messageId: Scalars['Int'];
};

export type ChatDeleteMessageOutput = {
  __typename?: 'ChatDeleteMessageOutput';
  error?: Maybe<MutationError>;
};

export type ChatRemoveUserInput = {
  id: Scalars['Int'];
};

export type ChatRemoveUserOutput = {
  __typename?: 'ChatRemoveUserOutput';
  error?: Maybe<MutationError>;
};

export type ChatUnArchivingInput = {
  chatId: Scalars['Int'];
};

export type ChatUnArchivingOutput = {
  __typename?: 'ChatUnArchivingOutput';
  error?: Maybe<MutationError>;
};

export type CheckDateOfManufactureInput = {
  objectId: Scalars['Int'];
};

export type CheckDateOfManufactureOutput = {
  __typename?: 'CheckDateOfManufactureOutput';
  error?: Maybe<MutationError>;
};

export type CompleteObjectInput = {
  objectId: Scalars['Int'];
};

export type CompleteObjectOutput = {
  __typename?: 'CompleteObjectOutput';
  error?: Maybe<MutationError>;
};

export type ContainerConfirmBookedInput = {
  containerId?: Maybe<Scalars['Int']>;
  keyS3File?: Maybe<Scalars['String']>;
};

export type ContainerConfirmBookedOutput = {
  __typename?: 'ContainerConfirmBookedOutput';
  error?: Maybe<MutationError>;
};

export type ContainerCreateInput = {
  dateOfLoading?: Maybe<Scalars['String']>;
  insurancePolicy?: Maybe<Scalars['String']>;
  insurancePolicyKeyS3?: Maybe<Array<Scalars['String']>>;
  number: Scalars['String'];
  objects?: Maybe<Array<Scalars['Int']>>;
};

export type ContainerCreateOutput = {
  __typename?: 'ContainerCreateOutput';
  error?: Maybe<MutationError>;
};

export type ContainerDeleteInput = {
  id: Scalars['Int'];
};

export type ContainerDeleteOutput = {
  __typename?: 'ContainerDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ContainerUpdateInput = {
  containerTypeId: Scalars['Int'];
  dateOfLoading?: Maybe<Scalars['DateTime']>;
  freightTypeId: Scalars['Int'];
  id: Scalars['Int'];
  insurancePolicy?: Maybe<Scalars['String']>;
  insurancePolicyKeyS3?: Maybe<Array<Scalars['String']>>;
  number?: Maybe<Scalars['String']>;
  seaCarrierId: Scalars['Int'];
};

export type ContainerUpdateOutput = {
  __typename?: 'ContainerUpdateOutput';
  error?: Maybe<MutationError>;
};

export type CountryCreateInput = {
  alfa2: Scalars['String'];
  code: Scalars['String'];
  fullName: Scalars['String'];
  name: Scalars['String'];
  nameEng: Scalars['String'];
};

export type CountryCreateOutput = {
  __typename?: 'CountryCreateOutput';
  error?: Maybe<MutationError>;
};

export type CreateDeliveryRequestInput = {
  carrierId?: Maybe<Scalars['Int']>;
  clientPrice?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['Int']>;
  deliveryPrice?: Maybe<Scalars['Int']>;
  destinationId: Scalars['Int'];
  objectId: Scalars['Int'];
  sourceId: Scalars['Int'];
};

export type CreateDeliveryRequestOutput = {
  __typename?: 'CreateDeliveryRequestOutput';
  error?: Maybe<MutationError>;
};

export type CreateInvoiceJapanAuctionPurchaseInput = {
  objectId: Scalars['Int'];
};

export type CreateInvoiceJapanAuctionPurchaseOutput = {
  __typename?: 'CreateInvoiceJapanAuctionPurchaseOutput';
  error?: Maybe<MutationError>;
};

export type CreateObjectActInput = {
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectId: Scalars['Int'];
  subject: Scalars['String'];
};

export type CreateObjectActOutput = {
  __typename?: 'CreateObjectActOutput';
  error?: Maybe<MutationError>;
};

export type CreateReceiptOfPaymentInput = {
  amount: Scalars['Int'];
  currencyId: Scalars['Int'];
  dateOfPayment: Scalars['String'];
  invoiceNumber: Scalars['String'];
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
  senderName: Scalars['String'];
};

export type CreateReceiptOfPaymentOutput = {
  __typename?: 'CreateReceiptOfPaymentOutput';
  error?: Maybe<MutationError>;
};

export type CreateSbktsRequestInput = {
  ambassador: Scalars['Int'];
  laboratory: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type CreateSbktsRequestOutput = {
  __typename?: 'CreateSbktsRequestOutput';
  error?: Maybe<MutationError>;
};

export type DeleteObjectInput = {
  objectId: Scalars['Int'];
};

export type DeleteObjectOutput = {
  __typename?: 'DeleteObjectOutput';
  error?: Maybe<MutationError>;
};

export type DeleteUserNotifyMessageInput = {
  channelId: Scalars['Int'];
};

export type DeleteUserNotifyMessageOutput = {
  __typename?: 'DeleteUserNotifyMessageOutput';
  error?: Maybe<MutationError>;
};

export type DeliveryToStockInput = {
  objectId: Scalars['Int'];
  sectorId?: Maybe<Scalars['Int']>;
  stockId: Scalars['Int'];
};

export type DeliveryToStockOutput = {
  __typename?: 'DeliveryToStockOutput';
  error?: Maybe<MutationError>;
};

export type DisallowVisiblePhotoInput = {
  objectId: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};

export type DisallowVisiblePhotoOutput = {
  __typename?: 'DisallowVisiblePhotoOutput';
  error?: Maybe<MutationError>;
};

export type DisplayBidsCheckInput = {
  agentId: Scalars['Int'];
};

export type DisplayBidsCheckOutput = {
  __typename?: 'DisplayBidsCheckOutput';
  error?: Maybe<MutationError>;
};

export type ExchangeJobCloseInput = {
  id: Scalars['Int'];
};

export type ExchangeJobCloseOutput = {
  __typename?: 'ExchangeJobCloseOutput';
  error?: Maybe<MutationError>;
};

export type ExchangeJobSendInput = {
  id: Scalars['Int'];
};

export type ExchangeJobSendOutput = {
  __typename?: 'ExchangeJobSendOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationAddObjectsInput = {
  id: Scalars['Int'];
  objectIds: Array<Scalars['Int']>;
};

export type ExportDeclarationAddObjectsOutput = {
  __typename?: 'ExportDeclarationAddObjectsOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationCreateInput = {
  bookingNo: Scalars['String'];
  objectIds?: Maybe<Array<Scalars['Int']>>;
  registrationDate: Scalars['DateTime'];
  tripId: Scalars['Int'];
};

export type ExportDeclarationCreateOutput = {
  __typename?: 'ExportDeclarationCreateOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationPaidInput = {
  id: Scalars['Int'];
  keyS3File: Scalars['String'];
  paidAt: Scalars['DateTime'];
};

export type ExportDeclarationPaidOutput = {
  __typename?: 'ExportDeclarationPaidOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationRemoveObjectInput = {
  id: Scalars['Int'];
  objectIds: Scalars['Int'];
};

export type ExportDeclarationRemoveObjectOutput = {
  __typename?: 'ExportDeclarationRemoveObjectOutput';
  error?: Maybe<MutationError>;
};

export type ExportDeclarationUpdateInput = {
  bookingNo: Scalars['String'];
  id: Scalars['Int'];
  registrationDate: Scalars['DateTime'];
  tripId: Scalars['Int'];
};

export type ExportDeclarationUpdateOutput = {
  __typename?: 'ExportDeclarationUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ExportToAuctionInput = {
  auctionId: Scalars['Int'];
  driverId?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
};

export type ExportToAuctionOutput = {
  __typename?: 'ExportToAuctionOutput';
  error?: Maybe<MutationError>;
};

export type FileDeleteInput = {
  fileId: Scalars['Int'];
};

export type FileDeleteOutput = {
  __typename?: 'FileDeleteOutput';
  error?: Maybe<MutationError>;
};

export type InvoiceByObjectsGenerateInput = {
  address?: Maybe<Scalars['String']>;
  advance?: Maybe<Scalars['Float']>;
  currencyId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameRus: Scalars['String'];
  objects: Array<Maybe<ObjectInvoiceItemInput>>;
  phone?: Maybe<Scalars['String']>;
  portName?: Maybe<Scalars['String']>;
  useAdvance?: Maybe<Scalars['Boolean']>;
};

export type ObjectInvoiceItemInput = {
  amount: Scalars['Float'];
  complexAmount: Scalars['Float'];
  freight: Scalars['Float'];
  id: Scalars['Int'];
};

export type InvoiceByObjectsGenerateOutput = {
  __typename?: 'InvoiceByObjectsGenerateOutput';
  error?: Maybe<MutationError>;
  invoice?: Maybe<Scalars['String']>;
};

export type InvoiceCustomGenerateInput = {
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currencyId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameRus: Scalars['String'];
  ownerId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  purposeMessage?: Maybe<Scalars['String']>;
  type: InvoiceCustomTypeEnum;
};

export type InvoiceCustomGenerateOutput = {
  __typename?: 'InvoiceCustomGenerateOutput';
  error?: Maybe<MutationError>;
  invoice?: Maybe<Scalars['String']>;
};

export type IssueObjectInput = {
  objectId: Scalars['Int'];
};

export type IssueObjectOutput = {
  __typename?: 'IssueObjectOutput';
  error?: Maybe<MutationError>;
};

export type KaitaiAutoResubmitChangeStateInput = {
  state: KaitaiAutoResubmitStateEnum;
};

export type KaitaiAutoResubmitChangeStateOutput = {
  __typename?: 'KaitaiAutoResubmitChangeStateOutput';
  error?: Maybe<MutationError>;
  state: KaitaiAutoResubmitStateEnum;
};

export type KaitaiQueueStatusChangeInput = {
  state: Scalars['Boolean'];
};

export type KaitaiQueueStatusChangeOutput = {
  __typename?: 'KaitaiQueueStatusChangeOutput';
  error?: Maybe<MutationError>;
  queueStatus?: Maybe<QueueStatus>;
};

export type MagadanObjectCreateInput = {
  attachments: Scalars['String'];
  carrierId?: Maybe<Scalars['Int']>;
  chassisTypeId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  countKeys: Scalars['Int'];
  hasSdCard?: Maybe<Scalars['Boolean']>;
  isArrived?: Maybe<Scalars['Boolean']>;
  isBroken?: Maybe<Scalars['Boolean']>;
  isNoneKeys?: Maybe<Scalars['Boolean']>;
  isNotDrivable?: Maybe<Scalars['Boolean']>;
  isRadCheck?: Maybe<Scalars['Boolean']>;
  makerId: Scalars['Int'];
  markId: Scalars['Int'];
  mediaKeysS3?: Maybe<Array<Maybe<Scalars['String']>>>;
  radiation: Scalars['String'];
  sawId?: Maybe<Scalars['Int']>;
  sectorId?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  vin: Scalars['String'];
};

export type MagadanObjectCreateOutput = {
  __typename?: 'MagadanObjectCreateOutput';
  error?: Maybe<MutationError>;
};

export type MagadanObjectUpdateInput = {
  attachments: Scalars['String'];
  carrierId?: Maybe<Scalars['Int']>;
  chassisTypeId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  countKeys: Scalars['Int'];
  hasSdCard?: Maybe<Scalars['Boolean']>;
  isArrived?: Maybe<Scalars['Boolean']>;
  isBroken?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isNoneKeys?: Maybe<Scalars['Boolean']>;
  isNotDrivable?: Maybe<Scalars['Boolean']>;
  isRadCheck?: Maybe<Scalars['Boolean']>;
  makerId: Scalars['Int'];
  markId: Scalars['Int'];
  mediaKeysS3?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectId: Scalars['Int'];
  radiation: Scalars['String'];
  sawId?: Maybe<Scalars['Int']>;
  sectorId?: Maybe<Scalars['Int']>;
  senderId: Scalars['Int'];
  vin: Scalars['String'];
};

export type MagadanObjectUpdateOutput = {
  __typename?: 'MagadanObjectUpdateOutput';
  error?: Maybe<MutationError>;
};

export type MakerCreateInput = {
  name: Scalars['String'];
};

export type MakerCreateOutput = {
  __typename?: 'MakerCreateOutput';
  error?: Maybe<MutationError>;
};

export type MarkCreateInput = {
  maker: Scalars['Int'];
  name: Scalars['String'];
};

export type MarkCreateOutput = {
  __typename?: 'MarkCreateOutput';
  error?: Maybe<MutationError>;
};

export type MoveMediaToObjectInput = {
  sourceId: Scalars['Int'];
  targetId: Scalars['Int'];
};

export type MoveMediaToObjectOutput = {
  __typename?: 'MoveMediaToObjectOutput';
  error?: Maybe<MutationError>;
};

export type ObjectAddPriorityPassInput = {
  objectId: Scalars['Int'];
};

export type ObjectAddPriorityPassOutput = {
  __typename?: 'ObjectAddPriorityPassOutput';
  error?: Maybe<MutationError>;
};

export type ObjectAddStatusBookingRequestInput = {
  objectId: Scalars['Int'];
};

export type ObjectAddStatusBookingRequestOutput = {
  __typename?: 'ObjectAddStatusBookingRequestOutput';
  error?: Maybe<MutationError>;
};

export type ObjectChangeDestinationCountryInput = {
  countryId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ObjectChangeDestinationCountryOutput = {
  __typename?: 'ObjectChangeDestinationCountryOutput';
  error?: Maybe<MutationError>;
};

export type ObjectChangeOwnerInput = {
  newOwnerId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ObjectChangeOwnerOutput = {
  __typename?: 'ObjectChangeOwnerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectChangeSawTypeInput = {
  objectId: Scalars['Int'];
  sawTypeId: Scalars['Int'];
};

export type ObjectChangeSawTypeOutput = {
  __typename?: 'ObjectChangeSawTypeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectCheckDebtsInput = {
  objectId: Scalars['Int'];
  organizationGuids?: Maybe<Array<OrganizationGuidEnum>>;
};

export enum OrganizationGuidEnum {
  AkebonoLtdGuid = 'AKEBONO_LTD_GUID',
  AkebonoLtdRGuid = 'AKEBONO_LTD_R_GUID',
  NavisShippingGuid = 'NAVIS_SHIPPING_GUID',
  NavisShippingJscGuid = 'NAVIS_SHIPPING_JSC_GUID',
  PrimbrokerGuid = 'PRIMBROKER_GUID'
}

export type ObjectCheckDebtsOutput = {
  __typename?: 'ObjectCheckDebtsOutput';
  error?: Maybe<MutationError>;
  isFinanceClear?: Maybe<Scalars['Boolean']>;
};

export type ObjectDeleteCustomDocumentsInput = {
  fileId?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
};

export type ObjectDeleteCustomDocumentsOutput = {
  __typename?: 'ObjectDeleteCustomDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectDeleteMediaInput = {
  imagesList: Array<Scalars['Int']>;
  objectId: Scalars['Int'];
};

export type ObjectDeleteMediaOutput = {
  __typename?: 'ObjectDeleteMediaOutput';
  error?: Maybe<MutationError>;
};

export type ObjectFeedbackCreateInput = {
  evaluation: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
};

export type ObjectFeedbackCreateOutput = {
  __typename?: 'ObjectFeedbackCreateOutput';
  error?: Maybe<MutationError>;
};

export type ObjectGreenCornerTgInput = {
  fewImages?: Maybe<Scalars['Boolean']>;
  objectId: Scalars['Int'];
};

export type ObjectGreenCornerTgOutput = {
  __typename?: 'ObjectGreenCornerTGOutput';
  error?: Maybe<MutationError>;
};

export type ObjectHoldToInput = {
  holdTo: Scalars['String'];
  objectId: Scalars['Int'];
};

export type ObjectHoldToOutput = {
  __typename?: 'ObjectHoldToOutput';
  error?: Maybe<MutationError>;
};

export type ObjectHoldToCloseInput = {
  objectId: Scalars['Int'];
};

export type ObjectHoldToCloseOutput = {
  __typename?: 'ObjectHoldToCloseOutput';
  error?: Maybe<MutationError>;
};

export type ObjectNeedVideoWatchInput = {
  comment: Scalars['String'];
  objectId: Scalars['Int'];
  type: VideoWatchTypeEnum;
};

export type ObjectNeedVideoWatchOutput = {
  __typename?: 'ObjectNeedVideoWatchOutput';
  error?: Maybe<MutationError>;
};

export type ObjectOfServiceRemoveFromContainerInput = {
  containerId: Scalars['Int'];
  objectId: Scalars['Int'];
};

export type ObjectOfServiceRemoveFromContainerOutput = {
  __typename?: 'ObjectOfServiceRemoveFromContainerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectRecipientPaymentEmailInput = {
  objectId: Scalars['Int'];
};

export type ObjectRecipientPaymentEmailOutput = {
  __typename?: 'ObjectRecipientPaymentEmailOutput';
  error?: Maybe<MutationError>;
};

export type ObjectRequestToParkingInput = {
  comment?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
};

export type ObjectRequestToParkingOutput = {
  __typename?: 'ObjectRequestToParkingOutput';
  error?: Maybe<MutationError>;
};

export type ObjectRequestToPreparationDocumentsInput = {
  objectId: Scalars['Int'];
};

export type ObjectRequestToPreparationDocumentsOutput = {
  __typename?: 'ObjectRequestToPreparationDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectResetInput = {
  objectId: Scalars['Int'];
  reason: Scalars['String'];
};

export type ObjectResetOutput = {
  __typename?: 'ObjectResetOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSendInvoiceEmailInput = {
  guid: Scalars['String'];
  types: Array<Maybe<InvoiceEmailTypeEnum>>;
};

export enum InvoiceEmailTypeEnum {
  Accountant = 'ACCOUNTANT',
  Client = 'CLIENT'
}

export type ObjectSendInvoiceEmailOutput = {
  __typename?: 'ObjectSendInvoiceEmailOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetDateDismantleForCubeInput = {
  date: Scalars['String'];
  objectId: Scalars['Int'];
};

export type ObjectSetDateDismantleForCubeOutput = {
  __typename?: 'ObjectSetDateDismantleForCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetDateRecyclingToCubeInput = {
  date: Scalars['String'];
  objectId: Scalars['Int'];
};

export type ObjectSetDateRecyclingToCubeOutput = {
  __typename?: 'ObjectSetDateRecyclingToCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetDateSoldRecyclingCubeInput = {
  date: Scalars['String'];
  objectId: Scalars['Int'];
};

export type ObjectSetDateSoldRecyclingCubeOutput = {
  __typename?: 'ObjectSetDateSoldRecyclingCubeOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetGuidFrom1cInput = {
  objectId: Scalars['Int'];
};

export type ObjectSetGuidFrom1cOutput = {
  __typename?: 'ObjectSetGuidFrom1cOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetIsGeneralBasisAdvanceInput = {
  objectId: Scalars['Int'];
};

export type ObjectSetIsGeneralBasisAdvanceOutput = {
  __typename?: 'ObjectSetIsGeneralBasisAdvanceOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetIsGreenCornerInput = {
  objectId: Scalars['Int'];
};

export type ObjectSetIsGreenCornerOutput = {
  __typename?: 'ObjectSetIsGreenCornerOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetKaitaiPriceInput = {
  objectId: Scalars['Int'];
  price: Scalars['Int'];
};

export type ObjectSetKaitaiPriceOutput = {
  __typename?: 'ObjectSetKaitaiPriceOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetMasterAccountClientInput = {
  clientId: Scalars['DateTime'];
  objectId: Scalars['Int'];
};

export type ObjectSetMasterAccountClientOutput = {
  __typename?: 'ObjectSetMasterAccountClientOutput';
  error?: Maybe<MutationError>;
};

export type ObjectSetSoldDateInput = {
  objectId: Scalars['Int'];
  soldDate: Scalars['DateTime'];
};

export type ObjectSetSoldDateOutput = {
  __typename?: 'ObjectSetSoldDateOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadCustomDocumentsInput = {
  agreementKeyS3File?: Maybe<Scalars['String']>;
  boardingPassesKeyS3Files?: Maybe<Array<Maybe<Scalars['String']>>>;
  certificateFromHotelKeyS3Files?: Maybe<Array<Maybe<Scalars['String']>>>;
  comeToCustoms?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  explanatoryKeyS3File?: Maybe<Array<Maybe<Scalars['String']>>>;
  explanatoryNoDVFOKeyS3File?: Maybe<Array<Maybe<Scalars['String']>>>;
  innKeyS3File?: Maybe<Scalars['String']>;
  invoiceCreateDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceKeyS3Files?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  passportKeyS3Files?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiptKeyS3File?: Maybe<Array<Maybe<Scalars['String']>>>;
  tempRegistrationKeyS3File?: Maybe<Scalars['String']>;
  validationTempRegistrationS3File?: Maybe<Scalars['String']>;
};

export type ObjectUploadCustomDocumentsOutput = {
  __typename?: 'ObjectUploadCustomDocumentsOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadCustomPaymentInput = {
  keyS3Files: Array<Maybe<Scalars['String']>>;
  objectId: Scalars['Int'];
};

export type ObjectUploadCustomPaymentOutput = {
  __typename?: 'ObjectUploadCustomPaymentOutput';
  error?: Maybe<MutationError>;
};

export type ObjectUploadFilesInput = {
  isFile: Scalars['Boolean'];
  keyS3Files: Array<Scalars['String']>;
  mediaType: MediaTypeEnum;
  objectId: Scalars['Int'];
};

export type ObjectUploadFilesOutput = {
  __typename?: 'ObjectUploadFilesOutput';
  error?: Maybe<MutationError>;
};

export type GetAgentsByParamsInput = {
  email?: Maybe<Scalars['String']>;
  fio: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type GetAgentsByParamsOutput = {
  __typename?: 'GetAgentsByParamsOutput';
  agents?: Maybe<Array<OwnerWithAgreements>>;
  error?: Maybe<MutationError>;
};

export type OwnerWithAgreements = {
  __typename?: 'OwnerWithAgreements';
  agent?: Maybe<Owner>;
  agreementsList?: Maybe<Array<OwnerAgreement>>;
  guidClient?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OwnerAgreement = {
  __typename?: 'OwnerAgreement';
  nameAgreement?: Maybe<Scalars['String']>;
  nameCurrency?: Maybe<Scalars['String']>;
  nameOrganization?: Maybe<Scalars['String']>;
  sumActive?: Maybe<Scalars['Float']>;
  sumBalance?: Maybe<Scalars['Float']>;
  sumPassive?: Maybe<Scalars['Float']>;
};

export type PalletAddWheelInput = {
  objectsId: Array<Maybe<Scalars['Int']>>;
  palletNumber: Scalars['String'];
};

export type PalletAddWheelOutput = {
  __typename?: 'PalletAddWheelOutput';
  error?: Maybe<MutationError>;
};

export type PalletCreateInput = {
  name?: Maybe<Scalars['String']>;
};

export type PalletCreateOutput = {
  __typename?: 'PalletCreateOutput';
  error?: Maybe<MutationError>;
};

export type PalletToPlanInput = {
  palletNumber: Scalars['String'];
  tripId: Scalars['Int'];
};

export type PalletToPlanOutput = {
  __typename?: 'PalletToPlanOutput';
  error?: Maybe<MutationError>;
};

export type PalletToPortInput = {
  palletNumber: Scalars['String'];
};

export type PalletToPortOutput = {
  __typename?: 'PalletToPortOutput';
  error?: Maybe<MutationError>;
  failedObjects?: Maybe<Array<ObjectOfServices>>;
};

export type PbAgreementSendedInput = {
  objectId: Scalars['String'];
};

export type PbAgreementSendedOutput = {
  __typename?: 'PbAgreementSendedOutput';
  error?: Maybe<MutationError>;
};

export type PickupAppointmentInput = {
  comment?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  plannedAt: Scalars['String'];
};

export type PickupAppointmentOutput = {
  __typename?: 'PickupAppointmentOutput';
  error?: Maybe<MutationError>;
};

export type PlanOnVesselTypeInput = {
  objectId: Scalars['Int'];
  vesselTypeId: Scalars['Int'];
};

export type PlanOnVesselTypeOutput = {
  __typename?: 'PlanOnVesselTypeOutput';
  error?: Maybe<MutationError>;
};

export type PortCreateInput = {
  countryId: Scalars['Int'];
  name: Scalars['String'];
};

export type PortCreateOutput = {
  __typename?: 'PortCreateOutput';
  error?: Maybe<MutationError>;
};

export type PreSignedPostCreateInput = {
  bucketType?: Maybe<BucketTypeEnum>;
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  logicFileType?: Maybe<LogicFileTypeEnum>;
};

export enum BucketTypeEnum {
  File = 'FILE',
  Image = 'IMAGE',
  Junk = 'JUNK',
  Video = 'VIDEO'
}

export enum LogicFileTypeEnum {
  FeedbackAttachment = 'FEEDBACK_ATTACHMENT'
}

export type PreSignedPostCreateOutput = {
  __typename?: 'PreSignedPostCreateOutput';
  error?: Maybe<MutationError>;
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PutUpForSaleCreateInput = {
  objectId: Scalars['Int'];
  price: Scalars['Int'];
};

export type PutUpForSaleCreateOutput = {
  __typename?: 'PutUpForSaleCreateOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleSoldOutInput = {
  objectId: Scalars['Int'];
};

export type PutUpForSaleSoldOutOutput = {
  __typename?: 'PutUpForSaleSoldOutOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleUpdateInput = {
  objectId: Scalars['Int'];
  price: Scalars['Int'];
};

export type PutUpForSaleUpdateOutput = {
  __typename?: 'PutUpForSaleUpdateOutput';
  error?: Maybe<MutationError>;
};

export type PutUpForSaleWithdrawInput = {
  objectId: Scalars['Int'];
};

export type PutUpForSaleWithdrawOutput = {
  __typename?: 'PutUpForSaleWithdrawOutput';
  error?: Maybe<MutationError>;
};

export type ReminderRemoveInput = {
  id: Scalars['Int'];
};

export type ReminderRemoveOutput = {
  __typename?: 'ReminderRemoveOutput';
  error?: Maybe<MutationError>;
};

export type RemoveFromStockInput = {
  objectId: Scalars['Int'];
  reason: Scalars['String'];
};

export type RemoveFromStockOutput = {
  __typename?: 'RemoveFromStockOutput';
  error?: Maybe<MutationError>;
};

export type RemoveObjectFromPlanInput = {
  objectId: Scalars['Int'];
  reasonId: Scalars['Int'];
};

export type RemoveObjectFromPlanOutput = {
  __typename?: 'RemoveObjectFromPlanOutput';
  error?: Maybe<MutationError>;
};

export type RenewExportCertificateInput = {
  exportCertificateId: Scalars['Int'];
  scheduledDate: Scalars['String'];
};

export type RenewExportCertificateOutput = {
  __typename?: 'RenewExportCertificateOutput';
  error?: Maybe<MutationError>;
};

export type ReplaceDoubleInput = {
  doubleId: Scalars['Int'];
  originalId: Scalars['Int'];
};

export type ReplaceDoubleOutput = {
  __typename?: 'ReplaceDoubleOutput';
  error?: Maybe<MutationError>;
};

export type ReplaceToStockInput = {
  objectId: Scalars['Int'];
  sectorId?: Maybe<Scalars['Int']>;
  stockId: Scalars['Int'];
};

export type ReplaceToStockOutput = {
  __typename?: 'ReplaceToStockOutput';
  error?: Maybe<MutationError>;
};

export type RequestPhotoFromAuctionInput = {
  comment: Scalars['String'];
  lotData: Scalars['String'];
  lotLink: Scalars['String'];
  type: VideoWatchTypeEnum;
};

export type RequestPhotoFromAuctionOutput = {
  __typename?: 'RequestPhotoFromAuctionOutput';
  error?: Maybe<MutationError>;
};

export type RequestPrimbrokerInvoiceInput = {
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectId?: Maybe<Scalars['Int']>;
};

export type RequestPrimbrokerInvoiceOutput = {
  __typename?: 'RequestPrimbrokerInvoiceOutput';
  error?: Maybe<MutationError>;
};

export type RequestSbktsCreateInput = {
  agent?: Maybe<RequestSbktsAgentInput>;
  agentLegal?: Maybe<RequestSbktsAgentLegalInput>;
  ambassador: Scalars['Int'];
  customer: Scalars['Int'];
  laboratory: Scalars['Int'];
  object: RequestSbktsObjectInput;
  objectId: Scalars['Int'];
};

export type RequestSbktsAgentInput = {
  fullAddress?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  issuedAtPassport: Scalars['String'];
  issuedUnit: Scalars['String'];
  issuedUnitName: Scalars['String'];
  name: Scalars['String'];
  passportDocuments?: Maybe<Array<Scalars['String']>>;
  passportNumber: Scalars['String'];
  passportSeries: Scalars['String'];
  snils?: Maybe<Scalars['String']>;
};

export type RequestSbktsAgentLegalInput = {
  bank: Scalars['String'];
  bik: Scalars['String'];
  kpp: Scalars['String'];
  ks: Scalars['String'];
  name: Scalars['String'];
  ogrn: Scalars['String'];
  rs: Scalars['String'];
};

export type RequestSbktsObjectInput = {
  color: Scalars['Int'];
  consignment?: Maybe<Array<Scalars['String']>>;
  dateOfManufacture: Scalars['String'];
  driveType?: Maybe<Scalars['String']>;
  engineModel?: Maybe<Scalars['String']>;
  engineNumber: Scalars['String'];
  enginePower: Scalars['Float'];
  engineVolume?: Maybe<Scalars['Float']>;
  glonassDocument?: Maybe<Array<Scalars['String']>>;
  glonassICCID?: Maybe<Scalars['String']>;
  glonassModel?: Maybe<Scalars['String']>;
  markName: Scalars['String'];
  maxWeight: Scalars['Int'];
  modelName: Scalars['String'];
  needEngineWatch?: Maybe<Scalars['Boolean']>;
  ownWeight: Scalars['Int'];
  seats?: Maybe<Scalars['Int']>;
};

export type RequestSbktsCreateOutput = {
  __typename?: 'RequestSbktsCreateOutput';
  error?: Maybe<MutationError>;
};

export type RequestSbktsFromExcelCreateInput = {
  agent?: Maybe<RequestSbktsExcelAgentInput>;
  agentLegal?: Maybe<RequestSbktsExcelAgentLegalInput>;
  ambassador: Scalars['Int'];
  customer: Scalars['Int'];
  laboratory: Scalars['Int'];
  object?: Maybe<RequestSbktsExcelInput>;
  objectId?: Maybe<Scalars['Int']>;
};

export type RequestSbktsExcelAgentInput = {
  fullAddress?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  innDocuments?: Maybe<Array<Scalars['String']>>;
  issuedAtPassport?: Maybe<Scalars['String']>;
  issuedUnit?: Maybe<Scalars['String']>;
  issuedUnitName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ogrnDocuments?: Maybe<Array<Scalars['String']>>;
  passportDocuments?: Maybe<Array<Scalars['String']>>;
  passportNumber?: Maybe<Scalars['String']>;
  passportSeries?: Maybe<Scalars['String']>;
  residentFEFD?: Maybe<Scalars['Boolean']>;
  snils?: Maybe<Scalars['String']>;
};

export type RequestSbktsExcelAgentLegalInput = {
  bank?: Maybe<Scalars['String']>;
  bik?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  ks?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ogrn?: Maybe<Scalars['String']>;
  rs?: Maybe<Scalars['String']>;
};

export type RequestSbktsExcelInput = {
  color?: Maybe<Scalars['Int']>;
  consignment?: Maybe<Array<Scalars['String']>>;
  dateOfManufacture?: Maybe<Scalars['String']>;
  driveType?: Maybe<Scalars['String']>;
  enableGlonass?: Maybe<Scalars['Boolean']>;
  engineModel?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  enginePower?: Maybe<Scalars['Float']>;
  engineType?: Maybe<Scalars['Int']>;
  engineVolume?: Maybe<Scalars['Float']>;
  glonassDocument?: Maybe<Array<Scalars['String']>>;
  glonassICCID?: Maybe<Scalars['String']>;
  glonassModel?: Maybe<Scalars['String']>;
  markName: Scalars['String'];
  maxWeight?: Maybe<Scalars['Int']>;
  modelName: Scalars['String'];
  needEngineWatch?: Maybe<Scalars['Boolean']>;
  ownWeight?: Maybe<Scalars['Int']>;
  seats?: Maybe<Scalars['Int']>;
  tpo?: Maybe<Array<Scalars['String']>>;
};

export type RequestSbktsFromExcelCreateOutput = {
  __typename?: 'RequestSbktsFromExcelCreateOutput';
  agentUpdateError?: Maybe<Scalars['String']>;
  error?: Maybe<MutationError>;
  fields?: Maybe<Array<RequestSbktsExcelFixField>>;
  objectUpdateError?: Maybe<Scalars['String']>;
  sbktsError?: Maybe<Scalars['String']>;
};

export type RequestSbktsExcelFixField = {
  __typename?: 'RequestSbktsExcelFixField';
  errorMessage?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
};

export type RequestToIssueInput = {
  comment?: Maybe<Scalars['String']>;
  dateIssue: Scalars['String'];
  objectId: Scalars['Int'];
};

export type RequestToIssueOutput = {
  __typename?: 'RequestToIssueOutput';
  error?: Maybe<MutationError>;
};

export type RequestUnHoldInput = {
  requestId: Scalars['Int'];
};

export type RequestUnHoldOutput = {
  __typename?: 'RequestUnHoldOutput';
  error?: Maybe<MutationError>;
};

export type RestoreObjectInput = {
  objectId: Scalars['Int'];
};

export type RestoreObjectOutput = {
  __typename?: 'RestoreObjectOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectCommentAddInput = {
  comment: Scalars['String'];
  id: Scalars['Int'];
};

export type ScrapObjectCommentAddOutput = {
  __typename?: 'ScrapObjectCommentAddOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectCreateInput = {
  /** Номер */
  aggregateNumber?: Maybe<Scalars['String']>;
  basket?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  lotSize: Scalars['String'];
  /** Количество занимаемых ячеек */
  lotSizeMultiplier?: Maybe<Scalars['Int']>;
  objectId: Scalars['Int'];
  place?: Maybe<Scalars['String']>;
  /** Тип запчасти */
  scrapTypeId: Scalars['Int'];
  /** Состояние запчасти */
  status: YahooStateLotEnum;
};

export type ScrapObjectCreateOutput = {
  __typename?: 'ScrapObjectCreateOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectDeliverySetInput = {
  address: Scalars['String'];
  carrier: Scalars['String'];
  id: Scalars['Int'];
  trackNumber?: Maybe<Scalars['String']>;
};

export type ScrapObjectDeliverySetOutput = {
  __typename?: 'ScrapObjectDeliverySetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectsDiscountToYahooInput = {
  objectsIds: Array<Maybe<Scalars['Int']>>;
};

export type ScrapObjectsDiscountToYahooOutput = {
  __typename?: 'ScrapObjectsDiscountToYahooOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectLotCancelInput = {
  id: Scalars['Int'];
};

export type ScrapObjectLotCancelOutput = {
  __typename?: 'ScrapObjectLotCancelOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectPlaceSetInput = {
  basket?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  place: Scalars['String'];
};

export type ScrapObjectPlaceSetOutput = {
  __typename?: 'ScrapObjectPlaceSetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectRebindInput = {
  parentObjectId: Scalars['Int'];
  scrapObjectId: Scalars['Int'];
};

export type ScrapObjectRebindOutput = {
  __typename?: 'ScrapObjectRebindOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectSizeSetInput = {
  id: Scalars['Int'];
  lotSize: Scalars['String'];
  lotSizeMultiplier?: Maybe<Scalars['Int']>;
};

export type ScrapObjectSizeSetOutput = {
  __typename?: 'ScrapObjectSizeSetOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectStatusSetInput = {
  objectId: Scalars['Int'];
  statusId: Scalars['Int'];
};

export type ScrapObjectStatusSetOutput = {
  __typename?: 'ScrapObjectStatusSetOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectToYahooInput = {
  amount: Scalars['Int'];
  autoExtension: Scalars['Boolean'];
  buyoutPrice?: Maybe<Scalars['Int']>;
  categoryCode: Scalars['String'];
  categoryId: Scalars['String'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  endTime: Scalars['Int'];
  id: Scalars['Int'];
  imageIds: Array<Scalars['Int']>;
  markdownRatio?: Maybe<YahooLotResubmitRatioEnum>;
  numResubmit?: Maybe<Scalars['Int']>;
  shipnames: Array<YahooLotDeliveryServiceEnum>;
  shipping: YahooLotShippingEnum;
  shippinginput: YahooLotShippingInputEnum;
  shipschedule: YahooLotShipScheduleEnum;
  startPrice: Scalars['Int'];
  status: YahooStateLotEnum;
  title: Scalars['String'];
  yahooAccount: YahooAccountEnum;
};

export enum YahooLotDeliveryServiceEnum {
  Other = 'other',
  Sagawa = 'sagawa',
  Seino = 'seino',
  Yamato = 'yamato',
  YuMail = 'yu_mail',
  YuPack = 'yu_pack'
}

export enum YahooLotShippingEnum {
  Buyer = 'buyer',
  Seller = 'seller'
}

export enum YahooLotShipScheduleEnum {
  OneTwo = 'one_two',
  ThreeSeven = 'three_seven',
  TwoThree = 'two_three'
}

export enum YahooAccountEnum {
  Akebono429 = 'akebono429',
  Eoosh66991 = 'eoosh66991',
  Ikyib99865 = 'ikyib99865',
  Magadanshop = 'magadanshop'
}

export type ScrapObjectToYahooOutput = {
  __typename?: 'ScrapObjectToYahooOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectUnbindInput = {
  objectId: Scalars['Int'];
};

export type ScrapObjectUnbindOutput = {
  __typename?: 'ScrapObjectUnbindOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectUpdateInput = {
  aggregateNumber?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  scrapTypeId: Scalars['Int'];
};

export type ScrapObjectUpdateOutput = {
  __typename?: 'ScrapObjectUpdateOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapObjectUploadShippingInvoiceInput = {
  id: Scalars['Int'];
  keyS3Files: Array<Maybe<Scalars['String']>>;
};

export type ScrapObjectUploadShippingInvoiceOutput = {
  __typename?: 'ScrapObjectUploadShippingInvoiceOutput';
  error?: Maybe<MutationError>;
};

export type ScrapObjectWriteOffInput = {
  objectId: Scalars['Int'];
};

export type ScrapObjectWriteOffOutput = {
  __typename?: 'ScrapObjectWriteOffOutput';
  error?: Maybe<MutationError>;
  scrapObject?: Maybe<ObjectOfServices>;
};

export type ScrapTypeCreateInput = {
  aggregateTypeId?: Maybe<Scalars['Int']>;
  carTypeId: Scalars['Int'];
  costInPercent?: Maybe<Scalars['Int']>;
  countObjects?: Maybe<Scalars['Int']>;
  descriptionId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nameEn: Scalars['String'];
  nameJp: Scalars['String'];
};

export type ScrapTypeCreateOutput = {
  __typename?: 'ScrapTypeCreateOutput';
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionCreateInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ScrapTypeDescriptionCreateOutput = {
  __typename?: 'ScrapTypeDescriptionCreateOutput';
  description?: Maybe<ScrapTypeDescription>;
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionDeleteInput = {
  id: Scalars['Int'];
};

export type ScrapTypeDescriptionDeleteOutput = {
  __typename?: 'ScrapTypeDescriptionDeleteOutput';
  error?: Maybe<MutationError>;
};

export type ScrapTypeDescriptionUpdateInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ScrapTypeDescriptionUpdateOutput = {
  __typename?: 'ScrapTypeDescriptionUpdateOutput';
  description?: Maybe<ScrapTypeDescription>;
  error?: Maybe<MutationError>;
};

export type ScrapTypeUpdateInput = {
  aggregateTypeId?: Maybe<Scalars['Int']>;
  carTypeId: Scalars['Int'];
  costInPercent?: Maybe<Scalars['Int']>;
  countObjects?: Maybe<Scalars['Int']>;
  descriptionId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  nameEn: Scalars['String'];
  nameJp: Scalars['String'];
};

export type ScrapTypeUpdateOutput = {
  __typename?: 'ScrapTypeUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ScrapUtilizedInput = {
  id: Scalars['Int'];
};

export type ScrapUtilizedOutput = {
  __typename?: 'ScrapUtilizedOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrierCreateInput = {
  name: Scalars['String'];
};

export type SeaCarrierCreateOutput = {
  __typename?: 'SeaCarrierCreateOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrierDeleteInput = {
  id: Scalars['Int'];
};

export type SeaCarrierDeleteOutput = {
  __typename?: 'SeaCarrierDeleteOutput';
  error?: Maybe<MutationError>;
};

export type SeaCarrierEditInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SeaCarrierEditOutput = {
  __typename?: 'SeaCarrierEditOutput';
  error?: Maybe<MutationError>;
};

export type SendAllClientsNotificationInput = {
  message: Scalars['String'];
};

export type SendAllClientsNotificationOutput = {
  __typename?: 'SendAllClientsNotificationOutput';
  error?: Maybe<MutationError>;
};

export type SendDirectorFeedbackInput = {
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  text: Scalars['String'];
};

export type SendDirectorFeedbackOutput = {
  __typename?: 'SendDirectorFeedbackOutput';
  error?: Maybe<MutationError>;
};

export type SendFeedbackInput = {
  email?: Maybe<Scalars['String']>;
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SendFeedbackOutput = {
  __typename?: 'SendFeedbackOutput';
  error?: Maybe<MutationError>;
};

export type SendNotificationEmailInput = {
  notificationKey: EmailObjectNotificationEnum;
  objectId: Scalars['Int'];
};

export enum EmailObjectNotificationEnum {
  /** Предоставьте паспорт с пропиской */
  GetPassportWithRegistration = 'GET_PASSPORT_WITH_REGISTRATION',
  /** Неверная регистрация физлица в СЭП */
  WrongEpsRegistration = 'WRONG_EPS_REGISTRATION'
}

export type SendNotificationEmailOutput = {
  __typename?: 'SendNotificationEmailOutput';
  error?: Maybe<MutationError>;
};

export type SendPaymentInfoInput = {
  keyS3Files: Array<Scalars['String']>;
  paymentId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type SendPaymentInfoOutput = {
  __typename?: 'SendPaymentInfoOutput';
  error?: Maybe<MutationError>;
};

export type SendReportAkebonoPaymentInput = {
  amount: Scalars['String'];
  currencyId: Scalars['Int'];
  invoiceNumber: Scalars['String'];
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
  senderName: Scalars['String'];
  transferDate: Scalars['String'];
};

export type SendReportAkebonoPaymentOutput = {
  __typename?: 'SendReportAkebonoPaymentOutput';
  error?: Maybe<MutationError>;
};

export type SendRussianDeliveryRequestInput = {
  address: Scalars['String'];
  contact: Scalars['String'];
  fileBase64?: Maybe<Scalars['String']>;
  objectId: Scalars['Int'];
  text: Scalars['String'];
};

export type SendRussianDeliveryRequestOutput = {
  __typename?: 'SendRussianDeliveryRequestOutput';
  error?: Maybe<MutationError>;
};

export type ServiceRequestCanceledInput = {
  id: Scalars['Int'];
};

export type ServiceRequestCanceledOutput = {
  __typename?: 'ServiceRequestCanceledOutput';
  error?: Maybe<MutationError>;
};

export type ServiceRequestCompletedInput = {
  id: Scalars['Int'];
  keyS3Files?: Maybe<Array<Scalars['String']>>;
};

export type ServiceRequestCompletedOutput = {
  __typename?: 'ServiceRequestCompletedOutput';
  error?: Maybe<MutationError>;
};

export type SetBoundaryInput = {
  boundaryDate: Scalars['String'];
  objectId: Scalars['Int'];
};

export type SetBoundaryOutput = {
  __typename?: 'SetBoundaryOutput';
  error?: Maybe<MutationError>;
};

export type SetIsGlonassNeedInput = {
  objectId: Scalars['Int'];
};

export type SetIsGlonassNeedOutput = {
  __typename?: 'SetIsGlonassNeedOutput';
  error?: Maybe<MutationError>;
};

export type SortObjectMediaInput = {
  imageIdsArray: Array<Maybe<Scalars['Int']>>;
};

export type SortObjectMediaOutput = {
  __typename?: 'SortObjectMediaOutput';
  error?: Maybe<MutationError>;
};

export type StorageCreateInput = {
  countryId: Scalars['Int'];
  name: Scalars['String'];
};

export type StorageCreateOutput = {
  __typename?: 'StorageCreateOutput';
  error?: Maybe<MutationError>;
};

export type StorageUpdateInput = {
  /** Id склада */
  id: Scalars['Int'];
  /** Уведомлять клиента о прибытии объекта */
  notificationClient: Scalars['Boolean'];
  /** Уведомлять менеджера о прибытии объекта */
  notificationManager: Scalars['Boolean'];
  /** Стоимость одного дня хранения (Иен) */
  storageCost?: Maybe<Scalars['Int']>;
  /** Количество дней бесплатного хранения */
  storageFreeDays?: Maybe<Scalars['Int']>;
};

export type StorageUpdateOutput = {
  __typename?: 'StorageUpdateOutput';
  error?: Maybe<MutationError>;
};

export type StorageSectorCreateInput = {
  capacity: Scalars['Int'];
  name: Scalars['String'];
  nameEng: Scalars['String'];
  order: Scalars['Int'];
  stockId: Scalars['Int'];
};

export type StorageSectorCreateOutput = {
  __typename?: 'StorageSectorCreateOutput';
  error?: Maybe<MutationError>;
};

export type StorageSectorEditInput = {
  capacity: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  nameEng: Scalars['String'];
  order: Scalars['Int'];
  sectorId: Scalars['Int'];
  stockId: Scalars['Int'];
};

export type StorageSectorEditOutput = {
  __typename?: 'StorageSectorEditOutput';
  error?: Maybe<MutationError>;
};

export type SubscribeUserToTelegramInput = {
  botId: Scalars['Int'];
};

export type SubscribeUserToTelegramOutput = {
  __typename?: 'SubscribeUserToTelegramOutput';
  error?: Maybe<MutationError>;
  uuid: Scalars['String'];
};

export type SyncObjectInput = {
  objectId: Scalars['Int'];
};

export type SyncObjectOutput = {
  __typename?: 'SyncObjectOutput';
  error?: Maybe<MutationError>;
};

export type TicketAddReviewInput = {
  message: Scalars['String'];
  rating?: Maybe<Scalars['Float']>;
  ticketId: Scalars['Int'];
};

export type TicketAddReviewOutput = {
  __typename?: 'TicketAddReviewOutput';
  error?: Maybe<MutationError>;
};

export type TicketAddUserInput = {
  ticketId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TicketAddUserOutput = {
  __typename?: 'TicketAddUserOutput';
  error?: Maybe<MutationError>;
};

export type TicketChangeSubTypeInput = {
  ticketId: Scalars['Int'];
  typeId?: Maybe<Scalars['Int']>;
};

export type TicketChangeSubTypeOutput = {
  __typename?: 'TicketChangeSubTypeOutput';
  error?: Maybe<MutationError>;
};

export type TicketChangeTypeInput = {
  ticketId: Scalars['Int'];
  typeId: Scalars['Int'];
};

export type TicketChangeTypeOutput = {
  __typename?: 'TicketChangeTypeOutput';
  error?: Maybe<MutationError>;
};

export type TicketCreateInput = {
  keyS3Files?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectId: Scalars['Int'];
  requestAmount?: Maybe<Scalars['Int']>;
  ticketTypeId: Scalars['Int'];
};

export type TicketCreateOutput = {
  __typename?: 'TicketCreateOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionInput = {
  decisions: Array<TicketDecisionItemInput>;
  innerReason?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  ticketId: Scalars['Int'];
};

export type TicketDecisionItemInput = {
  amount?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  organizationId: Scalars['Int'];
};

export type TicketDecisionOutput = {
  __typename?: 'TicketDecisionOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionAddInput = {
  decisions: Array<TicketDecisionItemInput>;
  ticketId: Scalars['Int'];
};

export type TicketDecisionAddOutput = {
  __typename?: 'TicketDecisionAddOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionAppealInput = {
  decisionId: Scalars['Int'];
  keyS3Files?: Maybe<Array<Maybe<Scalars['String']>>>;
  message: Scalars['String'];
};

export type TicketDecisionAppealOutput = {
  __typename?: 'TicketDecisionAppealOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionApproveInput = {
  ticketId: Scalars['Int'];
};

export type TicketDecisionApproveOutput = {
  __typename?: 'TicketDecisionApproveOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionApproveOrganizationInput = {
  decisions: Array<Maybe<TicketApproveItemInput>>;
  ticketId: Scalars['Int'];
};

export type TicketApproveItemInput = {
  comment?: Maybe<Scalars['String']>;
  compensationAmount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  decisionId: Scalars['Int'];
  guiltyMessage: Scalars['String'];
  isCompensatedByThirdParty?: Maybe<Scalars['Boolean']>;
  isPayOrganization?: Maybe<Scalars['Boolean']>;
};

export type TicketDecisionApproveOrganizationOutput = {
  __typename?: 'TicketDecisionApproveOrganizationOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionCancelInput = {
  decisionId: Scalars['Int'];
  message: Scalars['String'];
};

export type TicketDecisionCancelOutput = {
  __typename?: 'TicketDecisionCancelOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionCancelOnlyAdminInput = {
  decisionId: Scalars['Int'];
};

export type TicketDecisionCancelOnlyAdminOutput = {
  __typename?: 'TicketDecisionCancelOnlyAdminOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionPayoutInput = {
  message: Scalars['String'];
  ticketId: Scalars['Int'];
};

export type TicketDecisionPayoutOutput = {
  __typename?: 'TicketDecisionPayoutOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionResetPayoutInput = {
  decisionId: Scalars['Int'];
};

export type TicketDecisionResetPayoutOutput = {
  __typename?: 'TicketDecisionResetPayoutOutput';
  error?: Maybe<MutationError>;
};

export type TicketDecisionUpdateInput = {
  amount: Scalars['String'];
  currencyId?: Maybe<Scalars['Int']>;
  decisionId: Scalars['Int'];
};

export type TicketDecisionUpdateOutput = {
  __typename?: 'TicketDecisionUpdateOutput';
  error?: Maybe<MutationError>;
};

export type TicketDeclineInput = {
  innerReason: Scalars['String'];
  reason: Scalars['String'];
  ticketId: Scalars['Int'];
};

export type TicketDeclineOutput = {
  __typename?: 'TicketDeclineOutput';
  error?: Maybe<MutationError>;
};

export type TicketRemoveUserInput = {
  ticketId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TicketRemoveUserOutput = {
  __typename?: 'TicketRemoveUserOutput';
  error?: Maybe<MutationError>;
};

export type TicketResetInput = {
  ticketId: Scalars['Int'];
};

export type TicketResetOutput = {
  __typename?: 'TicketResetOutput';
  error?: Maybe<MutationError>;
};

export type TicketSilentCloseInput = {
  ticketId: Scalars['Int'];
};

export type TicketSilentCloseOutput = {
  __typename?: 'TicketSilentCloseOutput';
  error?: Maybe<MutationError>;
};

export type ToggleUserNotifyMessageInput = {
  channelId: Scalars['Int'];
  messageTypeId: Scalars['Int'];
};

export type ToggleUserNotifyMessageOutput = {
  __typename?: 'ToggleUserNotifyMessageOutput';
  error?: Maybe<MutationError>;
};

export type TripChangeObjectsOrderInput = {
  objectOrderList: Array<TripObjectOrderInput>;
  planId: Scalars['Int'];
};

export type TripObjectOrderInput = {
  objectId: Scalars['Int'];
  /** Позиция в списке */
  position: Scalars['Int'];
};

export type TripChangeObjectsOrderOutput = {
  __typename?: 'TripChangeObjectsOrderOutput';
  error?: Maybe<MutationError>;
};

export type TripCloseInput = {
  id: Scalars['Int'];
};

export type TripCloseOutput = {
  __typename?: 'TripCloseOutput';
  error?: Maybe<MutationError>;
};

export type TripCreateInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  dateVl: Scalars['DateTime'];
  number: Scalars['String'];
  portFrom: Scalars['Int'];
  portTo: Scalars['Int'];
  seaCarrierId: Scalars['Int'];
  ship: Scalars['Int'];
  stockTo: Scalars['Int'];
};

export type TripCreateOutput = {
  __typename?: 'TripCreateOutput';
  error?: Maybe<MutationError>;
};

export type TripDeleteInput = {
  id: Scalars['Int'];
};

export type TripDeleteOutput = {
  __typename?: 'TripDeleteOutput';
  error?: Maybe<MutationError>;
};

export type TripEditInput = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  dateVl: Scalars['DateTime'];
  id: Scalars['Int'];
  number: Scalars['String'];
  portFrom: Scalars['Int'];
  portTo: Scalars['Int'];
  seaCarrierId: Scalars['Int'];
  ship: Scalars['Int'];
  stockTo: Scalars['Int'];
};

export type TripEditOutput = {
  __typename?: 'TripEditOutput';
  error?: Maybe<MutationError>;
};

export type TripLoadingInput = {
  planId: Scalars['Int'];
};

export type TripLoadingOutput = {
  __typename?: 'TripLoadingOutput';
  error?: Maybe<MutationError>;
};

export type TripReturnToSourcePortInput = {
  planId: Scalars['Int'];
};

export type TripReturnToSourcePortOutput = {
  __typename?: 'TripReturnToSourcePortOutput';
  error?: Maybe<MutationError>;
};

export type TripToPortInput = {
  containerId?: Maybe<Scalars['Int']>;
  planId: Scalars['Int'];
};

export type TripToPortOutput = {
  __typename?: 'TripToPortOutput';
  error?: Maybe<MutationError>;
};

export type TypeOfExportEditInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  nameEng: Scalars['String'];
};

export type TypeOfExportEditOutput = {
  __typename?: 'TypeOfExportEditOutput';
  error?: Maybe<MutationError>;
};

export type UnlockTheIssuanceInput = {
  comment: Scalars['String'];
  objectId: Scalars['Int'];
};

export type UnlockTheIssuanceOutput = {
  __typename?: 'UnlockTheIssuanceOutput';
  error?: Maybe<MutationError>;
};

export type UnViewChatInput = {
  chatId: Scalars['Int'];
};

export type UnViewChatOutput = {
  __typename?: 'UnViewChatOutput';
  error?: Maybe<MutationError>;
};

export type UpdateInvoiceAuctionPurchaseInput = {
  invoiceKeysS3?: Maybe<Array<Scalars['String']>>;
  nomenclaturesList: Array<Maybe<InvoiceServiceInput>>;
  objectId: Scalars['Int'];
};

export type InvoiceServiceInput = {
  amount: Scalars['Int'];
  nomenclature: Scalars['Int'];
};

export type UpdateInvoiceAuctionPurchaseOutput = {
  __typename?: 'UpdateInvoiceAuctionPurchaseOutput';
  error?: Maybe<MutationError>;
};

export type UploadFilesInput = {
  entity: MediaEntityEnum;
  entityId: Scalars['Int'];
  keyS3Files: Array<Scalars['String']>;
  mediaType: MediaTypeEnum;
  onlyOneFile?: Maybe<Scalars['Boolean']>;
};

export enum MediaEntityEnum {
  BrAgents = 'br_agents',
  BrAgentsFormal = 'br_agents_formal',
  RegCars = 'reg_cars',
  RegContainers = 'reg_containers',
  RegExportDeclarations = 'reg_export_declarations',
  RegSbktsDocs = 'reg_sbkts_docs',
  RegTicket = 'reg_ticket',
  RegVehicles = 'reg_vehicles'
}

export type UploadFilesOutput = {
  __typename?: 'UploadFilesOutput';
  error?: Maybe<MutationError>;
};

export type UserChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UserChangePasswordOutput = {
  __typename?: 'UserChangePasswordOutput';
  error?: Maybe<MutationError>;
};

export type UserProfileUpdateInput = {
  emails?: Maybe<Array<UserEmailInput>>;
  language: LanguageEnum;
  timezone: Scalars['String'];
};

export type UserEmailInput = {
  email: Scalars['String'];
  useForNotification?: Maybe<Scalars['Boolean']>;
};

export type UserProfileUpdateOutput = {
  __typename?: 'UserProfileUpdateOutput';
  error?: Maybe<MutationError>;
};

export type VesselCreateInput = {
  name: Scalars['String'];
};

export type VesselCreateOutput = {
  __typename?: 'VesselCreateOutput';
  error?: Maybe<MutationError>;
};

export type VesselUpdateInput = {
  id: Scalars['Int'];
  type: Scalars['Int'];
};

export type VesselUpdateOutput = {
  __typename?: 'VesselUpdateOutput';
  error?: Maybe<MutationError>;
};

export type ViewChatInput = {
  chatId: Scalars['Int'];
};

export type ViewChatOutput = {
  __typename?: 'ViewChatOutput';
  error?: Maybe<MutationError>;
};

export type WatchCarUpdateInput = {
  attachments?: Maybe<Scalars['String']>;
  carTypeId: Scalars['Int'];
  chassisTypeId: Scalars['Int'];
  colorId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  complete: Scalars['Boolean'];
  engineModel?: Maybe<Scalars['String']>;
  engineNumber?: Maybe<Scalars['String']>;
  engineTypeId: Scalars['Int'];
  height: Scalars['Int'];
  isBroken?: Maybe<Scalars['Boolean']>;
  length: Scalars['Int'];
  loadCapacity: Scalars['Float'];
  makerId: Scalars['Int'];
  markId: Scalars['Int'];
  mediaKeysS3?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectId: Scalars['Int'];
  sawId?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
  vin: Scalars['String'];
  weight: Scalars['Int'];
  width: Scalars['Int'];
};

export type WatchCarUpdateOutput = {
  __typename?: 'WatchCarUpdateOutput';
  error?: Maybe<MutationError>;
};

export type WheelBuyInput = {
  objectId: Scalars['Int'];
};

export type WheelBuyOutput = {
  __typename?: 'WheelBuyOutput';
  error?: Maybe<MutationError>;
};

export type WheelCreateInput = {
  count: Scalars['Int'];
  diskManufacturer: Scalars['String'];
  diskType: DiskTypeEnum;
  fotos?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectId: Scalars['Int'];
  tireManufacturer: Scalars['String'];
  tireProfileHeight: Scalars['Int'];
  tireProfileWidth: Scalars['Int'];
  tireRadius: Scalars['Int'];
  tireType: TireTypeEnum;
  tireWear: Scalars['Int'];
};

export type WheelCreateOutput = {
  __typename?: 'WheelCreateOutput';
  error?: Maybe<MutationError>;
};

export type WheelRemoveFromPalletInput = {
  objectId: Scalars['Int'];
};

export type WheelRemoveFromPalletOutput = {
  __typename?: 'WheelRemoveFromPalletOutput';
  error?: Maybe<MutationError>;
};

export type WheelSetIsLoadedInput = {
  objectId: Scalars['Int'];
};

export type WheelSetIsLoadedOutput = {
  __typename?: 'WheelSetIsLoadedOutput';
  error?: Maybe<MutationError>;
};

export type WheelSetPriceInput = {
  objectId: Scalars['Int'];
  price: Scalars['Int'];
};

export type WheelSetPriceOutput = {
  __typename?: 'WheelSetPriceOutput';
  error?: Maybe<MutationError>;
};

export type DeleteUserNotifyMessageMutationVariables = Exact<{
  input?: Maybe<DeleteUserNotifyMessageInput>;
}>;


export type DeleteUserNotifyMessageMutation = { __typename?: 'Mutation', deleteUserNotifyMessage?: Maybe<{ __typename?: 'DeleteUserNotifyMessageOutput', error?: Maybe<{ __typename?: 'MutationError', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type RequestPhotoFromAuctionMutationVariables = Exact<{
  input: RequestPhotoFromAuctionInput;
}>;


export type RequestPhotoFromAuctionMutation = { __typename?: 'Mutation', requestPhotoFromAuction?: Maybe<{ __typename?: 'RequestPhotoFromAuctionOutput', error?: Maybe<{ __typename?: 'MutationError', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type SubscribeUserToTelegramMutationVariables = Exact<{
  input?: Maybe<SubscribeUserToTelegramInput>;
}>;


export type SubscribeUserToTelegramMutation = { __typename?: 'Mutation', subscribeUserToTelegram?: Maybe<{ __typename?: 'SubscribeUserToTelegramOutput', uuid: string, error?: Maybe<{ __typename?: 'MutationError', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type ToggleUserNotifyMessageMutationVariables = Exact<{
  input?: Maybe<ToggleUserNotifyMessageInput>;
}>;


export type ToggleUserNotifyMessageMutation = { __typename?: 'Mutation', toggleUserNotifyMessage?: Maybe<{ __typename?: 'ToggleUserNotifyMessageOutput', error?: Maybe<{ __typename?: 'MutationError', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type AuctionFeedbacksQueryVariables = Exact<{
  auctionId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AuctionFeedbacksQuery = { __typename?: 'Query', auctionFeedbacks?: Maybe<{ __typename?: 'AuctionFeedbacksPaginatedList', total: number, nodes: Array<{ __typename?: 'CarFeedback', id?: Maybe<number>, createdAt?: Maybe<any>, evaluation?: Maybe<number>, feedback?: Maybe<string>, owner?: Maybe<{ __typename?: 'OwnerFeedback', id?: Maybe<number>, link?: Maybe<string>, purchase?: Maybe<number>, rating?: Maybe<UserRatingEnum> }>, carInfo?: Maybe<{ __typename?: 'CarInfo', maker?: Maybe<string>, mark?: Maybe<string>, year?: Maybe<string> }> }> }> };

export type CheckAgentDebtsQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAgentDebtsQuery = { __typename?: 'Query', checkAgentDebts?: Maybe<boolean> };

export type CurrentUserIsEcarjpBotConnectedQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserIsEcarjpBotConnectedQuery = { __typename?: 'Query', currentUser?: Maybe<{ __typename?: 'User', id?: Maybe<number>, isBotEcarJpAuc?: Maybe<boolean> }> };

export type CurrentUserNotifyMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserNotifyMessagesQuery = { __typename?: 'Query', currentUserNotifyMessages?: Maybe<Array<{ __typename?: 'CurrentUserNotifyMessage', bot?: Maybe<{ __typename?: 'NotifyBotInfo', id?: Maybe<number>, name?: Maybe<string>, clientName?: Maybe<string> }>, channels?: Maybe<Array<{ __typename?: 'UserChannel', enabled?: Maybe<boolean>, id?: Maybe<number>, username?: Maybe<string> }>>, messageTypes?: Maybe<Array<{ __typename?: 'NotifyMessage', description?: Maybe<string>, id?: Maybe<number>, isDisabled?: Maybe<boolean>, name?: Maybe<string>, nameEng?: Maybe<string>, channels?: Maybe<Array<{ __typename?: 'UserChannel', enabledMessage?: Maybe<boolean>, id?: Maybe<number>, username?: Maybe<string> }>> }>> }>> };

export type EcarJpBotSubscribeUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type EcarJpBotSubscribeUrlQuery = { __typename?: 'Query', ecarJpBotSubscribeUrl: string };

export type LotWatchRequestDataQueryVariables = Exact<{
  type: VideoWatchTypeEnum;
  lotLink: Scalars['String'];
}>;


export type LotWatchRequestDataQuery = { __typename?: 'Query', serviceRequestPrice?: Maybe<number>, serviceRequestAuctionLotByUser?: Maybe<{ __typename?: 'ServiceRequest', id?: Maybe<number>, files?: Maybe<Array<Maybe<{ __typename?: 'S3File', id?: Maybe<number>, fullPathForFile?: Maybe<string> }>>> }> };

export const DeleteUserNotifyMessageDocument = gql`
    mutation deleteUserNotifyMessage($input: DeleteUserNotifyMessageInput) {
  deleteUserNotifyMessage(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserNotifyMessageGQL extends Apollo.Mutation<DeleteUserNotifyMessageMutation, DeleteUserNotifyMessageMutationVariables> {
    document = DeleteUserNotifyMessageDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPhotoFromAuctionDocument = gql`
    mutation requestPhotoFromAuction($input: RequestPhotoFromAuctionInput!) {
  requestPhotoFromAuction(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPhotoFromAuctionGQL extends Apollo.Mutation<RequestPhotoFromAuctionMutation, RequestPhotoFromAuctionMutationVariables> {
    document = RequestPhotoFromAuctionDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscribeUserToTelegramDocument = gql`
    mutation subscribeUserToTelegram($input: SubscribeUserToTelegramInput) {
  subscribeUserToTelegram(input: $input) {
    error {
      code
      message
    }
    uuid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribeUserToTelegramGQL extends Apollo.Mutation<SubscribeUserToTelegramMutation, SubscribeUserToTelegramMutationVariables> {
    document = SubscribeUserToTelegramDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleUserNotifyMessageDocument = gql`
    mutation toggleUserNotifyMessage($input: ToggleUserNotifyMessageInput) {
  toggleUserNotifyMessage(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleUserNotifyMessageGQL extends Apollo.Mutation<ToggleUserNotifyMessageMutation, ToggleUserNotifyMessageMutationVariables> {
    document = ToggleUserNotifyMessageDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuctionFeedbacksDocument = gql`
    query auctionFeedbacks($auctionId: Int!, $first: Int, $offset: Int) {
  auctionFeedbacks(auctionId: $auctionId, first: $first, offset: $offset) {
    nodes {
      id
      createdAt
      evaluation
      feedback
      owner {
        id
        link
        purchase
        rating
      }
      carInfo {
        maker
        mark
        year
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuctionFeedbacksGQL extends Apollo.Query<AuctionFeedbacksQuery, AuctionFeedbacksQueryVariables> {
    document = AuctionFeedbacksDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckAgentDebtsDocument = gql`
    query checkAgentDebts {
  checkAgentDebts
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckAgentDebtsGQL extends Apollo.Query<CheckAgentDebtsQuery, CheckAgentDebtsQueryVariables> {
    document = CheckAgentDebtsDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserIsEcarjpBotConnectedDocument = gql`
    query currentUserIsEcarjpBotConnected {
  currentUser {
    id
    isBotEcarJpAuc
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserIsEcarjpBotConnectedGQL extends Apollo.Query<CurrentUserIsEcarjpBotConnectedQuery, CurrentUserIsEcarjpBotConnectedQueryVariables> {
    document = CurrentUserIsEcarjpBotConnectedDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserNotifyMessagesDocument = gql`
    query currentUserNotifyMessages {
  currentUserNotifyMessages(onlyEcarjpBot: true) {
    bot {
      id
      name
      clientName
    }
    channels {
      enabled
      id
      username
    }
    messageTypes {
      channels {
        enabledMessage
        id
        username
      }
      description
      id
      isDisabled
      name
      nameEng
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserNotifyMessagesGQL extends Apollo.Query<CurrentUserNotifyMessagesQuery, CurrentUserNotifyMessagesQueryVariables> {
    document = CurrentUserNotifyMessagesDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EcarJpBotSubscribeUrlDocument = gql`
    query ecarJpBotSubscribeUrl {
  ecarJpBotSubscribeUrl
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EcarJpBotSubscribeUrlGQL extends Apollo.Query<EcarJpBotSubscribeUrlQuery, EcarJpBotSubscribeUrlQueryVariables> {
    document = EcarJpBotSubscribeUrlDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotWatchRequestDataDocument = gql`
    query lotWatchRequestData($type: VideoWatchTypeEnum!, $lotLink: String!) {
  serviceRequestPrice(type: $type)
  serviceRequestAuctionLotByUser(lotLink: $lotLink) {
    id
    files {
      id
      fullPathForFile
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotWatchRequestDataGQL extends Apollo.Query<LotWatchRequestDataQuery, LotWatchRequestDataQueryVariables> {
    document = LotWatchRequestDataDocument;
    client = 'shared-familiar';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DeliveryUnion": [
      "DeliveryRequest",
      "ObjectDelivered"
    ],
    "MediaUnion": [
      "ObjectMediaItem",
      "S3File"
    ],
    "SearchResult": [
      "Invoice",
      "InvoiceCustom",
      "ObjectOfServices"
    ]
  }
};
      export default result;
    